var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppDropdown',_vm._b({attrs:{"isOpen":_vm.isDropdownOpen},on:{"update:isOpen":[function($event){_vm.isDropdownOpen=$event},function($event){_vm.focusOnSearchInput(); _vm.$emit('update:isOpen', $event)}],"update:is-open":function($event){_vm.isDropdownOpen=$event},"select":function($event){return _vm.$emit('select', $event)},"input":function($event){return _vm.$emit('input', $event)},"update:value":function($event){return _vm.$emit('update:value', $event)},"submit":function($event){return _vm.$emit('submit', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_vm._t("trigger",null,null,{ active })]}},{key:"buttonLabel",fn:function({ active }){return [_vm._t("buttonLabel",null,null,{ active })]}},{key:"empty",fn:function(){return [_vm._t("empty",function(){return [(_vm.$attrs.loading)?_c('span',[_vm._v("Loading...")]):_c('span',{staticClass:"has-text-grey is-size-7 text-center"},[_vm._v("No results match your search")])]})]},proxy:true},{key:"dropdown-start",fn:function(){return [_c('AppInputFieldString',_vm._b({staticClass:"carmigo-input mx-1 mb-2",attrs:{"id":"dropdown-search-input"},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}},'AppInputFieldString',{
                isClearable: true,
                iconRight: 'magnify',
                ...(_vm.$attrs.searchAttrs ?? {}),
            },false))]},proxy:true},{key:"dropdownItem",fn:function({ option, isSelected }){return [_vm._t("dropdownItem",null,null,{ option, isSelected })]}},{key:"dropdown-end",fn:function(){return [_vm._t("dropdown-end")]},proxy:true}],null,true)},'AppDropdown',{
        ..._vm.$props,
        ..._vm.$attrs,
        options: _vm.filteredOptions,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }