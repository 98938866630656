
import { defineComponent } from 'vue';

import TheListingDetailsViewAndCounts from './TheListingDetailsViewAndCounts.vue';

export default defineComponent({
    name: "TheListingActivityCard",
    components: {
        TheListingDetailsViewAndCounts,
    },
    props: {
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        viewCount: {
            type: Number,
            required: false,
        },
        bidCount: {
            type: Number,
            required: false,
        },
        offerCount: {
            type: Number,
            required: false,
        },
        showViewsAndCounts: {
            type: Boolean,
            default: true,
        },
    }
});
