
import { computed, ComputedRef, defineComponent, onMounted, PropType } from 'vue';
import { useAuctionActivity, useGetAuctionActivity, useLoggedInUser, useSingleAuctionListener, useUpdateAuctionListingCardOnRdbChange, useUserRole } from '@/composables';
import { formatDate, toCurrencyShowDashesIfNull } from '@/utils';

import AppFlag from './AppFlag.vue';
import BidCurrent from './BidCurrent.vue';
import BiddingButtons from './Buttons/BiddingButtons.vue';
import CountdownTimer from './CountdownTimer.vue';
import TheListingActivityCard from './TheListingActivityCard.vue';

export default defineComponent({
    name: "TheListingActivityCardAuction",
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicle: {
            type: Object, // Eventually we need to update BiddingButtons so an entire Vehicle object isn't required
            required: false,
        },
        userCurrentBid: {
            type: Object as PropType<{
                bidAmount: number,
                proxyBidAmount?: number,
                storeName: string,
            }>
        },
        reservePrice: {
            type: Number,
            required: false,
        },
        buyItNowPrice: {
            type: Number,
            required: false,
        },
        storeIds: { // viewing multiple stores' listings
            type: Array as PropType<number[]>,
            required: false,
        },
    },
    components: {
        AppFlag,
        BidCurrent,
        BiddingButtons,
        CountdownTimer,
        TheListingActivityCard,
    },
    setup(props, { emit }) {
        const viewingMultipleStores = computed(() => Boolean(props.storeIds?.length));
        const { isUserBuyer, isUserAdmin, isUserDsr } = useUserRole();
        const { loggedInUserPersonId } = useLoggedInUser();

        const { highestBid, updateAuction } = useUpdateAuctionListingCardOnRdbChange();
        const { loadingHighestBidListener } = useSingleAuctionListener(props.vehicleListingId, (snapshot) => {
            updateAuction(snapshot);
            if (snapshot.isInitialSnapshot) {
                return;
            }
            if (snapshot.newSnapshotValue.bidHistory?.length !== snapshot.oldSnapshotValue?.bidHistory?.length) {
                getAuctionActivity(props.vehicleListingId);
            }
        });

        const {
            userCurrentBid,
            storeCurrentBid,
            getAuctionActivity,
            getStoreCurrentBid,
        } = useGetAuctionActivity({
            currentBid: props.userCurrentBid,
        });

        onMounted(() => {
            if (props.storeIds) {
                getStoreCurrentBid(props.vehicleListingId, props.storeIds);
            }
        });

        const { 
            hasUserBid, 
            hasStoreBid,
            isUserHighestBidder,
            isUserHighestBidderStore,
            isProxyBidExpired,
            isAuctionEnded,
        } = useAuctionActivity({ 
            highestBid,
            userCurrentBid,
            storeIds: props.storeIds,
        });
        const isMyBid = computed(() => {
            if (!viewingMultipleStores.value) {
                return true;
            }
            if (isUserHighestBidder.value) {
                return true;
            }
            if (storeCurrentBid.value?.buyerPersonId == loggedInUserPersonId.value) {
                return true;
            }
        });
        const getBidColor: ComputedRef<'highest-bidder' | 'outbid' | undefined> = computed(() => {
            if (isUserHighestBidder.value || !hasStoreBid.value) { // use default BidCurrent styles
                return undefined;
            }
            return isUserHighestBidderStore.value ? 'highest-bidder' : 'outbid';
        });
        
        return {
            viewingMultipleStores,
            getBidColor,
            isUserBuyer,
            isUserAdmin,
            isUserDsr,
            formatDate,
            toCurrencyShowDashesIfNull,
            hasUserBid,
            hasStoreBid,
            isMyBid,
            isUserHighestBidder,
            isUserHighestBidderStore,

            highestBid,
            loadingHighestBidListener,
            userCurrentBid,
            storeCurrentBid,
            isProxyBidExpired,
            getAuctionActivity,
            isAuctionEnded,
        }
    }
})
