
import { defineComponent, PropType, ref, watch } from 'vue';
import { NoteDTO, NoteType } from '../types';

import AppLoading from './AppLoading.vue';
import TheActivityFeedNote from './TheActivityFeedNote.vue';

export default defineComponent({
    name: 'TheActivityFeedDisplay',
    props: {
        activityFeed: {
            type: Array as PropType<NoteDTO[]>,
            default: () => { return [] },
        },
        noteType: {
            type: String as PropType<NoteType>,
            default: 'admin',
        },
    },
    components: {
        AppLoading,
        TheActivityFeedNote,
    },
    setup(props, { emit, attrs }) {
        const isLoading = ref(true);

        watch(() => props.activityFeed, () => {
            if (props.activityFeed.length) {
                // scroll to bottom
                requestAnimationFrame(() => {
                    let displayContainer = document.getElementById('activity-feed-display');
                    displayContainer.scrollTop = displayContainer.scrollHeight;
                });
            }
        });

        function checkShowTimestampForNote(note, idx) {
            if (idx == 0) {
                return true;
            }
            let previousNote = props.activityFeed[idx-1];

            // if different sender, always show name and timestamp
            let sameSender = previousNote.sender.personId == note.sender.personId;
            if (!sameSender) {
                return true;
            }

            // if within 30 min of my previous message, don't show timestamp
            let previousNoteDate = new Date(previousNote.date);
            let noteDate = new Date(note.date);

            let previousNoteTime = previousNoteDate.getTime();
            let noteTime = noteDate.getTime();
            let within30Min = Math.floor((noteTime - previousNoteTime) / 60000) < 30;
            if (within30Min) {
                return false;
            }

            return true;
        }

        return {
            isLoading,
            checkShowTimestampForNote,
        }
    },
});
