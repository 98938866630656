import type firebase from 'firebase/compat/app';
import { ProfileDTO } from '@/types/ProfileDTO';
import { computed, Ref, ref, SetupContext, type ComputedRef } from 'vue';
import { userRoleGetters, type RoleGetter, isVehicleAuctioned, isMarketplaceListingStatusInMarketplaceOrLater, openImpersonateUserDialog, openToast, getRedirectPathByRole } from '@/utils';
import { useStore } from './useStore';
import { Store } from 'vuex';
import store from '@/vuex';
import { VehicleStatus } from '@/types';
import { useRouter } from 'vue-router/composables';

export interface User extends firebase.auth.UserMetadata {
  uid: string,
  profile: ProfileDTO;
  stsTokenManager: Record<string, unknown>
}

/**
 * Get the current user from the store.
 */
export function useUser() {
    return computed<User>(() => store.state.user);
}

export function useUserRole(customStore?: Store<any>) {
    const store = customStore ?? useStore();
    const roleMap = {} as Record<RoleGetter, ComputedRef<boolean>>;

    userRoleGetters.forEach((role) => {
        roleMap[role] = computed(() => store.getters[role]);
    });

    return roleMap;
}

export function useRestrictDsrRole() {
    const { isUserDsr } = useUserRole();

    function isUserDsrAndListingHasGoneLive(status: VehicleStatus) {
        return isUserDsr.value && (isVehicleAuctioned(status) || isMarketplaceListingStatusInMarketplaceOrLater(status));
    }
    
    return {
        isUserDsrAndListingHasGoneLive,
    }
}

export function useLoggedInUser() {
    const loggedInUserPersonId: ComputedRef<number | undefined> = computed(() => store.state.user?.profile?.id ? parseInt(store.state.user.profile.id) : undefined);
    const loggedInUserName: ComputedRef<string | undefined> = computed(() => store.state.user?.profile?.firstName ? `${store.state.user.profile.firstName} ${store.state.user.profile.lastName}` : undefined)
    return {
        loggedInUserPersonId,
        loggedInUserName,
    }
}

export function useImpersonateUser({ context }: {
    context?: SetupContext<('close' | any)[]>
}) {
    const router = useRouter();
    const loadingImpersonatePersonId: Ref<number | undefined> = ref(undefined);

    function impersonateUser({ personId, firstName, lastName }: {
        personId: number,
        firstName: string,
        lastName: string,
    }) {
        openImpersonateUserDialog(
            {
                personId,
                firstName,
                lastName,
            }, 
            {
                onConfirm: () => loadingImpersonatePersonId.value = personId,
                onCancel: () => loadingImpersonatePersonId.value = undefined,
            },
            {
                onSuccess: () => {
                    loadingImpersonatePersonId.value = undefined;
                    openToast('is-success', `Impersonating ${firstName} ${lastName}`);
                    const { path: redirectPath } = getRedirectPathByRole();
                    router.push(redirectPath);
                    context?.emit('close');
                },
                onError: () => loadingImpersonatePersonId.value = undefined,
            }
        )
    }
    return {
        impersonateUser,
        loadingImpersonatePersonId,
    }
}