
import { useDeviceOrientation } from '@/composables';
import { openToast } from '@/utils';
import { computed, defineComponent, onMounted, PropType } from 'vue';

interface Resolution {
  width: number;
  height: number;
}

export default defineComponent({
    name: 'AppCamera',
    emits: ['loading', 'error', 'started', 'stopped', 'snapshot', 'close'],
    props: {
        resolution: {
            type: Object as PropType<Resolution>,
            default: () => ({ width: 1920, height: 1080 }),
        },
        facingMode: {
            type: String,
            default: 'environment',
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
        playsinline: {
            type: Boolean,
            default: true,
        },
        constraints: {
            type: Object,
            required: false,
        },
        enforceOrientation: {
            type: String as PropType<'landscape' | 'portrait'>, 
            required: false,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        enableZoom: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        stream: null as null | MediaStream,
        video: null as null | HTMLVideoElement,
        canvas: null as null | HTMLCanvasElement,
        isLandscape: false,
        openedToast: null,
        blobs: [] as Blob[],
        zoomFactor: 1,
    }),
    setup(props, context) {
        const { orientation, requestPermission: requestOrientationPermission } = useDeviceOrientation();
        const showOrientationAlert = computed(() => {
            if (!props.enforceOrientation || !orientation.value) {
                return false;
            }

            if (props.enforceOrientation) {
                return ['portrait', 'portrait-upside-down'].includes(orientation.value);
            }

            return ['landscape', 'landscape-right', 'landscape-left'].includes(orientation.value);
        });
        onMounted(async () => {
            if (props.enforceOrientation) {
                await requestOrientationPermission();
            }
        });

        return {
            showOrientationAlert,
        }
    },
    mounted() {
        if (!navigator.mediaDevices) {
            throw new Error('Media devices not available');
        }

        this.video = this.$refs.video as HTMLVideoElement;
        this.canvas = this.$refs.canvas as HTMLCanvasElement;

        if (this.playsinline && this.video) {
            this.video.setAttribute('playsinline', '');
        }

        if (this.autoplay) {
            this.start();
        }

        if (this.landscapeOnly) {
            this.startOrientationChangeListener();
        }
    },
    methods: {
        updateZoom() {
            if (this.video) {
                this.video.style.transform = `scale(${this.zoomFactor})`;
                this.video.style.transformOrigin = 'center center';
            }
        },
        async start() {
            this.$emit('loading');

            const constraints = this.constraints || {
                video: {
                    width: this.resolution.width,
                    height: this.resolution.height,
                    facingMode: this.facingMode,
                    deviceId: {},
                },
                audio: false,
            };

            try {
                this.stream = await navigator.mediaDevices.getUserMedia(constraints);

                if (!this.video) {
                    throw new Error('Video is not yet ready.');
                }

                this.video.srcObject = this.stream;

                this.$emit('started');
            } catch (error) {
                this.$emit('error', error);
            }
        },

        snapshot(
            resolution: Resolution,
            quality?: number,
            type = 'image/png',
        ) {
            if (!this.video) {
                throw new Error('Video is not yet ready.');
            }

            if (!this.canvas) {
                throw new Error('Canvas is not yet ready.');
            }
            this.openedToast = openToast('is-grey', 'Taking photo...', 'indefinite');
            const { width, height } = resolution || this.resolution;

            this.canvas.width = width * this.zoomFactor;
            this.canvas.height = height * this.zoomFactor;

            const context = this.canvas.getContext('2d');
            if (context) {
                const offsetX = (width * this.zoomFactor - width) / 2;
                const offsetY = (height * this.zoomFactor - height) / 2;
                context.scale(this.zoomFactor, this.zoomFactor);
                context.drawImage(this.video, -offsetX, -offsetY, width * this.zoomFactor, height * this.zoomFactor);
            }

            return new Promise((resolve) => {
                this.canvas?.toBlob(
                    (blob: Blob | null) => {
                        this.$emit('snapshot', blob);
                        if (blob) {
                            this.blobs.push(blob);
                        }
                        resolve(blob);
                    },
                    type,
                    quality,
                );
            }).then(() => {
                this.openedToast?.close();
                if (!this.multiple) {
                    this.$emit('close', this.blobs);
                }
                this.openedToast = openToast('is-success', 'Photo taken');
            }).catch(() => {
                this.openedToast?.close();
                this.openedToast = openToast('is-danger', 'Could not take photo');
            });
        },

        async startOrientationChangeListener() {
            const landscape = window.matchMedia('(orientation: landscape)');

            this.isLandscape = landscape.matches;

            landscape.addEventListener('change', this.orientationChangeListener);
        },

        orientationChangeListener(e: MediaQueryListEvent) {
            this.isLandscape = e.matches;
        },

        stop() {
            this.stream?.getTracks().forEach((track) => track.stop());
            this.$emit('stopped');
        },
    },
    beforeDestroy() {
        window.matchMedia('(orientation: landscape)').removeEventListener('change', this.orientationChangeListener);
    },
});
