var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.arbitrationClaim && _vm.hasArbitrationTimeRemaining)?_c('AppButton',_vm._b({staticClass:"open-arbitration-claim-button",on:{"click":function($event){$event.stopPropagation();return _vm.openCreateArbitrationClaimModal.apply(null, arguments)}}},'AppButton',{
        type: 'is-light',
        expanded: true,
        ..._vm.$attrs,
    },false),[_c('div',{staticClass:"arbitration-button-content flex-row align-center justify-center"},[_c('span',[_vm._v("Open Arbitration Claim")]),(_vm.$props.arbitrationExpirationDate)?_c('span',{staticClass:"flex-row align-center is-size-6"},[(!(_vm.$attrs.size == 'is-small'))?_c('span',{staticClass:"mx-1"},[_vm._v(" - ")]):_vm._e(),_c('CountdownTimer',_vm._b({},'CountdownTimer',{
                    date1: new Date(_vm.$props.arbitrationExpirationDate),
                    date2: _vm.serverSyncedNewDate(),
                    color: 'has-text-primary',
                },false))],1):_vm._e()])]):(_vm.arbitrationClaim && _vm.$props.showOpenedClaimDetails)?_c('div',{staticClass:"claim-details-container width-100 px-4 py-3 has-background-primary-light"},[_c('div',[_c('div',{staticClass:"claim-header flex-row align-start"},[(_vm.arbitrationClaim.arbitrationStatus == 'Resolved')?_c('h3',[_vm._v("Arbitration claim resolved")]):_c('h3',[_vm._v("An arbitration claim has been submitted")])]),_c('div',{staticClass:"claim-details mt-1"},[_c('p',[_vm._v("Updated "+_vm._s(_vm.moment(_vm.arbitrationClaim.updatedDate).format('M/D/YYYY @ h:mma')))]),_c('p',[_vm._v(" Visit the "),_c('a',{staticClass:"underline",attrs:{"href":"/#/arbitrations","target":"_blank"}},[_vm._v("Arbitrations Dashboard")]),_vm._v(" to manage your claim. ")])])]),(!['Opened', 'Resolved'].includes(_vm.arbitrationClaim.arbitrationStatus))?_c('div',{staticClass:"claim-status"},[_c('StatusTag',_vm._b({staticClass:"status"},'StatusTag',{
                status: _vm.arbitrationClaim.arbitrationStatus,
                type: 'primary',
            },false)),(_vm.isArbitrationStatusActionRequired)?_c('div',{staticClass:"flex-row align-center mt-2"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"alert-circle","type":"is-danger"}}),_c('p',{staticClass:"has-text-danger is-poppins"},[_vm._v("Action Required")])],1):_vm._e()],1):_vm._e()]):_c('AppTooltip',_vm._b({staticClass:"width-100"},'AppTooltip',{
        label: _vm.arbitrationUnavailableReason,
        position: 'is-bottom',
        type: 'is-grey',
        ..._vm.$attrs.tooltipAttrs,
    },false),[_c('AppButton',_vm._b({},'AppButton',{
            label: 'Open Arbitration Claim',
            type: 'is-light',
            disabled: true,
            expanded: true,
            ..._vm.$attrs,
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }