
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref, watch } from 'vue';
import { isEqual } from 'lodash';

import AppButton from './AppButton.vue';

export default defineComponent({
    name: 'AppDropdown',
    props: {
        options: {
            type: Array,
            default: () => ([]),
        },
        optionLabelKey: {
            type: String,
            required: false,
        },
        optionValueKey: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        value: {
            type: [String, Number, Object, Array], // array for 'multiple' select
            required: false,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        useSubmitButton: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'input', 'select', 'click', 'update:isOpen'],
    components: {
        AppButton,
    },
    setup(props, { emit, attrs }) {
        const dropdownRef = ref(null);
        onMounted(() => {
            if (props.isOpen) {
                dropdownRef.value?.toggle();
            }
        });
        
        const initialValue = props.value;
        const selectedOption = ref(props.value);
        watch(() => props.value, () => selectedOption.value = props.value);
        const hasChanges = computed(() => {
            try {
                let initialValueSorted = (initialValue as any[])?.sort();
                let selectedOptionsSorted = (selectedOption.value as any[])?.sort();
    
                return !isEqual(initialValueSorted, selectedOptionsSorted);
            } catch(error) {
                return true;
            }
        });
        
        watch(selectedOption, (newVal) => {
            emit('update:value', newVal);
            emit('input', newVal);
            emit('select', newVal);
        });
        
        function isOptionSelected(option: any) {
            if (props.optionValueKey) {
                return option[props.optionValueKey] === selectedOption.value;
            }
            return option === selectedOption.value;
        }

        return {
            hasChanges,
            dropdownRef,
            isOptionSelected,
            selectedOption,
        }
    },
});
