
import { defineComponent, onMounted, PropType, ref, Ref, watch } from 'vue';
import { ImageDTO, VehicleListingAnnouncement } from '@/types';
import { useCancelToken, useCreateAnnouncementForm, useUploadImage } from '@/composables';
import { detectMobile, getIconColorByAnnouncementType, openModal } from '@/utils';
import { AttachmentDTO } from '@/types/attachment';
import { getAnnouncementPrediction } from '@/api';

import AddAttachmentsButton from './AddAttachmentsButton.vue';
import AppDropdown from './AppDropdown.vue';
import AppImage from './AppImage.vue';
import AppLoading from './AppLoading.vue';
import AutocompleteInput from './AutocompleteInput.vue';
import ThePhotoGalleryModal from './ThePhotoGalleryModal.vue';

export default defineComponent({
    name: 'TheCreateAnnouncementForm',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        announcement: {
            type: Object as PropType<VehicleListingAnnouncement>,
            required: false,
        },
        vehicleListingPhotos: {
            type: Array as PropType<ImageDTO[]>,
            required: false,
        }, 
    },
    components: {
        AddAttachmentsButton,
        AppDropdown,
        AppImage,
        AppLoading,
        AutocompleteInput,
    },
    emits: ['update', 'addPhoto', 'update:vehicleListingPhotoType'],
    setup(props, context) {
        const vehicleListingPhotoType: Ref<'Exterior' | 'Interior' | 'Damage' | undefined> = ref(undefined);
        const imageUploadFilePath: Ref<string | undefined> = ref(`${props.vehicleListingId}/inspection/${vehicleListingPhotoType.value}`);
        const uploadedImageUrl: Ref<string | undefined> = ref(props.announcement?.announcementPhoto?.url ?? undefined);

        const {
            addToUploadQueue,
            executeUploadQueue,
            loadingUploadImages,
        } = useUploadImage({
            context,
            showToasts: false,
        });

        const hasPhotoToUpload: Ref<boolean> = ref(false);
        function addSelectedPhoto(file?: File & { file: AttachmentDTO }) {
            if (!file) {
                return;
            }
            hasPhotoToUpload.value = true;
            // This uploads the photo to storage so we can display it on the form.
            // Unless the correct photo type has been clicked before uploading, the image may be uploaded to the wrong place first.
            // This is currently acceptable because we only fetch images from storage that are related to a vehicle_listing_photo.
            addToUploadQueue(file);
            executeUploadQueue(imageUploadFilePath.value, {
                onSuccess: (uploadedFiles) => {
                    if (!uploadedFiles.length) {
                        return;
                    }
                    uploadedImageUrl.value = uploadedFiles[0].url;
                    updateVehicleListingPhotoType('Damage');
                }
            });
            context.emit('addPhoto', file);
        }

        function removePhoto() {
            hasPhotoToUpload.value = false;
            uploadedImageUrl.value = undefined;
            vehicleListingPhotoType.value = undefined;
            selectedVehiclePhoto.value = undefined;
            context.emit('addPhoto', undefined);
            context.emit('update:vehicleListingPhotoType', undefined);
            context.emit('update', updatedAnnouncement.value);
        }

        function openSelectListingPhotosModal() {
            openModal({
                component: ThePhotoGalleryModal,
                props: {
                    photos: props.vehicleListingPhotos,
                    isClickable: true,
                    selectMax: 1, 
                },
                events: {
                    submit: (selectedPhotos: ImageDTO[]) => {
                        selectedVehiclePhoto.value = selectedPhotos[0];
                        uploadedImageUrl.value = selectedVehiclePhoto.value?.url;
                    },
                }
            });
        }

        function updateVehicleListingPhotoType(listingPhotoType: 'Exterior' | 'Interior' | 'Damage') {
            vehicleListingPhotoType.value = listingPhotoType;
            context.emit('update:vehicleListingPhotoType', listingPhotoType);
        }

        const {
            selectedAnnouncementTypeId,
            selectedAnnouncementType,
            selectedCategoryId,
            selectedCategory,
            selectedSubcategory,
            selectedSubcategoryId,
            announcementText,
            selectedVehiclePhoto,
            updatedAnnouncement,

            announcementTypeOptions,
            categoryOptions,
            getCategoryOptions,
            subcategoryOptions,
            loadingCategoryOptions,
            loadingSubcategoryOptions,
        } = useCreateAnnouncementForm({
            editingAnnouncement: props.announcement,
            context,
        });

        onMounted(getCategoryOptions);
        
        const announcementTextAutocompleteOptions: Ref<{ text: string}[]> = ref([]);
        const loadingAutocompleteOptions = ref(false);

        const { cancelToken, cancelPreviousRequest } = useCancelToken();
        watch(() => announcementText.value, () => {
            if (!announcementText.value) {
                announcementTextAutocompleteOptions.value = [];
            }
            if (announcementText.value) {
                loadingAutocompleteOptions.value = true;
                cancelPreviousRequest();
                getAnnouncementPrediction(announcementText.value, {
                    cancelToken: cancelToken.value?.token,
                    skipErrorOnCancelRequest: true,
                    onSuccess: (options) => {
                        announcementTextAutocompleteOptions.value = options.map((option: string) => {
                            return {
                                text: option,
                            }
                        });
                        loadingAutocompleteOptions.value = false;
                    },
                    onError: () => loadingAutocompleteOptions.value = false,
                });
            }
        });
        
        return {
            detectMobile,
            getIconColorByAnnouncementType,

            selectedCategoryId,
            selectedCategory,
            categoryOptions,
            loadingCategoryOptions,
            loadingSubcategoryOptions,

            selectedAnnouncementTypeId,
            selectedAnnouncementType,
            announcementTypeOptions,

            selectedSubcategory,
            selectedSubcategoryId,
            subcategoryOptions,

            announcementText,
            addSelectedPhoto,
            removePhoto,
            imageUploadFilePath,
            vehicleListingPhotoType,
            uploadedImageUrl,
            loadingUploadImages,
            openSelectListingPhotosModal,
            hasPhotoToUpload,
            updateVehicleListingPhotoType,
            announcementTextAutocompleteOptions,
            loadingAutocompleteOptions,
        }
    },
});

