import { APIConfig } from '@/types';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess } from '@/utils';
import { debounce } from 'lodash';
import { computed, ComputedRef, Ref, ref, SetupContext } from 'vue';

export function useEditableFields({ context, otherUnsavedChanges }: {
    context: SetupContext<('input' | any)[]>,
    otherUnsavedChanges?: ComputedRef<boolean>,
}) {
    const unsavedUpdates: Ref<{ [key: string]: any }> = ref({});
    function addToUnsavedUpdates(newEdits: { [key: string]: any }) {
        unsavedUpdates.value = {
            ...unsavedUpdates.value,
            ...newEdits, // { year: 2022, color: 'blue' }
        }
    }

    function emitUpdates(updates: { [fieldName: string]: any }) {
        context.emit('input', updates);
    }

    const hasUnsavedChanges = computed(() => {
        if (otherUnsavedChanges) {
            return Boolean(Object.keys(unsavedUpdates.value).length) || otherUnsavedChanges.value;
        }
        return Boolean(Object.keys(unsavedUpdates.value).length);
    });

    return {
        unsavedUpdates,
        addToUnsavedUpdates,
        emitUpdates,
        hasUnsavedChanges,
    }
}

export function useAutocomplete({ callback, config={} }: { 
    callback: (text: string, config: APIConfig) => Promise<any[]> ,
    config?: APIConfig,
}) {
    const autocompleteOptions: Ref<string[]> = ref([]);
    const loadingAutocomplete: Ref<boolean | string> = ref(false);

    const debouncedGetAutocompleteOptions = debounce(getAutocompleteOptions, 300);

    async function getAutocompleteOptions(text: string, id?: string) {
        if (!text) {
            return;
        }
        loadingAutocomplete.value = id ?? true;
        autocompleteOptions.value = await callback(text, {
            onSuccess: (res) => {
                applyAPIConfigOnSuccess(res, config);
                loadingAutocomplete.value = false;
            },
            onError: (error?: Error) => {
                applyAPIConfigOnError(error, config);
                loadingAutocomplete.value = false;
            }
        });
    }

    return {
        autocompleteOptions,
        loadingAutocomplete,
        debouncedGetAutocompleteOptions,
    }
}