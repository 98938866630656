
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';
import { focusOnElement } from '@/utils';

import AppDropdown from '@/components/AppDropdown.vue';
import AppInputFieldString from '@/components/AppInputFieldString.vue';

export default defineComponent({
    name: 'AppDropdownWithSearch',
    props: {
        options: {
            type: Array,
            default: () => ([]),
        },
        optionLabelKey: {
            type: String,
            required: true,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppDropdown,
        AppInputFieldString,
    },
    setup(props, context) {
        const isDropdownOpen = ref(props.isOpen);
        const searchValue = ref('');
        const filteredOptions = computed(() => {
            if (!searchValue.value.length || !props.optionLabelKey) {
                return props.options;
            }
            let searchValueClean = searchValue.value.toLowerCase().trim();
            return props.options.filter(option => option[props.optionLabelKey].toLowerCase().includes(searchValueClean));
        });

        function focusOnSearchInput() {
            nextTick(() => focusOnElement('dropdown-search-input'));
        }

        return {
            isDropdownOpen,
            searchValue,
            filteredOptions,
            focusOnSearchInput,
        }
    }
});
