<template>
    <Modal 
        id="forgot-password-modal" 
        customButtons 
        @close="$emit('close')"
        class="carmigo-modal"
    >
        <template #header>
            Reset your password
        </template>

        <template #body>
            <div class="reset-password-container">
                <p class="mb-2">Where should we email your reset password link?</p>
                <AppInputFieldEmail 
                    :email.sync="emailUpdated"
                    class="carmigo-input"
                    id="forgot-email-input"
                    v-bind="{
                        fieldAttrs: { label: '' },
                    }"
                    @keypressEnter="sendResetPasswordLink"
                />
            </div>
        </template>

        <template #buttonsRight>
            <b-button
                label="Cancel"
                type="is-danger"
                @click="$emit('close')"
                :disabled="isLoading"
            />
            <b-button 
                label="Send Link"
                type="is-primary"
                @click="sendResetPasswordLink"
                :loading="isLoading"
                :disabled="isDisabled"
            />
        </template>

    </Modal>
</template>

<script>
import AppInputFieldEmail from './AppInputFieldEmail.vue';
import Modal from './Modal.vue';

import {
    focusOnElement,
    openDialogAlert,
    openToast,
} from '../utils';

import {
    POST,
} from '../api';

export default {
    name: 'ForgotPasswordModal',
    props: {
        email: {
            required: false,
            type: String,
            default: '',
        }
    },
    components: {
        AppInputFieldEmail,
        Modal,
    },
    data() {
        return {
            emailUpdated: this.email,
            isLoading: false,
            updateKey: 0,
            invalidInput: null,
        }
    },
    mounted() {
        focusOnElement('forgot-email-input', 200);
    },
    watch: {
        emailUpdated() {
            this.invalidInput = null;
        },
    },
    computed: {
        isDisabled() {
            return !(this.invalidInput === null) || !Boolean(this.emailUpdated.length);
        },
    },
    methods: {
        openDialogAlert,
        openToast,

        async sendResetPasswordLink() {
            this.isLoading = true;
            
            await POST('/auth/resetPassword', {
                email: this.emailUpdated.toLowerCase(),
            }).then((res) => {
                this.isLoading = false;
                this.openToast('is-success', 'Reset password link sent!');
                this.$emit('resetPasswordSent', this.emailUpdated);
                this.$emit('close');
            }).catch(error => {
                console.log('Error in sendResetPasswordLink', error);
                this.isLoading = false;
                this.openDialogAlert(
                    'Something went wrong',
                    'We were unable to send your password reset link. If the problem persists, please contact support.'
                );
                this.$emit('close');
            });

            return;
        },
    },
}
</script>

<style scoped lang='postcss'>
.modal-card {
    padding: 2rem auto;
}
.reset-password-container {
    max-width: 350px;
    height: auto;
    margin: auto;
}

@media screen and (min-width: 550px) {
  #forgot-password-modal {
    min-width: 500px;
  }
}

</style>
