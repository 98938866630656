import { createPrivateNote } from '@/api/vehicles';
import { createAdminNote, getActivityFeedByVehicleListingId, getLoggedInUserNotesByVehicleListingId } from '@/api';
import { NoteDTO, APIConfig, NoteType } from '@/types';
import { computed, Ref, ref, SetupContext } from 'vue';
import { applyAPIConfigOnSuccess, applyAPIConfigOnError, openModal, openToast } from '@/utils';
import { BModalConfig } from 'buefy/types/components';

import TheBuyerNotesModal from '@/components/TheBuyerNotesModal.vue';

export function useCreateListingNote({ vehicleListingId, noteType, context }: {
    vehicleListingId: number, 
    noteType: NoteType,
    context?: SetupContext<('updatedNotes' | any)[]>
}) {
    const noteText = ref('');
    const noteKey = ref(0);

    const loadingSubmitNote = ref(false);
    const errorMessage = ref<string>();

    async function submitNote(config: APIConfig={}) {
        let createNoteFunction = getCreateNoteFunctionByNoteType(noteType);
        if (!createNoteFunction) {
            return;
        }

        let isEmpty = !noteText.value.split('').some(char => char !== '\n');
        if (isEmpty) {
            return;
        }

        loadingSubmitNote.value = true;
        await createNoteFunction(vehicleListingId, noteText.value, {
            ...config,
            onSuccess: (allNotes: NoteDTO[]) => {
                openToast('is-success', 'Note saved!');
                loadingSubmitNote.value = false;
                noteText.value = '';
                errorMessage.value = undefined;
                noteKey.value++;
                context?.emit('updatedNotes', allNotes);
                applyAPIConfigOnSuccess(allNotes, config);
            },
            customErrorHandling: true,
            onError: (error) => {
                loadingSubmitNote.value = false;
                errorMessage.value = 'Your note failed to save. Try again later or contact support.';
                applyAPIConfigOnError(error, config);
            },
        });
    }

    function getCreateNoteFunctionByNoteType(noteType: NoteType) {
        let createNoteFunction = undefined;
        switch(noteType) {
            case 'admin':
                createNoteFunction = createAdminNote;
                break;
            case 'private':
                createNoteFunction = createPrivateNote;
                break;
            case 'system':
                break;
        }
        return createNoteFunction;
    }

    return {
        noteText,
        noteKey,
        submitNote,
        loadingSubmitNote,
        errorMessage,
    }
}

export function useActivityFeed({ vehicleListingId }: {
    vehicleListingId: number,
}) {
    const loadingActivityFeed: Ref<boolean> = ref(false);
    const activityFeed: Ref<NoteDTO[]> = ref([]);
    async function getActivityFeed() {
        loadingActivityFeed.value = true;
        activityFeed.value = await getActivityFeedByVehicleListingId(vehicleListingId, {
            onSuccess: () => loadingActivityFeed.value = false,
            onError: () => loadingActivityFeed.value = false,
        });
    }

    return {
        getActivityFeed,
        activityFeed,
        loadingActivityFeed,
    }
}

export function useBuyerNotesIconButton({ buyerNotes, buyerHasNote=false }: { 
    buyerNotes?: Ref<NoteDTO[]>, 
    buyerHasNote?: boolean,
}={}) {
    const hasBuyerNote: Ref<boolean> = ref(buyerHasNote);
    const buyerNoteIcon: Ref<string> = computed(() => hasBuyerNote.value ? 'comment-text' : 'comment-plus-outline');

    function openBuyerNotesModal(modalConfig: BModalConfig) {
        openModal({
            component: TheBuyerNotesModal,
            events: {
                updateNotes: (updatedNotes: NoteDTO[]) => {
                    hasBuyerNote.value = Boolean(updatedNotes?.length);
                    if (buyerNotes) {
                        buyerNotes.value = updatedNotes;
                    }
                },
                ...(modalConfig?.events ?? {}),
            },
            ...modalConfig,
        });
    }

    return {
        hasBuyerNote,
        buyerNoteIcon,
        openBuyerNotesModal,
    }
}

export function useBuyerNotes({ buyerNotesInitial }: { buyerNotesInitial?: NoteDTO[] }={}) {
    const buyerNotes: Ref<NoteDTO[]> = ref(buyerNotesInitial ?? []);
    const loadingBuyerNotes: Ref<boolean> = ref(false);

    async function getBuyerNotes(vehicleListingId: number, config: APIConfig={}) {
        loadingBuyerNotes.value = true;
        await getLoggedInUserNotesByVehicleListingId(vehicleListingId, {
            ...config,
            onSuccess: (res) => {
                buyerNotes.value = res;
                loadingBuyerNotes.value = false;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: (error) => {
                loadingBuyerNotes.value = false;
                applyAPIConfigOnError(error, config);
            },
        });
    }

    return {
        buyerNotes,
        loadingBuyerNotes,
        getBuyerNotes,
    }
}