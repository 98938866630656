
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { useUser } from '../composables';
import { NoteDTO, NoteType } from '../types';
import { formatDate } from '../utils';

export default defineComponent({
    name: 'TheActivityFeedNote',
    props: {
        note: {
            type: Object as PropType<NoteDTO>,
            required: true,
        },
        dateFormat: {
            type: String,
            default: 'MMM d, h:mm a',
        },
        showTimestamp: {
            type: Boolean,
            default: true,
        },
        noteType: {
            type: String as PropType<NoteType>,
            default: 'admin',
        },
    },
    setup(props, { emit, attrs }) {
        const user = useUser();
        const loggedInUserPersonId = user.value?.profile?.id ? Number(user.value?.profile?.id) : undefined;

        const isMyNote: ComputedRef<boolean> = computed(() => props.note.sender.personId == loggedInUserPersonId);

        return {
            formatDate,
            isMyNote,
        }
    },
});
