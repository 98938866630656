
import { computed, ComputedRef, defineComponent, onMounted, PropType, ref, Ref } from 'vue';
import { AnnouncementType, TitleStatus, VehicleListingAnnouncementCategorizedDTO } from '../types';
import { getIconColorByAnnouncementType, getMostSevereAnnouncementType, getMostSevereAnnouncementTypeFromList } from '@/utils';
import { usePlayAudio } from '@/composables';
import { omit } from 'lodash';

import TheAnnouncementIcons from '@/components/TheAnnouncementIcons.vue';
import AppAudioPlayer from '@/components/AppAudioPlayer.vue';
import TheListingDetailsCard from './TheListingDetailsCard.vue';
import TheVehicleListingAnnouncements from './TheVehicleListingAnnouncements.vue'

export default defineComponent({
    name: 'TheListingDetailsAnnouncements',
    props: {
        announcements: {
            type: Object as PropType<VehicleListingAnnouncementCategorizedDTO>,
            required: false,
        },
        numAnnouncements: {
            type: Number,
            required: false,
        },
        showAllAnnouncements: {
            type: Boolean,
            default: false,
        },
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        engineRecordingUrl: {
            type: String,
            required: false,
        },
        showEngineRecording: {
            type: Boolean,
            default: true,
        },
        mostSevereAnnouncementType: {
            type: String as PropType<AnnouncementType>,
            required: false,
        },
    },
    components: {
        AppAudioPlayer,
        TheAnnouncementIcons,
        TheListingDetailsCard,
        TheVehicleListingAnnouncements,
    },
    emits: ['viewPhoto', 'edit', 'delete'],
    setup(props, { emit, attrs }) {
        const announcementsRef: ComputedRef<VehicleListingAnnouncementCategorizedDTO | undefined> = computed(() => props.announcements as VehicleListingAnnouncementCategorizedDTO);
        const hasUnknownAnnouncements = computed(() => Boolean(announcementsRef.value?.unknown?.length));
        const announcementsWithCategory: ComputedRef<VehicleListingAnnouncementCategorizedDTO> = computed(() => omit(announcementsRef.value, 'unknown'));

        const mostSevereAnnouncementType: ComputedRef<AnnouncementType | undefined> = computed(() => {
            if (props.mostSevereAnnouncementType) {
                return props.mostSevereAnnouncementType;
            }
            return hasUnknownAnnouncements.value && !props.showAllAnnouncements
                ? getMostSevereAnnouncementTypeFromList(announcementsRef.value?.unknown)
                : getMostSevereAnnouncementType(announcementsRef.value);
        });

        return {
            getIconColorByAnnouncementType,
            hasUnknownAnnouncements,
            announcementsWithCategory,
            mostSevereAnnouncementType,
            announcementsRef,
        }
    },
});
