
import { defineComponent, nextTick, onMounted, PropType, watch } from 'vue';
import { useBreakpoint, useBuyerNotes, useBuyerNotesIconButton } from '@/composables';
import { pick } from 'lodash';
import { NoteDTO } from '@/types';
import { scrollToElementBottom } from '@/utils';

import AppButton from '@/components/AppButton.vue';
import TheActivityFeedDisplay from '@/components/TheActivityFeedDisplay.vue';
import TheActivityFeedInput from '@/components/TheActivityFeedInput.vue';

export default defineComponent({
    name: 'TheListingBuyerNotes',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicleName: {
            type: String,
            required: false,
        },
        imageUrl: {
            type: String,
            required: false,
        },
        vin: {
            type: String,
            required: false,
        },
        buyerNotesInitial: {
            type: Array as PropType<NoteDTO[]>,
            required: false,
        },
        useCustomSubmit: {
            type: Boolean,
            default: false,
        },
        useAddNoteButton: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppButton,
        TheActivityFeedDisplay,
        TheActivityFeedInput,
    },
    emits: ['update:loadingBuyerNotes', 'update:noteText'],
    setup(props, context) {
        const { bulmaBreakpoint } = useBreakpoint();

        const {
            buyerNotes,
            loadingBuyerNotes,
            getBuyerNotes,
        } = useBuyerNotes({
            buyerNotesInitial: props.buyerNotesInitial,
        });
        
        onMounted(() => {
            if (!props.buyerNotesInitial) {
                getBuyerNotes(props.vehicleListingId, { onSuccess: () => nextTick(scrollToBuyerNotesBottom) })
            } else {
                scrollToBuyerNotesBottom();
            }
        });
        
        watch(loadingBuyerNotes, () => {
            context.emit('update:loadingBuyerNotes', loadingBuyerNotes.value);
        });

        const { 
            openBuyerNotesModal,
        } = useBuyerNotesIconButton({ buyerNotes });

        function scrollToBuyerNotesBottom() {;
            scrollToElementBottom('#buyer-notes-display');
        }

        function updateBuyerNotes(updatedNotes: NoteDTO[]) {
            buyerNotes.value = updatedNotes;
            nextTick(scrollToBuyerNotesBottom);
        }

        return {
            pick,
            bulmaBreakpoint,
            buyerNotes,
            loadingBuyerNotes,
            openBuyerNotesModal,
            updateBuyerNotes,
        }
    }
});
