var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetailsEditable',_vm._b({key:`inspector-vdp-${_vm.listingKey}`,on:{"input":function($event){_vm.addToUnsavedUpdates($event); _vm.updateListingFromUnsavedUpdates($event);},"updateVehiclePhotos":_vm.updateListingVehiclePhotos},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.isEditingMode)?_c('AppButton',_vm._b({on:{"click":function($event){return _vm.toggleEditingMode(false)}}},'AppButton',{
                label: 'Preview Listing',
                type: 'is-dark',
                expanded: true,
            },false)):_c('AppButton',_vm._b({on:{"click":function($event){return _vm.toggleEditingMode(true)}}},'AppButton',{
                label: 'Edit',
                iconLeft: 'pencil',
                type: 'is-dark',
                expanded: true,
                disabled: _vm.isDsrAndListingIsAuctioned,
            },false)),(_vm.hasUnsavedChanges)?_c('AppButton',_vm._b({on:{"click":function($event){return _vm.saveInspection();}}},'AppButton',{
                label: 'Save Edits',
                iconLeft: 'check-bold', 
                type: 'is-primary',
                loading: _vm.loadingSaveUpdates || _vm.loadingSaveBrandedLane,
                expanded: true,
            },false)):_c('AppButton',_vm._b({on:{"click":function($event){return _vm.submit()}}},'AppButton',{
                label: 'Submit Listing',
                iconLeft: 'check-all',
                type: 'is-danger',
                disabled: _vm.loadingSaveUpdates,
                loading: _vm.loadingSubmitInspection,
                expanded: true,
            },false))]},proxy:true},{key:"carouselButtons",fn:function(){return [_c('div',{staticClass:"flex-row align-center justify-center mb-2"},[_c('h3',{staticClass:"mr-2"},[_vm._v("Assigned Inspector:")]),_c('TheAssignInspectorEditableField',_vm._b({},'TheAssignInspectorEditableField',{
                    vehicleListingId: _vm.vehicleListingId,
                    inspectorPersonId: _vm.listing.inspectorPersonId,
                },false))],1)]},proxy:true},{key:"branded-lane",fn:function(){return [(_vm.isEditingMode)?_c('div',{staticClass:"flex-row align-center mt-5"},[_c('AppDropdownWithSearch',_vm._b({staticClass:"select-branded-lane width-100",attrs:{"value":_vm.selectedBrandedLane},on:{"update:value":function($event){_vm.selectedBrandedLane=$event}}},'AppDropdownWithSearch',{
                    fieldAttrs: { 
                        label: 'Branded Lane',
                        labelPosition: 'on-border',
                    },
                    buttonAttrs: { label: _vm.selectedBrandedLane?.name ?? 'Select' },
                    loading: _vm.loadingBrandedLaneOptions,
                    options: _vm.brandedLaneOptions,
                    optionLabelKey: 'name',
                    position: 'is-bottom-right',
                },false)),_c('AppButton',_vm._b({staticClass:"no-border",on:{"click":function($event){_vm.selectedBrandedLane = undefined}}},'AppButton',{
                    iconRight: 'delete',
                    type: 'is-danger',
                    outlined: true,
                    disabled: !_vm.selectedBrandedLane,
                },false))],1):(_vm.selectedBrandedLane)?_c('div',{staticClass:"branded-lane mt-2 flex-column"},[_c('div',{staticClass:"carmigo-tag"},[_c('p',{staticClass:"overflow-ellipsis"},[_vm._v(_vm._s(_vm.selectedBrandedLane.name))])]),_c('div',{staticClass:"carmigo-tag"},[_c('p',{staticClass:"overflow-ellipsis"},[_vm._v(_vm._s(_vm.selectedBrandedLane.description))])])]):_vm._e()]},proxy:true},{key:"audio-files",fn:function(){return [(_vm.isEditingMode)?_c('TheListingDetailsCard',{staticClass:"engine-audio",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex-row align-center justify-space-between width-100"},[_c('h2',{staticClass:"ml-2"},[_vm._v("Engine Recording")]),(_vm.isRecording)?_c('div',{staticClass:"flex-row align-center"},[_c('b-icon',{staticClass:"has-text-danger",attrs:{"icon":"record"}}),_c('span',{staticClass:"is-size-7 has-text-dark"},[_vm._v(_vm._s(_vm.formattedRecordingTime))])],1):_vm._e()])]},proxy:true},{key:"body",fn:function(){return [(_vm.loadingUploadAudio)?_c('div',{staticClass:"loading-container width-100 my-2"},[_c('AppLoading',{attrs:{"active":"","isFullPage":false}})],1):_c('div',{staticClass:"flex-row align-center"},[_c('AppButton',_vm._b({staticClass:"carmigo-button no-border",on:{"click":_vm.downloadEngineAudio}},'AppButton',{
                            iconRight: 'download',
                            type: 'is-primary',
                            size: 'is-medium',
                            disabled: !_vm.rawRecordingUrl && !_vm.recordingUrl,
                            outlined: true,
                        },false)),(_vm.recordingUrl)?_c('div',{staticClass:"flex-row align-center width-100"},[_c('AppAudioPlayer',{staticClass:"width-100",attrs:{"src":_vm.recordingUrl}}),_c('AppButton',_vm._b({staticClass:"no-border has-text-danger p-0 ml-3",on:{"click":_vm.confirmDeleteRecording}},'AppButton',{
                                iconRight: 'delete',
                                size: 'is-medium',
                                loading: _vm.loadingDeleteRecording,
                            },false))],1):_c('AppAudioRecorder',_vm._b({staticClass:"width-100",on:{"update:recording":_vm.updateIsRecording,"update:rawRecordingUrl":function($event){_vm.rawRecordingUrl = $event},"uploadAudio":_vm.updateEngineRecordingUrl}},'AppAudioRecorder',{
                            storageFilePath: `${_vm.vehicleListingId}/inspection/audio`,
                            filename: 'engine',
                            startButtonAttrs: {
                                expanded: true,
                                loading: _vm.loadingEngineRecording,
                            },
                            stopButtonAttrs: {
                                expanded: true,
                                loading: _vm.loadingEngineRecording,
                            },
                        },false))],1),(!_vm.loadingUploadAudio)?_c('div',{staticClass:"width-100 text-center my-2"},[_c('b-upload',_vm._b({on:{"input":function($event){return _vm.saveEngineAudioFromDevice($event)}}},'b-upload',{
                            accept: '.mp3,.webm',
                            multiple: false,
                        },false),[_c('div',{staticClass:"has-text-primary is-poppins text-center is-size-6 hover-pointer"},[_vm._v(" or "),_c('span',{staticClass:"is-poppins bold underline"},[_vm._v("Upload Audio")])])])],1):_vm._e()]},proxy:true}],null,false,1994410071)}):_vm._e()]},proxy:true}])},'AppListingDetailsEditable',{
        isEditingMode: _vm.isEditingMode,
        listing: _vm.updatedListing,
        vehicleListingId: _vm.vehicleListingId,
        marketplaceListingId: _vm.marketplaceListingId,
        vehiclePhotos: _vm.vehiclePhotos,
        loadingVehiclePhotos: _vm.loadingVehiclePhotos,
        vehiclePhotosDTO: _vm.vehiclePhotosDTO,
        announcements: _vm.announcements,
        loadingAnnouncements: _vm.loadingAnnouncements,
        engineRecordingUrl: _vm.recordingUrl,
        bookoutOptions: _vm.bookoutOptions,
        loadingBookoutOptions: _vm.loadingBookoutOptions,
        titleStatus: _vm.titleStatus,
        loadingTitleStatus: _vm.loadingTitleStatus,
        sellerReason: _vm.sellerReason,
        additionalPricingDetails: _vm.additionalPricingDetails,
        loadingAdditionalPricingDetails: _vm.loadingAdditionalPricingDetails,
        fixedButtons: true,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }