
import { computed, defineComponent, PropType } from 'vue';
import { HighestBidRefRecord } from '@/types';
import store from '@/vuex';

export default defineComponent({
  name: 'BidCurrent',
  props: {
    bidAmount: [String, Number],
    orderBuyerPersonId: {
      type: Number,
      required: false,
    },
    highestBid: {
      type: Object as PropType<HighestBidRefRecord>,
      required: false,
    },
    isMarketplaceListing: {
      type: Boolean,
      default: false
    },
    bidColor: {
      type: String as PropType<'highest-bidder' | 'outbid'>,
      required: false,
    },
  },
  emits: ['highestBidder', 'outbid'],
  setup(props, context) {
    const loggedInUID = parseInt(store.state.user.profile.id);

    const isUserWinner = computed(() => loggedInUID == props.orderBuyerPersonId);

    const didBuyerBid = computed(() => {
      if (!props.highestBid) {
        return false;
      }
      const highestBid = props.highestBid as HighestBidRefRecord;
      if (!highestBid.bidHistory?.length) {
        return false;
      }
      return highestBid.bidHistory.some(bid => parseInt(bid.buyer.id) == loggedInUID);
    });

    const isHighestBidder = computed(() => {
      let highestBidder = parseInt((props.highestBid as HighestBidRefRecord)?.buyer?.id) == loggedInUID;
      context.emit('highestBidder', highestBidder);
      return highestBidder;
    });

    const wasBuyerOutbid = computed(() => {
      let wasOutbid = didBuyerBid.value && !isHighestBidder.value;
      context.emit('outbid', wasOutbid);
      return wasOutbid;
    });

    const highestBidderDidNotPurchase = computed(() => {
      if (!props.orderBuyerPersonId) {
        return false;
      }
      return isHighestBidder.value && !isUserWinner.value;
    });

    const getIconUrl = computed(() => {
      if (highestBidderDidNotPurchase.value) {
        return require('@/assets/icons/outbid-30px.png');
      }
      if (isHighestBidder.value || isUserWinner.value) {
        return require('@/assets/icons/highest-bid-30px.png');
      }
      if (wasBuyerOutbid.value) {
        return require('@/assets/icons/outbid-30px.png');
      }
      return '';
    });
    
    const getType = computed(() => {
      if (highestBidderDidNotPurchase.value || wasBuyerOutbid.value) {
        return 'is-danger';
      }
      if (isHighestBidder.value || isUserWinner.value) {
        return 'is-primary';
      }
      return '';
    });

    const getTooltipText = computed(() => {
      if (isUserWinner.value) {
        return 'You purchased this vehicle';
      }
      if (highestBidderDidNotPurchase.value) {
        return 'You did not purchase this vehicle';
      }
      if (isHighestBidder.value) {
        return 'You\'re the highest bidder';
      }
      if (wasBuyerOutbid.value) {
        return 'You\'ve been outbid';
      }
      return '';
    });

    const getBidAmountClass = computed(() => {
      if (props.bidColor) {
        return { [props.bidColor]: true };
      }
      return {
        'highest-bidder': isHighestBidder.value || isUserWinner.value,
        'outbid': (wasBuyerOutbid.value && !isUserWinner.value)  || highestBidderDidNotPurchase.value,
        'has-text-primary': props.isMarketplaceListing,
      }
    });

    return {
      loggedInUID,
      isUserWinner,
      didBuyerBid,
      isHighestBidder,
      wasBuyerOutbid,
      highestBidderDidNotPurchase,
      getIconUrl,
      getType,
      getTooltipText,
      getBidAmountClass,
    }
  },
});
