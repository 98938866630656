var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetails',_vm._b({scopedSlots:_vm._u([{key:"listingButtons",fn:function(){return [_c('div',{staticClass:"width-100"},[_c('div',[(_vm.highestBid.status == 'Negotiating')?_c('TheNegotiateButtonAuction',_vm._b({key:`negotiations-${_vm.highestBid.negotiations.length}`},'TheNegotiateButtonAuction',{ 
                        vehicleListingId: _vm.listing.id,
                        vin: _vm.listing.vin,
                        size: 'is-medium',
                        negotiationOffer: _vm.negotiationOffer,
                        loading: _vm.loadingNegotiationOffer,
                        expanded: true,
                    },false)):(_vm.isVehicleAuctioned(_vm.highestBid.status))?_c('ViewBidHistoryButton',_vm._b({staticClass:"bid-history-button"},'ViewBidHistoryButton',{
                        vehicle: {
                            id: _vm.listing.id,
                            year: _vm.listing.year,
                            brand: _vm.listing.brand,
                            model: _vm.listing.model,
                            heroPicUrl: _vm.listing.heroImgUrl,
                            vin: _vm.listing.vin,
                            reservePrice: _vm.listing.auction.reservePrice,
                            buyItNowPrice: _vm.listing.auction.buyItNowPrice,
                        },
                        highestBid: _vm.highestBid,
                        bidHistory: _vm.bidHistory,
                        size: 'is-medium',
                        outlined: false,
                        loading: _vm.loadingBidHistory,
                    },false)):_vm._e(),(_vm.isVehicleSold(_vm.highestBid.status))?_c('ViewOrderSummaryButton',_vm._b({staticClass:"order-summary-button mt-2"},'ViewOrderSummaryButton',{
                        vehicleListingId: _vm.listing.id,
                        vehicleStatus: _vm.highestBid.status,
                        vehicleName: `${_vm.listing.year} ${_vm.listing.brand} ${_vm.listing.model}`,
                        size: 'is-medium',
                        outlined: false,
                    },false)):_vm._e()],1)])]},proxy:true},{key:"bidHistory",fn:function(){return [_c('div')]},proxy:true}])},'AppListingDetails',{ 
        listing: _vm.listing,
        vehiclePhotos: _vm.vehiclePhotos,
        announcements: _vm.announcements,
        loadingAnnouncements: _vm.loadingAnnouncements,
        bookoutOptions: _vm.bookoutOptions,
        loadingBookoutOptions: _vm.loadingBookoutOptions,
        order: _vm.order,
        highestBid: _vm.highestBid,
        engineRecordingUrl: _vm.engineRecordingUrl,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }