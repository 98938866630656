var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",staticStyle:{"min-width":"400px"},on:{"confirm":_vm.submitAndUpdateNotes,"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Your Notes ")]},proxy:true},{key:"body",fn:function(){return [(_vm.$props.vehicleName)?_c('InfoHeader',_vm._b({},'InfoHeader',{
                title: _vm.$props.vehicleName,
                subtitle: _vm.$props.vin,
                imageUrl: _vm.$props.imageUrl,
                size: 'small',
            },false)):_vm._e(),(_vm.loadingBuyerNotes)?_c('div',{staticClass:"mt-3"},_vm._l((3),function(n){return _c('AppLoadingSkeleton',{key:`loading-${n}`,staticClass:"mb-2",attrs:{"height":"30px","width":"100%"}})}),1):_vm._e(),_c('div',{staticClass:"mt-5 buyer-notes-display"},[_c('TheListingBuyerNotes',_vm._b({key:`buyer-notes-${_vm.buyerNotes?.length}`,attrs:{"noteText":_vm.noteText,"loadingBuyerNotes":_vm.loadingBuyerNotes},on:{"update:noteText":function($event){_vm.noteText=$event},"update:note-text":function($event){_vm.noteText=$event},"update:loadingBuyerNotes":function($event){_vm.loadingBuyerNotes=$event},"update:loading-buyer-notes":function($event){_vm.loadingBuyerNotes=$event}}},'TheListingBuyerNotes',{
                    vehicleListingId: _vm.$props.vehicleListingId,
                    useCustomSubmit: true,
                    buyerNotesInitial: _vm.buyerNotes,
                },false))],1)]},proxy:true}])},'Modal',{
        isLoading: _vm.loadingSubmitNote,
        customLoading: true,
        showButtons:['close', 'submit'],
        submitButtonAttrs: {
            disabled: !_vm.noteText,
        },
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }