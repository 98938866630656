import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, formatErrorObj, openErrorDialog } from '@/utils';
import type {
    AdditionalPricingDTO,
    AdminVehicleDetailsDTO,
    APIConfig,
    AskingPriceReason,
    CompetingOfferer,
    iPlace,
    Negotiation,
    NoteDTO,
    VehicleHeaderDTO,
    VehiclePhotosDTO,
} from '@/types';
import { GET, POST, PUT } from '.';

export function loginWithVehicleListingId(data: {vehicleListingId: number, password: string}) {
    return POST('/vehicles/loginWithVehicleListingId', {
        vehicleListingId: data.vehicleListingId,
        password: data.password,
    }).then(async (response) => {
        if (response.data) {
            const loginInfo = { username: response.data.profile.email, password: data.password };
            return loginInfo;
        }
    }).catch((error) => {
        console.log(error);
    });
}

export function registerAccountWithVehicleListingId(data: {vehicleListingId: string, password: string}) {
    return POST('/vehicles/registerAccountWithVehicleListingId', {
        vehicleListingId: data.vehicleListingId,
        password: data.password,
    }).then((response) => {
        if (response.data) {
            const loginInfo = { username: response.data.profile.email, password: data.password };
            return loginInfo;
        }
    }).catch((error) => {
        console.log(error);
    });
}

export async function searchByVin(vin: string) {
    const results = await GET(`/vehicles/searchByVin/${vin}`)
        .then((res) => res.data)
        .catch((error) => {
            console.log('Error in searchByVin', vin, error);
        });
    return results;
}

export function checkFirebaseAccountStatusWithVehicleListingId(data: {vehicleListingId: number}) {
    return POST('/vehicles/checkFirebaseAccountStatusWithVehicleListingId', {
        vehicleListingId: data.vehicleListingId,
    }).then(async (response) => {
        if (response.data) {
            return response.data;
        }
    }).catch((error) => {
        console.log(error);
    });
}

export function reactivateVehicle(vehicleListingId: string) { // it is a string here because it's coming from this.$route.params.vehicleListingId which is a string
    return PUT('/vehicles/reactivateVehicle', {
        vehicleListingId,
    })
        .catch((error) => {
            console.log(error);
        });
}

export async function getNegotiationHistory(vehicleListingId: number): Promise<Negotiation[]> {
    return GET(`/vehicles/${vehicleListingId}/getNegotiationHistory`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
}

export async function clearAuctionDataAndMoveToInspectionScheduled(vehicleListingId: number, config: APIConfig={}) {
    await PUT(`/vehicles/resetToInspectionScheduled/${vehicleListingId}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
        }).catch((error) => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed reset to Inspection Scheduled',
                message: error?.response?.data?.error ?? `Unable to clear auction data for vehicle ${vehicleListingId}`,
                error,
            });
        });
}

export async function getVehicleHeaderDetails(vehicleListingId: number) {
    return GET<VehicleHeaderDTO>(`/vehicles/headerDetails/${vehicleListingId}`)
        .then((res) => res.data)
        .catch((error) => {
            openErrorDialog({
                title: 'Failed to fetch vehicle header details',
                message: `We encountered an error while fetching the vehicle header details for vehicle ${vehicleListingId}`,
                error,
            });
            return null;
        });
}

export async function getVehiclePhotos(vehicleListingId: number, config: APIConfig={}): Promise<VehiclePhotosDTO | void> {
    return GET<VehiclePhotosDTO>(`/vehicles/photos/${vehicleListingId}`)
        .then((res) => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch((error) => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch vehicle photos',
                message: `We encountered an error while fetching the vehicle photos for vehicle ${vehicleListingId}`,
                error,
            });
        });
}

export async function getVehicleAuctionDetails(vehicleListingId: number) {
    return GET<{ reserve: number }>(`/vehicles/${vehicleListingId}/getAuctionDetails`)
        .then((res) => res.data)
        .catch((error) => {
            openErrorDialog({
                title: 'Failed to fetch vehicle auction details',
                message: `We encountered an error while fetching the vehicle auction details for vehicle ${vehicleListingId}`,
                error,
            });
            return null;
        });
}

export async function getAskingPriceReasons() {
    return GET<AskingPriceReason[]>('/vehicles/getAskingPriceReasons')
        .then((res) => res.data)
        .catch((error) => {
            openErrorDialog({
                title: 'Failed to fetch asking price reason list',
                message: 'We encountered an error while fetching the asking price reason list.',
                error,
            });
            return [];
        });
}

export async function getCompetingOfferers() {
    return GET<CompetingOfferer[]>('/vehicles/getCompetingOfferers')
        .then((res) => res.data)
        .catch((error) => {
            openErrorDialog({
                title: 'Failed to fetch competing offerers list',
                message: 'We encountered an error while fetching the competing offerers list.',
                error,
            });
            return [];
        });
}

export async function saveEditedVehicle(vehicleListingId: number, details: Partial<AdminVehicleDetailsDTO>, config: APIConfig={}) {
    await PUT(`/vehicles/${vehicleListingId}`, { id: vehicleListingId.toString(), ...details })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            let celebrateValidationError = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to save vehicle edits',
                message: celebrateValidationError ?? `
                    <p>We encountered an error while updating vehicle ${vehicleListingId}</p>
                    <p class="has-text-danger">Changes attempted:<br>
                        ${formatErrorObj(details)}
                    </p>
                `,
                error,
            });
        });
}

export async function getPlaceByVehicleListingId(vehicleListingId: number, config: APIConfig={}): Promise<iPlace> {
    return await GET(`/vehicles/${vehicleListingId}/getPlaceInfo`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            let celebrateValidationError = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to get vehicle location',
                message: celebrateValidationError ?? `We encountered an error while fetching location details for vehicle ${vehicleListingId}`,
                error,
            });
        });
}

export async function getAdditionalPricingDetailsByVehicleListingId(vehicleListingId: number, config: APIConfig={}): Promise<AdditionalPricingDTO> {
    return await GET(`/vehicles/${vehicleListingId}/getPricingDetails`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            let celebrateValidationError = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to get pricing details',
                message: celebrateValidationError ?? `We encountered an error while fetching additional pricing details for vehicle ${vehicleListingId}`,
                error,
            });
        });
}

export async function createPrivateNote(vehicleListingId: number, noteText: string, config: APIConfig={}) {
    return await POST(`/vehicles/${vehicleListingId}/addNote`, { noteText })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getLoggedInUserNotesByVehicleListingId(vehicleListingId: number, config: APIConfig={}): Promise<NoteDTO[]> {
    return await GET(`/vehicles/${vehicleListingId}/note`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}