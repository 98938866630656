
import { computed, defineComponent, PropType } from 'vue';
import { getIconColorByAnnouncementType, isTitleLost } from '@/utils';
import { AnnouncementType } from '@/types';

export default defineComponent({
    name: 'TheAnnouncementIcons',
    props: {
        mostSevereAnnouncementType: {
            type: String as PropType<AnnouncementType>,
            required: false,
        },
        titleStatus: {
            type: String,
            required: false,
        },
        customIconColors: {
            type: Object as PropType<{ [key: string]: string }>,
            required: false,
        },
    },
    setup(props, context) {
        const defaultIconAttrs = computed(() => {
            return {
                icon: 'alert-circle',
                customSize: 'mdi-18px',
            }
        });

        return {
            getIconColorByAnnouncementType, 
            isTitleLost,
            defaultIconAttrs,
        }
    }
});
