
import { computed, ComputedRef, defineComponent, PropType, ref } from 'vue';
import { SRPListing } from '../types';
import { isVehicleSold, toCurrency } from '../utils';
import { useAuctionVDP, useBreakpoint, useCurrentNegotiationOffer, useGetArbitrationDetails, useHandleStatusChange, useIncrementViewCount, useSingleAuctionListener, useUpdateAuctionListingCardOnRdbChange, useUpdateWatchlistStatus, useUserRole, useVDP } from '../composables';
import { pick } from 'lodash';

import AppButton from '../components/AppButton.vue';
import AppListingDetails from '../components/AppListingDetails.vue';
import BiddingButtons from '../components/Buttons/BiddingButtons.vue';
import CountdownTimer from '../components/CountdownTimer.vue';
import TheCheckoutButton from '../components/Buttons/TheCheckoutButton.vue';
import TheListingBuyerNotes from '@/components/TheListingBuyerNotes.vue';
import TheListingDetailsCard from '@/components/TheListingDetailsCard.vue';
import TheListingDetailsListingStats from '../components/TheListingDetailsListingStats.vue';
import TheListingWatchlistButton from '../components/TheListingWatchlistButton.vue';
import TheNegotiateButtonAuction from '../components/TheNegotiateButtonAuction.vue';
import TheOpenArbitrationClaimButton from '../components/TheOpenArbitrationClaimButton.vue';
import ViewOrderSummaryButton from '../components/Buttons/ViewOrderSummaryButton.vue';

export default defineComponent({
    name: 'AppListingDetailsBuyer',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
    },
    components: {
        AppButton,
        AppListingDetails,
        BiddingButtons,
        CountdownTimer,
        TheCheckoutButton,
        TheListingBuyerNotes,
        TheListingDetailsCard,
        TheListingDetailsListingStats,
        TheListingWatchlistButton,
        TheNegotiateButtonAuction,
        TheOpenArbitrationClaimButton,
        ViewOrderSummaryButton,
    },
    emits: ['watching'],
    setup(props, context) {
        useIncrementViewCount(props.listing.id);
        const loadingBuyerNotes = ref(false);
        const { bulmaBreakpoint } = useBreakpoint();

        const {
            engineRecordingUrl,
            loadingEngineRecording,
        } = useVDP({ vehicleListingId: props.listing.id });
        
        const { 
            vehiclePhotos,
            loadingVehiclePhotos,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            bidHistory,
            loadingBidHistory,
            fetchBidHistoryOnRdbChange,
            order,
            loadingOrder,
            titleDelivery,
            vehicleDelivery,
            purchasedVehicle,
            fetchOrder,
        } = useAuctionVDP({ 
            vehicleListingId: props.listing.id,
            vehicleDetails: {
                year: props.listing.year.toString(),
                make: props.listing.brand,
                ...pick(props.listing, ['model', 'trim', 'vin']),
            }
        });

        const { highestBid, updateAuction } = useUpdateAuctionListingCardOnRdbChange();
        useSingleAuctionListener(props.listing.id!, (snapshot) => {
            updateAuction(snapshot);
            fetchBidHistoryOnRdbChange(snapshot);
            getUpdatedNegotiationOffer(props.listing.id);
        });

        useHandleStatusChange({
            rdbListener: highestBid,
            onStatusChange: (newStatus, oldStatus) => {
                if (isVehicleSold(newStatus) && !isVehicleSold(oldStatus)) {
                    fetchOrder(data => order.value = data);
                }
            }
        });

        const { updateWatchlistStatus } = useUpdateWatchlistStatus(props.listing.id, context);

        const { 
            loadingArbitrationDetails,
            arbitrationDetails,
        } = useGetArbitrationDetails(props.listing.id);

        const {
            negotiationOffer,
            loadingNegotiationOffer,
            getUpdatedNegotiationOffer,
        } = useCurrentNegotiationOffer();

        const facilitatingAuctionStoreName: ComputedRef<string | undefined> = computed(() => props.listing?.facilitatingAuction?.showInUi ? props.listing?.facilitatingAuction?.name : undefined);

        return {
            bulmaBreakpoint,
            toCurrency,
            loadingBuyerNotes,
            isVehicleSold,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            bidHistory,
            loadingBidHistory,
            vehiclePhotos,
            loadingVehiclePhotos,
            order,
            loadingOrder,
            titleDelivery,
            vehicleDelivery,
            highestBid,
            purchasedVehicle,
            updateWatchlistStatus,
            arbitrationDetails,
            negotiationOffer,
            loadingNegotiationOffer,
            engineRecordingUrl,
            loadingEngineRecording,
            facilitatingAuctionStoreName,
        }
    },
});
