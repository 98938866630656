
import { defineComponent } from 'vue';
import { useBreakpoint, usePlayAudio } from '@/composables';

import AppButton from '@/components/AppButton.vue';

export default defineComponent({
    name: 'AppAudioPlayer',
    props: {
        src: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
    },
    components: {
        AppButton,
    },
    setup(props, context) {
        const { bulmaBreakpoint } = useBreakpoint();
        
        const {
            audio,
            isPlaying,
            currentTime,
            duration,
            togglePlayPause,
            updateProgress,
            scrub,
            onEnded,
            formatTime,
        } = usePlayAudio();

        return {
            audio,
            isPlaying,
            currentTime,
            duration,
            togglePlayPause,
            updateProgress,
            scrub,
            onEnded,
            formatTime,
            bulmaBreakpoint,
        }
    }
});
