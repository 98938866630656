
import { computed, defineComponent, nextTick, onMounted, PropType, ref, watch } from 'vue';
import { ImageDTO, SRPListing, VehicleListingAnnouncementCategorizedDTO } from '@/types';
import { BBVehicleFeature } from '@/types/bbVehicleFeature';
import { getIconColorByAnnouncementType } from '@/utils';
import { useConditionReportPdf } from '@/composables';

import TheAnnouncement from './TheAnnouncement.vue';

export default defineComponent({
    name: 'TheConditionReportPdf',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicle: {
            type: Object as PropType<{ 
                year: string, 
                make: string, 
                model: string, 
                trim: string,
                vin: string,
                mileage: number,
            }>,
            required: false,
        },
        photos: {
            type: Array as PropType<ImageDTO[]>,
            required: false,
        },
        features: {
            type: Array as PropType<BBVehicleFeature[]>,
            required: false,
        },
        announcements: {
            type: Object as PropType<VehicleListingAnnouncementCategorizedDTO>,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TheAnnouncement,
    },
    emits: ['update:loading', 'loaded'],
    setup(props, context) {
        const title = `Carmigo Condition Report: ${props.vehicle!.vin}`;
        const nonEmptyAnnouncements = computed(() => {
            let announcements: VehicleListingAnnouncementCategorizedDTO = fetchedConditionReport.value?.announcements ?? props.announcements as VehicleListingAnnouncementCategorizedDTO;
            if (!announcements) {
                return undefined;
            }
            let nonEmpty: VehicleListingAnnouncementCategorizedDTO = {};
            Object.keys(announcements).forEach((c) => {
                let category = c as keyof VehicleListingAnnouncementCategorizedDTO
                if (announcements![category]?.length) {
                    nonEmpty[category] = announcements![category];
                }
            });
            return nonEmpty;
        });

        const selectedFeatures = computed(() => {
            let features = fetchedConditionReport.value?.features ?? props.features as BBVehicleFeature[];
            // @ts-ignore
            return features?.filter(feature => feature.selected);
        });

        const vehiclePhotos = computed(() => fetchedConditionReport.value?.photos ?? props.photos);
        const heroPic = computed(() => vehiclePhotos.value?.[0]);

        const {
            fetchAnnouncements,
            fetchBookoutOptions,
            fetchVehiclePhotos,
            conditionReport: fetchedConditionReport,
            loadingConditionReport,
            loadedConditionReport,
        } = useConditionReportPdf(props.vehicle as unknown as Partial<SRPListing>, props.vehicleListingId);

        watch(() => loadingConditionReport.value, () => {
            if (loadingConditionReport.value) {
                context.emit('update:loading', loadingConditionReport.value)
            }
        });

        // only emit 'loaded' once announcements/features/photos are fetched and photos are loaded
        watch(() => loadedConditionReport.value, () => {
            if (loadedConditionReport.value && imagesLoaded.value) {
                context.emit('update:loading', false);
                context.emit('loaded');
            }
        });

        const imagesLoaded = ref(false);
        watch(() => vehiclePhotos.value, () => {
            nextTick(() => {
            // only emit 'loaded' once photos are loaded
                const images = document.getElementById(`condition-report-${props.vehicleListingId}`)?.getElementsByTagName('img');
                let loadedImgCount = 0;
                if (images?.length) {
                    for (let img of images) {
                        img.onload = img.onerror = () => {
                            loadedImgCount++;

                            if (loadedImgCount == images.length) {
                                imagesLoaded.value = true;
                                if (loadedConditionReport.value) {
                                    context.emit('update:loading', false);
                                    context.emit('loaded');
                                }
                            }
                        }
                    }
                }
            })
        })

        onMounted(async () => {
            if (!props.announcements) {
                fetchAnnouncements?.();
            }
            if (!props.features) {
                fetchBookoutOptions?.();
            }
            if (!props.photos) {
                fetchVehiclePhotos?.()
            }
        })


        return {
            heroPic,
            title,
            nonEmptyAnnouncements,
            selectedFeatures,
            vehiclePhotos,
            getIconColorByAnnouncementType,
        }
    }
});
