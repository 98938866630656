
import { computed, ComputedRef, defineComponent, onMounted, PropType } from 'vue';
import { MarketPlaceOffererTypeEnum, MarketplaceOfferStatusEnum } from '@/enums';
import { formatDate, getListingTypeColor, toCurrencyShowDashesIfNull } from '@/utils';
import { useBreakpoint, useLoggedInUser, useSingleMarketplaceListingListener, useStoreHighestOffer, useUpdateMarketplaceListingDetailsOnListenerUpdate } from '@/composables';
import { marketplaceCurrentOfferDTO, MarketplaceRefSnapshot } from '@/types';

import CountdownTimer from './CountdownTimer.vue';
import TheListingActivityCard from './TheListingActivityCard.vue';
import TheListingDetailsViewAndCounts from './TheListingDetailsViewAndCounts.vue';
import TheMarketplaceListingButtons from './TheMarketplaceListingButtons.vue';

export default defineComponent({
    name: 'TheListingActivityCardMarketplace',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: true,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
        sellerStoreId: {
            type: Number,
            required: true,
        },
        sellerPersonId: {
            type: Number,
            required: true,
        },
        reservePrice: {
            type: Number,
            required: true,
        },
        buyItNowPrice: {
            type: Number,
            required: false,
        },
        canBuyItNow: {
            type: Boolean,
            required: false,
        },
        buyerCurrentOffer: {
            type: Object as PropType<marketplaceCurrentOfferDTO>,
            required: false,
        },
        storeIds: { // viewing multiple stores' listings
            type: Array as PropType<number[]>,
            required: false,
        },
    },
    components: {
        CountdownTimer,
        TheListingActivityCard,
        TheListingDetailsViewAndCounts,
        TheMarketplaceListingButtons,
    },
    setup(props, { emit, attrs }) {
        const { bulmaBreakpoint } = useBreakpoint();
        const viewingMultipleStores = computed(() => Boolean(props.storeIds?.length));
        const {
            listingDetails,
            isCheckoutOrLater,
            currentOfferKey,
            getUpdatedMarketplaceListingDetailsOnSnapshot,
            getUpdatedMarketplaceListingDetails,
        } = useUpdateMarketplaceListingDetailsOnListenerUpdate({
            marketplaceListingId: props.marketplaceListingId,
            currentOffer: props.buyerCurrentOffer,
        });

        useSingleMarketplaceListingListener(props.vehicleListingId, (snapshot) => {
            if (snapshot.isInitialSnapshot) {
                return;
            }
            getUpdatedMarketplaceListingDetailsOnSnapshot(snapshot as MarketplaceRefSnapshot);
        });

        const userCurrentOffer = computed(() => listingDetails.value?.currentOffer);

        const userCurrentOfferStatus: ComputedRef<string | null> = computed(() => {
            if (!userCurrentOffer.value?.offerStatusId) {
                return null;
            }
            return MarketplaceOfferStatusEnum[userCurrentOffer.value?.offerStatusId].toLowerCase();
        });

        const userCurrentOfferOffererType: ComputedRef<string | null> = computed(() => {
            if (!userCurrentOffer.value?.mostRecentOffererTypeId) {
                return null;
            }
            return MarketPlaceOffererTypeEnum[userCurrentOffer.value.mostRecentOffererTypeId].toLowerCase();
        });

        const {
            storeHighestOffer,
            loadingStoreHighestOffer,
            getStoreHighestOffer,
        } = useStoreHighestOffer();
        onMounted(() => {
            if (props.storeIds) {
                getStoreHighestOffer(props.vehicleListingId, props.storeIds);
            }
        });

        const { loggedInUserName } = useLoggedInUser();

        const didSellerSendOffer = computed(() => {
            return userCurrentOfferStatus.value === 'active' && userCurrentOfferOffererType.value === 'seller';
        });

        const isUserCurrentOfferExpired = computed(() => {
            if (!userCurrentOffer.value?.offerExpirationDate) {
                return false;
            }
            return new Date(userCurrentOffer.value.offerExpirationDate) < new Date();
        });

        const getClasses = computed(() => {
            return {
                'is-second-chance': props.isSecondChance,
                'show-listing-type': attrs.showListingTypeFlag,
                'multiple-stores': viewingMultipleStores.value,
            }
        });

        const getListingTypeLabel = computed(() => {
            if (props.isSecondChance && bulmaBreakpoint.value.mobile) {
                return bulmaBreakpoint.value.mobile ? '2nd Chance' : 'Second Chance';
            } else {
                return 'Marketplace';
            }
        });

        return {
            bulmaBreakpoint,
            viewingMultipleStores,
            formatDate,
            toCurrencyShowDashesIfNull,
            getListingTypeLabel,
            getListingTypeColor,
            getClasses,
            loggedInUserName,

            userCurrentOfferStatus,
            userCurrentOfferOffererType,
            didSellerSendOffer,
            isUserCurrentOfferExpired,

            userCurrentOffer,
            isCheckoutOrLater,
            getUpdatedMarketplaceListingDetails,
            listingDetails,
            currentOfferKey,

            storeHighestOffer,
            loadingStoreHighestOffer,
        }
    }
});
