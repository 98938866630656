
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import { PropType } from 'vue/types/v3-component-props';
import { focusOnElement } from '../utils';

type AutocompleteInputOption = { [key: string]: any };

export default defineComponent({
    name: 'AutocompleteInput',
    props: {
        options: {
            type: Array as PropType<AutocompleteInputOption[]>,
            default: () => { return [] },
        },
        value: {
            type: [Object, String, Number] as PropType<AutocompleteInputOption | string | number>,
            required: false,
        },
        initialInput: {
            type: String,
            required: false,
        },
        field: { 
            // an AutocompleteInputOption key
            type: String,
            default: 'name',
        },
        autofocus: {
            type: Boolean,
            default: true,
        },
        optionValueKey: {
            type: String,
            required: false,
        },
        optionLabelKey: {
            type: String,
            required: false,
        }
    },
    emits: ['select', 'update:value', 'input', 'clear'],
    setup(props, { emit }) {
        const input: Ref<string> = ref(props.initialInput ?? (props.value ? props.value[props.field] : ''));
        
        onMounted(() => {
            if (props.autofocus) {
                focusOnElement('autocomplete-input');
            }
        });

        function emitSelectedValue(event: any) {
            if (!event) {
                emit('clear', event);
                return;
            }
            emit('select', event);
            emit('update:value', event);
        }

        const filteredData = computed(() => {
            return props.options?.filter(option => {
                if (input.value && option[props.field]) {
                    return option[props.field].toString().toLowerCase().indexOf(input.value.toLowerCase()) >= 0;
                }
                return true;
            });
        });

        const selectedOption = computed(() => {
            return props.options?.find(option => option[props.field] == input.value);
        });

        function emitInput(event) {
            if (!selectedOption.value) {
                emit('input', event);
                return;
            }
            if (props.optionValueKey) {
                emit('input', selectedOption.value[props.optionValueKey]);
                return;
            }
            emit('input', selectedOption.value);
        }
        
        return {
            input,
            emitInput,
            emitSelectedValue,
            filteredData,
            selectedOption,
        }
    },
});
