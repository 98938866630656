
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { VehicleStatus } from '@/types';
import { formatDate, isVehicleInCheckoutStatus, toCurrencyShowDashesIfNull } from '@/utils';
import { useFetchOrder, useGetArbitrationDetails, useOrderSummaryModal, useSingleAuctionListener, useUpdateAuctionListingCardOnRdbChange, useUser, useUserRole } from '@/composables';

import AppButton from './AppButton.vue';
import TheCheckoutButton from './Buttons/TheCheckoutButton.vue';
import TheListingActivityCard from './TheListingActivityCard.vue';
import TheOpenArbitrationClaimButton from './TheOpenArbitrationClaimButton.vue';
import TheTitleAndDeliveryStatusIcons from './TheTitleAndDeliveryStatusIcons.vue';

export default defineComponent({
    name: 'TheListingActivityCardPurchased',
    props: {
        vehicleListingId: {
            type: Number as PropType<number>,
            required: true,
        },
        vehicleStatus: {
            type: String as PropType<VehicleStatus>,
            required: false,
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        },
        storeIds: { // viewing multiple stores' listings
            type: Array as PropType<number[]>,
            required: false,
        },
    },
    components: {
        AppButton,
        TheCheckoutButton,
        TheListingActivityCard,
        TheOpenArbitrationClaimButton,
        TheTitleAndDeliveryStatusIcons,
    },
    setup(props) {
        const viewingMultipleStores = computed(() => Boolean(props.storeIds?.length));
        const user = useUser();
        const loggedInUserPersonId = parseInt(user.value?.profile.id);
        const { isUserAdmin, isUserDsr } = useUserRole();

        const { highestBid, updateAuction } = useUpdateAuctionListingCardOnRdbChange();
        useSingleAuctionListener(props.vehicleListingId, updateAuction);

        const status: ComputedRef<VehicleStatus | undefined> = computed(() => highestBid.value?.status ?? props.vehicleStatus);
        const needsCheckout = computed(() => isVehicleInCheckoutStatus(status.value));

        const { openOrderSummaryModal } = useOrderSummaryModal({
            vehicleListingId: props.vehicleListingId,
            vehicleStatus: status.value,
            isUserAdminOrDsr: isUserAdmin.value || isUserDsr.value,
        });

        const { 
            order,
            loadingOrder,
        } = useFetchOrder(props.vehicleListingId);

        const {
            arbitrationDetails,
        } = useGetArbitrationDetails(props.vehicleListingId);

        const isMyPurchase = computed(() => order.value?.buyerPersonId == loggedInUserPersonId);
        
        return {
            viewingMultipleStores,
            formatDate,
            toCurrencyShowDashesIfNull,
            isMyPurchase,
            needsCheckout,
            openOrderSummaryModal,
            order,
            loadingOrder,
            arbitrationDetails,
            loggedInUserPersonId,
            isUserAdmin,
            isUserDsr,
            highestBid,
            status,
        }
    }
});
