var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingDetailsAnnouncements',_vm._b({key:`announcements-${_vm.announcementsKey}`,on:{"edit":_vm.openCreateAnnouncementModal,"delete":_vm.openDeleteAnnouncementConfirmationDialog,"viewPhoto":function($event){return _vm.$emit('viewPhoto', $event)}},scopedSlots:_vm._u([{key:"announcementsTop",fn:function(){return [(_vm.isEditingMode)?_c('div',{staticClass:"flex-row justify-center"},[_c('AppButton',_vm._b({staticClass:"no-border is-size-7 text-thin underline",on:{"click":function($event){return _vm.openCreateAnnouncementModal()}}},'AppButton',{
                    label: 'add announcement',
                    type: 'is-primary',
                    iconLeft: 'plus',
                    outlined: true,
                },false))],1):_vm._e()]},proxy:true},{key:"cardHeaderButton",fn:function(){return [(_vm.isEditingMode)?_c('a',{staticClass:"underline is-size-7",on:{"click":function($event){$event.stopPropagation();return _vm.addUnknownCategories.apply(null, arguments)}}},[_vm._v("add categories")]):_vm._e()]},proxy:true}])},'TheListingDetailsAnnouncements',{
        ..._vm.$props,
        ..._vm.$attrs,
        announcements: _vm.updatedAnnouncements,
        showAllAnnouncements: true,
        cardAttrs: {
            isEditable: _vm.isEditingMode,
            open: _vm.updatedAnnouncements?.unknown?.length ? false : undefined,
        },
        unknownCardAttrs: {
            open: true,
        },
        showEngineRecording: !_vm.isEditingMode,
        showEmptyText: !_vm.isEditingMode,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }