var render = function render(){var _vm=this,_c=_vm._self._c;return _c('InspectionForm',_vm._b({on:{"saved":_vm.updateAfterSavingInspection},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('Modal',_vm._b({key:_vm.photosKey,attrs:{"id":"editPhotosModal"},on:{"close":function($event){_vm.$emit('toggleEditData'); _vm.$emit('close');}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Edit Photos ")]},proxy:true},{key:"body",fn:function(){return _vm._l((Object.keys(_vm.photosCopy)),function(sectionName){return _c('section',{key:`photos-${sectionName}`,staticClass:"mb-3 gallery-section",attrs:{"id":`${sectionName}-photos`}},[_c('EditableGallery',{attrs:{"headerText":sectionName,"photos":_vm.photosCopy[sectionName].images,"filePath":`${_vm.vehicleListingId}/inspection/${sectionName}`,"numColumns":_vm.numColumns,"isStickyHeader":_vm.isStickyHeader,"moveToSectionOptions":_vm.getMoveToSectionOptions(sectionName)},on:{"uploading":function($event){_vm.uploadingPhotos = true},"input":_vm.updatePhotos,"activeChange":function($event){_vm.hasOpenDropdown = $event}}})],1)})},proxy:true},{key:"buttonsRight",fn:function(){return [_c('div',{staticClass:"flex-row"},[_c('b-button',{attrs:{"label":_vm.hasChanges ? `Revert Changes` : 'Cancel',"disabled":slotProps.loadingSave,"type":"is-danger"},on:{"click":function($event){_vm.hasChanges ? _vm.resetGallery() : _vm.$emit('close')}}}),_c('b-button',{attrs:{"label":"Save Changes","type":"is-primary","loading":slotProps.loadingSave,"disabled":!_vm.hasChanges || _vm.uploadingPhotos},on:{"click":function($event){return _vm.saveChanges(slotProps.updateInspectionData, slotProps.saveData)}}})],1)]},proxy:true}],null,true)},'Modal',{
                customButtons: true,
                jumpToList: _vm.jumpToList,
                useJumpToIcon: false,
                showJumpToOnScrollUp: false,
                useCarmigoModal: _vm.windowWidth > 768,
            },false))]}}])},'InspectionForm',{
        updateType: 'savePhotos',
        isEditing: true,
        allowEdits: false,
        toggleIsEditingAfterSaving: false,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }