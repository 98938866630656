/* eslint-disable no-use-before-define */
import { fireEvent } from '@/segment';
import { getRedirectPathByRole, scrollToTop } from '@/utils';
import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';
import store from '@/vuex';
import { 
  checkAdminAuth, 
  checkAuth, 
  checkAuthAndIgnoreInstantOffer,
  checkInspectorAuth, 
  checkPaveStatus, 
  checkSellerAuth, 
  checkStoreManagerAuth, 
  checkWithCustomRestriction, 
  getVehicleListingFilterParams,
  isVehicleComplete, 
  redirectForbiddenRoles,
  returningUsersRedirect, 
} from './routerFunctions';

Vue.use(VueRouter);


// const routes = <Array<RouteConfig>> [
//   {
//     path: '/login',
//     name: 'Login',
//     component: () => import('../views/Login.vue'),
//   },
//   {
//     path: '/login/:userId',
//     name: 'Login',
//     component: () => import('../views/Login.vue'),
//   },
//   {
//     path: '/updatedatabase',
//     name: 'UpdateDatabase',
//     component: () => import('../views/Admin/UpdateDatabase.vue'),
//     beforeEnter: checkAdminAuth,
//   },
//   {
//     path:'/*',
//     name: 'Maintenance',
//     component: () => import('../views/DownForMaintenance.vue')
//   }, 
// ]

const routes = <Array<RouteConfig>>[
  {
    path: '/',
    name: 'Home',
    redirect: () => getRedirectPathByRole(),
  },
  // {
  //   path: '/home',
  //   name: 'Get Estimate',
  //   component: () => import('../components/GetEstimate/GetEstimate.vue'),
  //   beforeEnter: checkWithCustomRestriction,
  //   query: { year: '' },
  // },

  // MAKE IT EASY START
  {
    path: '/test-mie/:vehicleListingId',
    name: 'Test MIE',
    component: () => import('../components/TestMakeItEasy.vue')
  },
  {
    path: '/welcomeback/:vehicleListingId',
    name: 'Welcome Back',
    component: () => import('../components/MakeItEasy/MakeItEasyVehicleBasics.vue'), // this is a catch - normally they're redirected through returningUsersRedirect
    beforeEnter: returningUsersRedirect,
  },
  
  // Make It Easy - LEAD SUBMISSION
  {
    path: '/home/:vin?',
    name: 'Get Estimate',
    component: () => import('../components/MakeItEasy/MakeItEasyVehicleBasics.vue'),
    query: { year: '' },
  },
  {
    path: '/vin',
    name: 'Search By VIN',
    component: () => import('../components/MakeItEasy/MakeItEasySearchByVin.vue'),
  },
  {
    path: '/phoneLogin',
    name: 'Phone Login',
    component: () => import('../components/MakeItEasy/MakeItEasyPhoneLogin.vue'),
  },
  {
    path: '/selectVehicle',
    name: 'Select Vehicle',
    beforeEnter: checkAuth,
    component: () => import('../components/MakeItEasy/SelectVehicle.vue'),
  },
  {
    path: '/howDidYouHear/:vehicleListingId',
    name: 'How Did You Hear',
    beforeEnter: checkAuth,
    component: () => import('../components/MakeItEasy/HowDidYouHear.vue'),
  },
  {
    path: '/sellingOptions/:vehicleListingId',
    name: 'Selling Options',
    beforeEnter: checkAuth,
    component: () => import('../components/MakeItEasy/SellingOptions.vue')
  },
  {
    path: '/getMoreWithCarmigo/:vehicleListingId',
    name: 'Get More With Carmigo',
    beforeEnter: checkAuth,
    component: () => import('../components/InstantOffer/GetMoreWithCarmigo.vue')
  },
  {
    path: '/getMultipleOffers/:vehicleListingId',
    name: 'Get Multiple Offers',
    beforeEnter: checkAuth,
    component: () => import('../components/MakeItEasy/MakeItEasyGetMultipleOffers.vue'),
  },
  {
    path: '/howCarmigoGetsYouMore/:vehicleListingId',
    name: 'How Carmigo Gets You More',
    beforeEnter: checkAuth,
    component: () => import('../components/MakeItEasy/MakeItEasyHowCarmigoGetsYouMore.vue'),
  },
  {
    path: '/instantOffer/:vehicleListingId',
    name: 'Instant Offer',
    component: () => import('../components/MakeItEasy/MakeItEasyViewInstantOffer.vue'),
    beforeEnter: checkAuthAndIgnoreInstantOffer,
  },
  {
    path: '/instantOfferPhotos/:vehicleListingId',
    name: 'Instant Offer Photos',
    component: () => import('../components/MakeItEasy/MakeItEasyViewInstantOffer.vue'),
    beforeEnter: checkAuthAndIgnoreInstantOffer,
  },
  {
    path: '/getPaid/:vehicleListingId',
    name: 'Get Paid',
    component: () => import('../components/MakeItEasy/MakeItEasyGetPaid.vue'),
    beforeEnter: checkAuthAndIgnoreInstantOffer,
  },
  {
    path: '/instantOfferCompleted/:vehicleListingId',
    name: 'Instant Offer Completed',
    beforeEnter: checkAuthAndIgnoreInstantOffer,
  },
  {
    path: '/instantOfferLost/:vehicleListingId',
    name: 'Instant Offer Lost',
    component: () => import('../components/MakeItEasy/InstantOfferLost.vue'),
    beforeEnter: checkAuthAndIgnoreInstantOffer,
  },
  {
    // I think they get sent here from /instantOffer if the offer is expired
    path: '/refreshInstantOffer',
    name: 'Refresh Instant Offer',
    component: () => import('../components/MakeItEasy/RefreshInstantOffer.vue'),
    beforeEnter: checkAuthAndIgnoreInstantOffer,
  },
  {
    path: '/questionnaire/:vehicleListingId',
    name: 'Questionnaire',
    component: () => import('../components/MakeItEasy/SellerQuestionnaireShort.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/set-request-price/:vehicleListingId',
    name: 'Set Request Price',
    component: () => import('@/components/MakeItEasy/MakeItEasySetMinimumPrice.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/asking-price-reason/:vehicleListingId',
    name: 'Asking Price Reason',
    component: () => import('@/components/MakeItEasy/MakeItEasyAskingPriceReason.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/confirm-request-price/:vehicleListingId',
    name: 'Confirm Request Price',
    component: () => import('@/components/MakeItEasy/MakeItEasyConfirmRequestPrice.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/offer-source/:vehicleListingId',
    name: 'Offer Source',
    component: () => import('@/components/MakeItEasy/MakeItEasyOfferSource.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/photos/:vehicleListingId',
    name: 'Take Photos',
    component: () => import('../components/MakeItEasy/MakeItEasyTakePhotos.vue'),
    beforeEnter: checkPaveStatus,
  },
  {
    path: '/bypass/:vehicleListingId',
    name: 'Bypass Photos',
    component: () => import('../views/BypassPavePhotos.vue'),
    beforeEnter: checkAuth,
    props: {
      redirectTo: 'Seller Agreement',
    }
  },
  {
    path: '/sellerAgreement/:vehicleListingId',
    name: 'Seller Agreement',
    component: () => import('../components/MakeItEasy/MakeItEasySellerAgreement.vue'),
    beforeEnter: isVehicleComplete //this isVehicleComplete function also calls checkAuth to confirm user is logged in
  },
  {
    path: '/vehicleOverview/:vehicleListingId',
    name: 'Vehicle Overview',
    component: () => import('../components/MakeItEasy/VehicleOverview.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/preview/:vehicleListingId',
    name: 'Vehicle Preview',
    component: () => import('../components/MakeItEasy/VehiclePreview.vue'),
    beforeEnter: checkAuth,
  },

  // Make It Easy - AFTER AUCTION END
  {
    path: '/congratulations/:vehicleListingId',
    name: 'Congratulations',
    component: () => import('../components/MakeItEasy/MakeItEasyCongratulations.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/offerExpired/:vehicleListingId',
    name: 'OfferExpired',
    component: () => import('../components/MakeItEasy/MakeItEasyOfferExpired.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/noOffers/:vehicleListingId',
    name: 'No Offers',
    component: () => import('../components/MakeItEasy/MakeItEasyNoOffers.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/minimumPriceNotMet/:vehicleListingId',
    name: 'Minimum Price Not Met',
    component: () => import('../components/MakeItEasy/MinimumPriceNotMet.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/counterOfferSubmitted/:vehicleListingId',
    name: 'Counter Offer Submitted',
    component: () => import('../components/MakeItEasy/CounterOfferSubmitted.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/newCounterOffer/:vehicleListingId',
    name: 'New Counter Offer',
    component: () => import('../components/MakeItEasy/NewCounterOffer.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/submitCounterOffer/:vehicleListingId',
    name: 'Submit Counter Offer',
    component: () => import('../components/MakeItEasy/SellerSubmitCounterOffer.vue'),
    beforeEnter: checkAuth,
  },
  // MAKE IT EASY END

  {
    path: '/getEstimateEnd',
    name: 'Get Estimate End',
    component: () => import('../components/GetEstimate/GetEstimateEnd.vue'),
  },
  {
    path: '/anonymous/getEstimateEnd',
    name: 'Get Estimate End',
    component: () => import('../components/GetEstimate/GetEstimateEnd.vue'),
  },
  {
    path: '/phone-login/:originPublicId',
    name: 'Phone Number Login',
    component: () => import('../views/LoginWithPhonePage.vue'),
  },
  {
    path: '/direct-login/:originPublicId',
    name: 'Carmigo Direct Login',
    component: () => import('../components/CarmigoDirect/CarmigoDirectLogin.vue'),
  },
  {
    path: '/createAccount/:originPublicId',
    name: 'Carmigo Direct Create Account',
    component: () => import('../components/CarmigoDirect/CarmigoDirectCreateAccount.vue'),
  },
  {
    path: '/createAccount',
    name: 'Create Account',
    component: () => import('../components/CreateAccount.vue'),
  },
  {
    path: '/paveComplete/:vehicleListingId',
    name: 'Seller Finished Photos',
    component: () => import('../components/SellerDashboard/MainSellerDashboard.vue'),
    beforeEnter: checkPaveStatus,
  },
  {
    path: '/MainSellerDashboard/PreAuctionFinishedPhotos',
    name: 'Seller Finished Photos',
    component: () => import('../components/SellerDashboard/MainSellerDashboardFinishedPhotos.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/MainSellerDashboard/PreAuctionStepCompleted/:lastCompletedStep/:vehicleListingId',
    name: 'Seller Step Complete',
    component: () => import('../components/SellerDashboard/MainSellerDashboardStepCompleted.vue'),
  },
  {
    path: '/MainSellerDashboard/StepCompleted/:lastCompletedStep/:vehicleListingId/:askingPrice/:sellerAndVehicleName',
    name: 'Carmigo Direct Step Complete',
    component: () => import('../components/CarmigoDirect/CarmigoDirectStepCompleted.vue'),
  },
  {
    path: '/MainSellerDashboard/SellerPreAuctionPhotosPage/:vehicleListingId',
    name: 'Seller PreAuction Photos',
    component: () => import('../components/SellerDashboard/PreAuctionSellerPhotosPage.vue'),
    props: true,
  },
  {
    path: '/MainSellerDashboard/LiveAuction/:vehicleListingId',
    name: 'Seller Live Auction',
    component: () => import('../components/SellerDashboard/SellerAuctionPage.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/MainSellerDashboard/SellerReviewPage/:vehicleListingId',
    name: 'Seller Review Page',
    component: () => import('../components/SellerDashboard/PreAuctionSellerReviewPage.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/car-profile/reviewing/:vehicleListingId',
    name: 'Carmigo Direct Seller Review Page',
    component: () => import('../components/CarmigoDirect/CarmigoDirectSellerReviewPage.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/SellerDashboard/SellerReviewPage/:vehicleListingId',
    name: 'Carmigo Direct Seller Review Page',
    component: () => import('../components/CarmigoDirect/CarmigoDirectSellerReviewPage.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/car-profile/view-offer/:vehicleListingId',
    name: 'Carmigo Direct View Offer',
    component: () => import('../components/CarmigoDirect/CarmigoDirectViewOffer.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/car-profile/offer-expired/:vehicleListingId',
    name: 'Carmigo Direct Offer Expired',
    component: () => import('../components/CarmigoDirect/CarmigoDirectOfferExpired.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/car-profile/delivery-overdue/:vehicleListingId',
    name: 'Carmigo Direct Delivery Overdue',
    component: () => import('../components/CarmigoDirect/CarmigoDirectDeliveryOverdue.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/car-profile/sold/:vehicleListingId',
    name: 'Carmigo Direct Car Sold',
    component: () => import('../components/CarmigoDirect/CarmigoDirectCarSold.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/auction-partner/:vehicleListingId',
    name: 'Carmigo Direct Auction Partner',
    component: () => import('../components/CarmigoDirect/CarmigoDirectAuctionPartner.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/no-offer/:vehicleListingId',
    name: 'Carmigo Direct No Offer',
    component: () => import('../components/CarmigoDirect/CarmigoDirectNoOffer.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/MainSellerDashboard/SellerAcceptTOS/:vehicleListingId',
    name: 'Accept TOS',
    component: () => import('../components/SellerDashboard/SellerAcceptTOS.vue'),
  },
  {
    path: '/car-profile/:vehicleListingId',
    name: 'Carmigo Direct Seller Car Profile',
    component: () => import('../components/CarmigoDirect/CarmigoDirectSellerCarProfile.vue'),
  },
  {
    path: '/MainSellerDashboard/:vehicleListingId',
    name: 'Main Seller Dashboard',
    component: () => import('../components/SellerDashboard/MainSellerDashboard.vue'),
  },
  {
    path: '/MainSellerDashboard/SetReservePrice/:vehicleListingId',
    name: 'Reserve',
    component: () => import('../components/SellerDashboard/MainSetReservePrice.vue'),
  },
  {
    path: '/MainSellerDashboard/SetAskingPrice/:vehicleListingId',
    name: 'Set Asking Price',
    component: () => import('../components/CarmigoDirect/SetAskingPrice.vue'),
  },
  {
    path: '/launchSellerQuestionnaire/:vehicleListingId',
    name: 'Launch Seller Questionnaire',
    component: () => import('../components/SellerDashboard/SellerQuestionnaireLaunch.vue'),
  },
  {
    path: '/sellerQuestionnaire/:vehicleListingId',
    name: 'Seller Questionnaire',
    component: () => import('../components/SellerDashboard/SellerQuestionnaire.vue'),
    props: true,
  },
  {
    path: '/sellerQuestionnaireFinish/:vehicleListingId',
    name: 'Seller Questionnaire Finish',
    component: () => import('../components/SellerDashboard/QuestionnaireFinished.vue'),
    props: true,
  },
  {
    path: '/signup',
    name: 'Sign Up',
    component: () => import('../views/AppSignup.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/AppLogin.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/login/:userId',
    name: 'Login',
    component: () => import('../views/AppLogin.vue'),
  },
  {
    path: '/login/:userId/autoCreate',
    name: 'Login',
    component: () => import('../views/AppLogin.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
  },
  // {
  //   path: '/',
  //   name: 'Audit',
  //   component: () => import('../views/SubmitLead/SubmitLead.vue')
  // },
  // {
  //   path: '/valuation',
  //   name: 'Valuation',
  //   component: () => import('../views/SubmitLead/VehicleValuation.vue'),
  // },
  {
    path: `/live-auctions${getVehicleListingFilterParams()}`,
    name: 'Auctions',
    component: () => import('../views/TheSRPLiveListings.vue'),
    beforeEnter: checkWithCustomRestriction,
  },
  {
    path: '/past-auctions',
    name: 'PastAuctions',
    component: () => import('../views/TheSRPLiveListings.vue'),
    beforeEnter: checkWithCustomRestriction,
  },
  {
    path: '/referral/:code',
    name: 'Referral Link',
    component: () => import('../views/Audit/Referral.vue'),
  },
  {
    path: '/origin/:publicId',
    name: 'Set Origin',
    component: () => import('../components/SetOrigin.vue'),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/referrals',
    name: 'Referrals',
    component: () => import('../views/Referrals.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/auctions/:id/:activeTab',
    name: 'Auction Details',
    component: () => import('../views/AppListingDetailsBase.vue'),
    beforeEnter: (to,from,next) => {
      const isLoggedIn = store.getters.isAuthenticated;
      // if 'source' is provided, we want to redirect to Login instead of Guest VDP
      if (!isLoggedIn && !to.query.source) { 
        next(); // guest VDP  
      } else {
        redirectForbiddenRoles(to, from, next, ['seller'], true)
      }
    },
  },
  {
    path: '/selling/:id',
    name: 'Seller Details',
    component: () => import('../views/AuctionDetails.vue'),
    beforeEnter: checkSellerAuth,
  },
  {
    path: '/dashboard/:dashboardType/:customTab',
    name: 'Dashboard',
    component: () => import('../views/Dashboard/MyListings.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/inventory/:customTab/:storeId',
    name: 'My Inventory',
    component: () => import ('../views/DealerInventory/DealerInventoryDashboard.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/carmigoDirect',
    name: 'Carmigo Direct SRP',
    component: () => import('../components/CarmigoDirect/CarmigoDirectSRP.vue'),
  },
  {
    path: '/arbitrations',
    name: 'Arbitrations Dashboard',
    component: () => import('../views/Arbitrations/ArbitrationsDashboard.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/inspector',
    name: 'Inspector',
    component: () => import('../views/Inspector/InspectorDashboard.vue'),
    beforeEnter: checkInspectorAuth,
  },
  {
    path: '/inspector/:vehicleListingId',
    name: 'Inspection Details',
    component: () => import('../views/AppListingDetailsBase.vue'),
    beforeEnter: checkInspectorAuth,
    props: true,
  },
  {
    path: '/review-listing/:id/:marketplaceListingId?',
    name: 'Review Your Listing',
    component: () => import('../views/AppListingDetailsBase.vue'),
    beforeEnter: checkAuth,
  },


  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/TheAdminDashboardBase.vue'),
    beforeEnter: checkAdminAuth,
  },

  {
    path: '/updatedatabase',
    name: 'UpdateDatabase',
    component: () => import('../views/Admin/UpdateDatabase.vue'),
    // beforeEnter: checkAdminAuth,
  },
  {
    path: '/stores/:id?',
    name: 'Stores',
    component: () => import('../views/StoreManager/Stores.vue'),
    beforeEnter: checkAdminAuth,
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe.vue'),
  },

  {
    path: '/outOfServiceRange',
    name: 'Out of Service Range',
    component: () => import('../views/InspectionOutOfRange.vue'),
  },

  {
    path: '/virtualInspection',
    name: 'Virtual Inspection',
    component: () => import('../views/InspectionVirtualRange.vue'),
  },

  {
    path: '/addBank/:storeId/:email/:status',
    name: 'Add-Bank',
    component: () => import('../views/StoreACHLanding.vue'),
  },
  {
    path: '/addBankUpdate/:storeId/:email/:status',
    name: 'Add-Bank',
    component: () => import('../views/StoreACHLanding.vue'),
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: () => import('../views/RestrictedReroute.vue'),
  },
  {
    path: '/verifyMicroDeposits/:storeId/:email/:status',
    name: 'Micro-Deposits',
    component: () => import('../views/StoreACHLanding.vue'),
  },
  {
    path: '/dwollaBusinessUpgrade/:storeId/:email/:status',
    name: 'Business-Upgrade',
    component: () => import('../views/StoreACHLanding.vue'),
  },
  {
    path: '/widget/:originPublicId',
    name: 'Dealer-Widget',
    component: () => import('../views/Widgets/DealerWidget/DealerWidget.vue'),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/widget/valuation/:originPublicId',
    name: 'Dealer-Widget-Valuation',
    component: () => import('../views/Widgets/DealerWidget/DealerWidgetVehicleValuation.vue'),
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '/dealerLandingPage',
    name: 'Dealer Landing Page',
    component: () => import('../components/CarmigoDirect/DealerLandingPage.vue'),
  },
  {
    path: '/fee-structure-test',
    name: 'FeeStructureTestView',
    component: () => import('../components/FeeStructureTestView.vue'),
  },
  {
    path: '/turn',
    name: 'Turn',
    component: () => import('../views/Turn.vue'),
  },
  {
    path: '/admin/listing/:vehicleListingId',
    name: 'Admin Listing Mobile',
    component: () => import('../components/TheAdminListingMobileBase.vue'),
    beforeEnter: checkAdminAuth,
    meta: { transitionName: 'fade' }
  },
  {
    path: '/admin/listing/:vehicleListingId/:details',
    name: 'Admin Mobile Listing Details',
    component: () => import('../components/TheAdminListingDetailsMobileBase.vue'),
    beforeEnter: checkAdminAuth,
    meta: { transitionName: 'fade' }
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  scrollToTop();
  next();
});

router.afterEach((to) => {
  try {
    fireEvent(to.name ? to.name : 'Unkown route name', { route: to.fullPath });
  } catch (error) {
    console.log(error);
  }
  document.querySelectorAll('.modal').forEach((modal) => {
    // @ts-expect-error: __vue__ is internal
    // eslint-disable-next-line no-underscore-dangle
    modal.__vue__?.$vnode?.context?.close();
  });

  if (to.name) { 
    Vue.nextTick( () => {
      document.title = to.name!;
    });
  }
});

export default router;
