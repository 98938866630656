
import { defineComponent, onBeforeMount, PropType, Ref, ref } from 'vue';
import { useEditableFields } from '../composables';
import { getAllStoreEmployees } from '../api/company';
import { SellerType, StoreEmployee, TitleStatus } from '../types';

import AutocompleteInput from './AutocompleteInput.vue';
import BuefyDropdown from './BuefyDropdown.vue';
import EditableField from './Forms/EditableField.vue';
import TheListingDetailsSellerDetails from './TheListingDetailsSellerDetails.vue';

export default defineComponent({
    name: 'TheListingDetailsSellerDetailsEditable',
    props: {
        isEditingMode: {
            type: Boolean,
            default: false,
        },
        titleStatus: {
            type: String as PropType<TitleStatus>,
            required: false,
        },
        sellerType: {
            type: String as PropType<SellerType>,
            required: false,
        },
        sellerReason: {
            type: String,
            required: false,
        },
        store: {
            type: String,
            required: false,
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        },
        sellerStoreEmployeeId: {
            type: Number,
            required: false,
        },
    },
    components: {
        AutocompleteInput,
        BuefyDropdown,
        EditableField,
        TheListingDetailsSellerDetails,
    },
    emits: ['input'],
    setup(props, context) {
        const isEditingSeller = ref(false);

        const titleStatus: TitleStatus | 'Unknown' = ['Lost', 'Title Absent'].includes(props.titleStatus) ? 'Unknown' : props.titleStatus;

        const updatedFields: Ref<{
            titleStatus?: TitleStatus | 'Unknown',
            sellerType?: SellerType,
            sellerReason?: string,
            sellerStoreEmployeeId?: number,
        }> = ref({
            titleStatus,
            sellerType: props.sellerType,
            sellerReason: props.sellerReason,
            sellerStoreEmployeeId: props.sellerStoreEmployeeId,
        });

        const { emitUpdates } = useEditableFields({ context })
        function emitUpdatedValues(updates: Partial<typeof updatedFields.value> | StoreEmployee) {
            if ((updates as StoreEmployee)?.buyerStoreEmployeeId) {
                updatedFields.value.sellerStoreEmployeeId = (updates as StoreEmployee).buyerStoreEmployeeId;
            }
            emitUpdates(updatedFields.value);
        }

        const allStoreEmployees: Ref<StoreEmployee[]> = ref([]);
        const loadingStoreEmployees = ref(false);
        const selectedStoreEmployee: Ref<StoreEmployee | undefined> = ref(undefined);

        onBeforeMount(async()=> {
            loadingStoreEmployees.value = true;
            await getAllStoreEmployees({ role: 'seller' }, {
                onSuccess: (employees) => {
                    allStoreEmployees.value = employees.map((employee: StoreEmployee) => {
                        return {
                            ...employee, 
                            displayValue: `${employee.firstName} ${employee.lastName} - ${employee.storeName}`, 
                        }
                    });
                    if (props.sellerStoreEmployeeId) {
                        selectedStoreEmployee.value = allStoreEmployees.value.find(employee => employee.buyerStoreEmployeeId == props.sellerStoreEmployeeId);
                    }
                    loadingStoreEmployees.value = false;
                },
                onError: () => loadingStoreEmployees.value = false,
            });
        });

        return {
            isEditingSeller,
            updatedFields,
            emitUpdatedValues,
            allStoreEmployees,
            loadingStoreEmployees,
            selectedStoreEmployee,
        }
    },
});
