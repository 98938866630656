var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"announcement-summary-icons flex-row align-center"},[(_vm.$props.mostSevereAnnouncementType == 'negative')?_c('b-icon',_vm._b({staticClass:"has-text-primary"},'b-icon',{
            ..._vm.defaultIconAttrs,
            ..._vm.$attrs.iconAttrs,
        },false)):_vm._e(),(_vm.$props.mostSevereAnnouncementType)?_c('b-icon',_vm._b({class:_vm.getIconColorByAnnouncementType(_vm.$props.mostSevereAnnouncementType, _vm.$props.customIconColors)},'b-icon',{
            ..._vm.defaultIconAttrs,
            ..._vm.$attrs.iconAttrs,
        },false)):_vm._e(),(_vm.$props.titleStatus && _vm.isTitleLost(_vm.$props.titleStatus))?_c('b-icon',_vm._b({staticClass:"title-status-announcement-icon",attrs:{"icon":"alert-circle"}},'b-icon',{
            ..._vm.defaultIconAttrs,
            ..._vm.$attrs.iconAttrs,
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }