<template>
    <Modal
        v-bind="{
            confirmButtonAttrs: {
                label: 'Print',
            },
            isLoading: loading,
        }"
        @confirm="print"
        @close="$emit('close')"
    >
        <template #header>
            Order Summary
        </template>

        <template #body>
            <div class="container" id="modalInvoice">
                <p v-if="orderSummaryMessage" class="order-summary-message text-center has-text-danger">{{ orderSummaryMessage }}</p>

                <section class="body">
                    <InfoHeader 
                        :title="vehicleInfo.yearMakeModel"
                        :subtitle="vehicleInfo.vin"
                        :imageUrl="vehicleInfo.image"
                        :date="vehicleInfo.dateOfSale "
                        imgSize='sm-medium'
                        class="info-header"
                    />
        
                    <section class="lists">
                        <div class="itemized-list">
                            <div class="header">Order Summary</div>
                            <ItemizedList
                                :listObj="formatOrderSummaryPrices"
                                :delineated="true"
                                :capitalizeItems="false"
                                :isLarge="true"
                            />
                        </div>
        
                        <div class="itemized-list">
                            <div class="header">Vehicle</div>
                            <ItemizedList
                                :listObj="vehicleInfo.details"
                                :delineated="true"
                                :capitalizeItems="false"
                                :isLarge="true"
                            />
                        </div>
        
                        <div class="itemized-list">
                            <div class="header">Buyer</div>
                            <ItemizedList
                                :listObj="buyer"
                                :delineated="true"
                                :capitalizeItems="false"
                                :isLarge="true"
                            />
                        </div>
                    </section>  
                </section>
                <img class="logo" src="../assets/logo.png" alt="Company Logo" />
            </div>
        </template>
    </Modal>
</template>

<script>
import { 
    formatDollarAmount,
    openErrorDialog
} from "../utils";
import { Printd } from 'printd'
import { orderSummaryDisplayObjectUsingOrder } from "../dashboardUtils";
import { GET } from '../api';

import InfoHeader from "../components/Arbitrations/InfoHeader.vue"
import ItemizedList from "../components/ItemizedList.vue"
import Loading from '../components/Loading.vue';
import Modal from '@/components/Modal.vue';

export default {
    name: "OrderSummaryPdf",
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        orderSummaryMessage: {
            type: String,
            required: false,
        }
    },
    components: {
        InfoHeader,
        ItemizedList,
        Loading,
        Modal,
    },
    data() {
        return {
            loading: false,
            vehicleInfo: {},
            orderSummary: {},
            buyer: {},
            cssText:`
            @page { 
                .title{
                    float:right
                }
             }
            .order-summary-message {
                display: none;
            }
            .date {
                text-align: right;
            }
            .medium{
                font-size: 1.5rem;
            }
            .logo {
                height: 74px;
                width: 200px;
                margin: auto;
                margin-top: auto;
                margin-bottom: 1rem;
                display: block;
            }
            #info-header {
                /* margin: 1rem; */
            }
            .image-thumbnail {
                margin-left: 1rem;
            }
            .info {
                margin-left: 1rem;
            }
            .info-title{
                color: #4a4a4a;
            }
            h1, h2, h3, h4, h5, h6 {
                font-size: 90%;
            }
            p {
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
            }
            .medium .info-subtitle{
                font-weight: 300;
            }
            .info-subtitle{
                font-size: .65em;
            }
            html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
                margin: 0;
                padding: 0;
            }
            .label:not(:last-child) {
                margin-bottom: 0;
            }
            .capitalize {
                font-size: .85em;
            }
            .flex-column {
                display: flex;
                flex-direction: column !important;
            }
            .flex-row {
                display: flex;
                flex-direction: row !important;
            }
            .align-center{
                align-items: center !important;
            }
            .body{
                height: 100%;
                padding: 1rem 6rem;
                margin-top: 2rem;
                font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
                font-size: 1em;
                line-height: 1.5;
                -webkit-print-color-adjust: exact;
            }
            .itemized-list:first-child {
                margin-top: 2rem;
            }
            .body .itemized-list{
                margin: 3rem 0;
            }
            .header{
                text-align: center;
                color: #363636;
                border-bottom: 1px solid currentColor;
                font-weight: 300;
                font-size: 1.25rem;
            }
            .image-thumbnail{
                margin-left: 1rem;
            }
            .image-thumbnail.sm-medium {
                width: 4.25rem;
                height: 4.25rem;
            }
            .image-thumbnail {
                border-radius: 50%;
            }
            img {
                max-width: 100%;
            }
            .list-row.is-large{
                padding: .5em 1.5em;
            }
            .list-row.is-total[data-v-0a4ebbde] {
                text-transform: uppercase;
                font-weight: bold !important;
            }
            .list-row.is-total .label[data-v-0a4ebbde] {
                font-weight: bold;
                font-size: 1em;
            }
            .justify-space-between {
                justify-content: space-between !important;
            }
            .point9-rem {
                font-size: 0.9rem;
            }
            .background {
                background: rgba(82, 170, 87, 0.1) !important
            }
            .vin-dropdown {
                display: none;
            }
            `
        }
    },
    async mounted() {
        this.loading = true;
        this.vehicle = await GET(`/vehicles/getOrderSummaryPDFInfo/${this.vehicleListingId}`)
            .then(res => res.data)
            .catch(error => {
                openErrorDialog({
                    title: 'Failed to fetch order summary details',
                    message: `We encountered an error while fetching the order summary for vehicle ${this.vehicleListingId}`,
                    error,
                });
            });
        this.getVehicleInfo()
        this.getOrderSummaryInfo()
        this.getBuyerInfo()
        this.loading = false;
    },
    computed: {
        getOrderSummaryTotal() {
            const orderSummaryValues = Object.keys(this.orderSummary).map(key => this.orderSummary[key])
            return orderSummaryValues.reduce((a,b) => a+b)
        },
        formatOrderSummaryPrices() {
            Object.keys(this.orderSummary).forEach(key => {
                this.orderSummary[key] = formatDollarAmount(this.orderSummary[key], true)
            })
            return this.orderSummary
        },
        formatvehicleInfodetails(){

        }
    },
    methods: {
        print() {
            const d = new Printd();
            d.print(document.getElementById('modalInvoice'), [this.cssText]);
        },
        getVehicleInfo() {
            this.vehicleInfo = {
                yearMakeModel: `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`,
                vin: this.vehicle.vin,
                image: this.vehicle.heroImgUrl,
                dateOfSale: new Date(this.vehicle.orderSummary.purchaseDate),
                details: {
                    "Trim": this.vehicle.trim,
                    "Color": this.vehicle.color,
                    "Miles": (this.vehicle.mileage).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                }
            }
            console.log('VEHICLE INFO', this.vehicleInfo)
        },
        getOrderSummaryInfo() {
            const orderSummary = this.vehicle.orderSummary
            const transportationObj = {}
            const transportationOption = this.vehicle.orderSummary.ancillaryOptions[0]
            if (transportationOption.selected) {
                transportationObj['Transportation'] = transportationOption.price;
            }
            this.orderSummary = orderSummaryDisplayObjectUsingOrder(orderSummary);
        },

        getBuyerInfo() {
            const store = this.vehicle.orderSummary.store
            this.buyer = {
                "Buyer": `${this.vehicle.buyer.firstName} ${this.vehicle.buyer.lastName}`,
                "Store": store.name,
                "Address": store.address,
                "": `${store.city}, ${store.state} ${store.zip}`
            }
        },

        getPhotosPrice() {
            const photos = this.vehicle.orderSummary.ancillaryOptions[1]
            const photosPurchased = photos.selected
            if (photosPurchased) this.orderSummary["Photos"] = photos.price
        },

        getAssurancePrice() {
            const assurancePlans = this.vehicle.orderSummary.ancillaryOptions.slice(2, 5)
            const selectedAssurance = assurancePlans.filter(plan => plan.selected)[0]
            
            if (selectedAssurance) {
                var name;
                switch(selectedAssurance.name) {
                    case "standardArbitration":
                        name = "Standard "
                        break;
                    case "extendedArbitration":
                        name="Extended "
                        break;
                    case "premiumArbitration":
                        name="Premium "
                        break;
                }
                this.orderSummary[`${name}Buyer Assurance`] = selectedAssurance.price
            }
        },
    },

    
}
</script>

<style scoped lang="postcss">
.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: justify-space-between;
  color: #363636;
}

.date {
    text-align: right;
}

.body {
    height: 100%;
    padding: 1rem 6rem;

    & .header {
        text-align: center;
        color: #363636;
        border-bottom: 1px solid currentColor;
        font-weight: 300;
        font-size: 1.25rem;
    }

    & .itemized-list {
        margin: 3rem 0;

        &:first-child {
            margin-top: 2rem;
        }


        & .list-row {
            padding: 1rem !important;
        }
    }
}

.logo {
    height: 74px;
    width: 200px;
    margin: auto;
    margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
    :deep(.modal-card-body-custom) {
        padding: 0 !important;
    }

    #modalInvoice {
        zoom: 80%;
    }

    .body {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 600px) {
    #modalInvoice {
        zoom: 35%;
    }
}

@media screen and (max-width: 300px) {
    #modalInvoice {
        zoom: 30%;
    }
}

</style>
