var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:`activity-feed-note-${_vm.noteKey}`,staticClass:"activity-feed-input"},[_c('InputField',_vm._b({staticClass:"activity-feed-input-field carmigo-input",on:{"iconRightClick":_vm.submitNote,"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')},"keypressEnter":function($event){return _vm.submitNote()}},model:{value:(_vm.noteText),callback:function ($$v) {_vm.noteText=$$v},expression:"noteText"}},'InputField',{
            type: 'textarea',
            placeholder: 'Send a note',
            customClass: `${_vm.isMobile ? 'is-mobile' : ''}`,
            fieldAttrs: {
                message: _vm.errorMessage,
            },
            id: 'activity-feed-input',
            ..._vm.inputProps,
        },false)),(!_vm.isMobile && !_vm.$props.useCustomSubmit)?_c('div',{staticClass:"submit-button-container"},[_c('AppButton',_vm._b({staticClass:"submit-button",on:{"click":_vm.submitNote}},'AppButton',{
                type: 'is-primary',
                label: 'Submit',
                outlined: true,
                loading: _vm.loadingSubmitNote,
                disabled: !_vm.noteText,
                ..._vm.$attrs.buttonAttrs,
            },false))],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }