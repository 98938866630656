import { APIConfig, ImageDTO } from "@/types";
import { AttachmentDTO } from "@/types/attachment";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatDate, openAlertDialog, openConfirmationDialog, openToast, uploadAttachmentsToStorage, UploadedFile, uploadFile } from "@/utils";
import { BNoticeComponent } from "buefy/types/components";
import { pick } from "lodash";
import { computed, onBeforeUnmount, onMounted, Ref, ref, SetupContext, watch } from "vue";

export function useUploadImage({ showToasts, context }: {
    showToasts: boolean,
    context: SetupContext<(any)[]>,
}) {
    const uploadedFiles = ref<ImageDTO[]>([]);
    const numSuccessfulUploads = ref(0);
    const attachmentUploadQueue = ref<(File & { file: AttachmentDTO })[]>([]);
    const attachmentUploadQueueIdx = ref(-1);
    const newAttachments = ref<AttachmentDTO[]>([]);
    const failedUploadsIdx = ref<number[]>([]);
    const openedToast: Ref<BNoticeComponent | null> = ref(null);
    const loadingUploadImages: Ref<boolean> = ref(false);

    function addToUploadQueue(file: File & { file: AttachmentDTO }) {
        attachmentUploadQueue.value.push(file);
    }

    function executeUploadQueue(filePath?: string, config: APIConfig={}) {
        if (!filePath) {
            return;
        }
        if (attachmentUploadQueueIdx.value == -1) {
            attachmentUploadQueueIdx.value = 0;
            uploadFilesAndRetryOnFailure({ filePath, config });
        }
    }

    async function uploadFilesAndRetryOnFailure({ filePath, config, uploadAttemptNum }: { filePath: string, config?: APIConfig, uploadAttemptNum?: number }) {
        loadingUploadImages.value = true;

        if (attachmentUploadQueueIdx.value >= attachmentUploadQueue.value.length) {
            context.emit('upload', uploadedFiles.value);
            applyAPIConfigOnSuccess(uploadedFiles.value, config);
            showUploadSummaryToastOrAlert();
            loadingUploadImages.value = false;
            attachmentUploadQueueIdx.value = -1;
            attachmentUploadQueue.value = [];
            newAttachments.value = [];
            uploadedFiles.value = [];
            return;
        }
        showUploadingToast();
        const fileToUpload = attachmentUploadQueue.value[attachmentUploadQueueIdx.value]?.file;
        await uploadAttachmentsToStorage(filePath, [fileToUpload])
            .then((res: UploadedFile[]) => {
                let uploadedImageDTO: ImageDTO = pick(res[0], ['filename', 'url', 'largeUrl', 'mediumUrl', 'smallUrl', 'progress'])
                uploadedFiles.value = uploadedFiles.value.concat(uploadedImageDTO);
                context.emit('uploaded', fileToUpload.name);
                numSuccessfulUploads.value++;
                attachmentUploadQueueIdx.value++;
                uploadFilesAndRetryOnFailure({ filePath, config });
            }).catch(error => {
                console.log('ERROR', error);
                // re-attempts twice (total of 3 attempts)
                // if fails on third attempt, moves on to the next attachment
                if ((uploadAttemptNum ?? 1) >= 3) {
                    loadingUploadImages.value = false;
                    applyAPIConfigOnError(error, config);
                    attachmentUploadQueueIdx.value++;
                    uploadFilesAndRetryOnFailure({ filePath, config });
                } else {
                    failedUploadsIdx.value.push(attachmentUploadQueueIdx.value + 1);
                    uploadFilesAndRetryOnFailure({ 
                        filePath, 
                        uploadAttemptNum: uploadAttemptNum ? uploadAttemptNum + 1 : 2,
                        config,
                    });
                }
            });
    }

    function showUploadingToast() {
        if (!showToasts || !attachmentUploadQueue.value.length) {
            return;
        }
        let failedText = ` | ${failedUploadsIdx.value.length} failed`;
        openedToast.value = openToast(
            'is-info',
            `Uploading ${numSuccessfulUploads.value + 1} of ${attachmentUploadQueue.value.length} attachments${failedUploadsIdx.value.length > 0 ? failedText : '...'}`,
            'indefinite'
        )
    }

    function showUploadSummaryToastOrAlert() {
        if (!showToasts || !attachmentUploadQueue.value.length) {
            return;
        }
        closeOpenToast();
        if (failedUploadsIdx.value.length > 0) {
            showFailedUploadsAlert();
        } else {
            openedToast.value = openToast('is-success', `All ${numSuccessfulUploads.value} attachments uploaded successfully`);
        }
        resetUploadCount();
    }

    function showFailedUploadsAlert() {
        if (!showToasts || !attachmentUploadQueue.value.length) {
            return;
        }
        let commaSeparatedIndexes = failedUploadsIdx.value.join(', ').replace(/, ([^,]*)$/, ' and $1');
        openAlertDialog({
            title: 'Some attachments failed to upload',
            message: `
                <p>Uploaded ${numSuccessfulUploads.value}/${attachmentUploadQueue.value.length} attachments successfully.</p>
                <p>Failed to upload attachments ${commaSeparatedIndexes} (${failedUploadsIdx.value.length} total).</p>
            `,
        });
    }

    function resetUploadCount() {
        numSuccessfulUploads.value = 0;
        failedUploadsIdx.value = [];
    }

    function closeOpenToast() {
        if (openedToast.value) {
            openedToast.value.close();
            openedToast.value = null;
        }
    }

    return {
        addToUploadQueue,
        executeUploadQueue,
        uploadedFiles,
        loadingUploadImages,
    }
}


export function useRecordAudio() {
    const isRecording = ref(false);
    const recordingUrl: Ref<string | undefined> = ref(undefined);
    const rawRecordingUrl: Ref<string | undefined> = ref(undefined);
    const recordingTimeElapsed = ref(0);
    let recordingInterval: ReturnType<typeof setInterval> | null = null;

    function downloadAudio(saveAsName: string, recordingUrl?: string, ) {
        if (recordingUrl) {
            const link = document.createElement('a');
            link.href = recordingUrl;
            link.download = `${saveAsName}.webm`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    function updateIsRecording(recording: boolean) {
        isRecording.value = recording;

        if (recording) {
            recordingTimeElapsed.value = 0;
            recordingInterval = setInterval(() => {
                recordingTimeElapsed.value += 1;
            }, 1000);
        } else if (recordingInterval) {
            clearInterval(recordingInterval);
            recordingInterval = null;
        }
    }

    function updateRecordingUrl(url?: string) {
        recordingUrl.value = url;
    }

    function openConfirmDeleteRecordingDialog({ onConfirm }: { onConfirm?: () => void } ={}) {
        openConfirmationDialog({
            title: 'Delete recording?',
            message: `Are you sure you want to delete this recording? This action cannot be undone.`, 
            onConfirm,
        });
    }

    const formattedRecordingTime = computed(() => {
        const hours = Math.floor(recordingTimeElapsed.value / 3600);
        const minutes = Math.floor((recordingTimeElapsed.value % 3600) / 60);
        const seconds = recordingTimeElapsed.value % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    });

    return {
        isRecording,
        recordingUrl,
        rawRecordingUrl,
        downloadAudio,
        updateRecordingUrl,
        updateIsRecording,
        openConfirmDeleteRecordingDialog,
        formattedRecordingTime,
    }
}

export function usePlayAudio() {
    const audio = ref<HTMLAudioElement | null>(null);
    const isPlaying = ref(false);
    const duration = ref(0);
    const currentTime = ref(0);

    watch(() => audio.value?.duration, () => {
        if (audio.value?.duration) {
            duration.value = audio.value?.duration;
        }
    });

    watch(() => isPlaying.value, (newState) => {
        if (audio.value) {
            newState ? audio.value.play() : audio.value.pause();
        }
    });

    const togglePlayPause = () => {
        if (audio.value) {
            isPlaying.value = Boolean(audio.value.paused);
        }
    };

    const updateProgress = () => {
        if (audio.value) {
            currentTime.value = audio.value.currentTime;
            duration.value = audio.value.duration;
        }
    };

    const scrub = (event: Event) => {
        const target = event.target as HTMLInputElement;
        if (audio.value) {
            audio.value.currentTime = parseFloat(target.value);
        }
    };

    const onEnded = () => {
        isPlaying.value = false;
    };

    const formatTime = (seconds: number) => {
        if (isNaN(seconds)) {
            return `00:00`;
        }
        const min = Math.floor(seconds / 60);
        const sec = Math.floor(seconds % 60).toString().padStart(2, "0");
        return `${min}:${sec}`;
    };

    onMounted(() => {
        if (audio.value) {
            audio.value.addEventListener("loadedmetadata", () => {
                duration.value = audio.value!.duration;
            });
        }
    });

    return {
        audio,
        isPlaying,
        currentTime,
        duration,
        togglePlayPause,
        updateProgress,
        scrub,
        onEnded,
        formatTime,
    }

}

export function useSaveAudio({ storageFilePath, filename, fileType='mp3', context }: {
    storageFilePath: string,
    filename: string,
    fileType?: string,
    context: SetupContext<('uploadAudio' | any)[]>,
}) {
    const loadingUploadAudio = ref(false);
    async function uploadAudioToStorage(audioBlob: Blob, config: APIConfig={}) {
        let formattedDate = formatDate(new Date(), 'MM_dd_yyyy-hhmma');
        let uploadFilename = `${filename}_${formattedDate}.${fileType}`;
        loadingUploadAudio.value = true;
        try {
            const uploadedFile = await uploadFile(storageFilePath, uploadFilename, audioBlob, fileType, {
                customOnSuccess: true,
                onSuccess: (url) => {
                    applyAPIConfigOnSuccess(url, config);
                    loadingUploadAudio.value = false;
                    context.emit('uploadAudio', url);
                },
                onError: () => loadingUploadAudio.value = false,
            });
        } catch(error) {
            loadingUploadAudio.value = false;
        }
    }

    return {
        uploadAudioToStorage,
        loadingUploadAudio,
    }

}

type OrientationType = 'portrait' | 'landscape-right' | 'landscape-left' | 'portrait-upside-down' | null;

export function useDeviceOrientation() {
    const orientation = ref<OrientationType>(null);
    const permissionState = ref<PermissionState | null>(null);

    // Check if the device has orientation API
    const hasOrientationSupport = typeof DeviceOrientationEvent !== 'undefined';
    
    // Request permission for device orientation (required for iOS 13+)
    const requestPermission = async (): Promise<boolean> => {
        if (!hasOrientationSupport) {
            return false;
        }
        
        // For iOS 13+ that requires permission
        let dataOrientationEvent = DeviceOrientationEvent as typeof DeviceOrientationEvent& { requestPermission?: () => any };
        if (dataOrientationEvent.requestPermission && typeof dataOrientationEvent.requestPermission === 'function') {
            try {
                const permission = await dataOrientationEvent.requestPermission();
                permissionState.value = permission;
                return permission === 'granted';
            } catch (err) {
                console.error('Error requesting device orientation permission:', err);
                return false;
            }
        }
        
        // For devices that don't require permission
        permissionState.value = 'granted';
        return true;
    };

    // Update orientation based on device orientation event
    const handleOrientationChange = () => {
        // Get screen orientation if available (more reliable)
        if (window.screen && window.screen.orientation) {
            const angle = window.screen.orientation.angle;
            
            if (angle === 0) {
                orientation.value = 'portrait';
            } else if (angle === 90) {
                orientation.value = 'landscape-right';
            } else if (angle == 180) {
                orientation.value = 'portrait-upside-down';
            } else if (angle == 270) {
                orientation.value = 'landscape-left';
            }
            return;
        }
        
        // Fallback to window.orientation for older devices
        const windowOrientation = window.orientation as number | undefined;
        
        if (windowOrientation === 0) {
            orientation.value = 'portrait';
        } else if (windowOrientation === 90) {
            orientation.value = 'landscape-right';
        } else if (windowOrientation === 180) {
            orientation.value = 'portrait-upside-down';
        } else if (windowOrientation === -90) {
            orientation.value = 'landscape-left';
        }
    };

    onMounted(() => {
        // Set initial orientation
        handleOrientationChange();
        
        // Add event listeners
        window.addEventListener('orientationchange', handleOrientationChange);
        
        if (window.screen && window.screen.orientation) {
            window.screen.orientation.addEventListener('change', handleOrientationChange);
        }
    });

    onBeforeUnmount(() => {
        // Remove event listeners
        window.removeEventListener('orientationchange', handleOrientationChange);
        
        if (window.screen && window.screen.orientation) {
            window.screen.orientation.removeEventListener('change', handleOrientationChange);
        }
    });

    return {
        orientation,
        permissionState,
        hasOrientationSupport,
        requestPermission
    };
}