var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingActivityCard',_vm._b({class:{
        'is-action-required': _vm.needsCheckout,
        'multiple-stores': _vm.viewingMultipleStores,
    },scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"offer-details"},[_c('div',{staticClass:"flex-row align-center"},[(_vm.viewingMultipleStores)?_c('b-icon',{staticClass:"mr-1",attrs:{"icon":_vm.isMyPurchase ? 'account' : 'storefront',"type":_vm.needsCheckout ? 'is-danger' : 'is-primary',"custom-size":"mdi-18px"}}):_vm._e(),(_vm.$attrs.loading || _vm.loadingOrder)?_c('div',[_c('span',[_vm._v("Loading...")])]):(_vm.needsCheckout)?_c('div',{staticClass:"has-text-danger bold"},[_c('span',[_vm._v("Checkout required")])]):_c('div',[_c('span',[_c('TheTitleAndDeliveryStatusIcons',_vm._b({},'TheTitleAndDeliveryStatusIcons',{
                                vehicleListingId: _vm.$props.vehicleListingId,
                                facilitatingAuctionStoreName: _vm.$props.facilitatingAuctionStoreName,
                                customSize: 'mdi-18px',
                                showLabels: true,
                            },false))],1)])],1),(_vm.viewingMultipleStores && _vm.order)?_c('div',{staticClass:"store-name"},[_vm._v(" "+_vm._s(_vm.order?.store?.name)+" ")]):_vm._e()])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"purchased-details-container columns"},[_c('div',{staticClass:"info-highlight column is-4"},[_c('p',{staticClass:"info-highlight__label"},[_vm._v("store")]),(_vm.order?.buyerFirstName)?_c('p',{staticClass:"is-size-7"},[_vm._v(_vm._s(`${_vm.order.buyerFirstName} ${_vm.order.buyerLastName}`))]):_vm._e()]),_c('div',{staticClass:"info-highlight column is-4"},[_c('p',{staticClass:"info-highlight__label"},[_vm._v("vehicle price")]),_c('p',{staticClass:"info-highlight__value"},[_vm._v(_vm._s(_vm.toCurrencyShowDashesIfNull(_vm.order?.vehiclePrice)))])]),(_vm.order?.purchaseDate)?_c('div',{staticClass:"info-highlight column is-4"},[_c('p',{staticClass:"info-highlight__label mr-1"},[_vm._v("purchased")]),_c('p',{staticClass:"info-highlight__value"},[_vm._v(_vm._s(_vm.formatDate(_vm.order?.purchaseDate)))])]):_vm._e()])]},proxy:true},{key:"buttons",fn:function(){return [_c('div',{staticClass:"buttons"},[(_vm.needsCheckout)?_c('TheCheckoutButton',_vm._b({},'TheCheckoutButton',{
                    vehicleListingId: _vm.$props.vehicleListingId,
                    vehiclePrice: _vm.order?.vehiclePrice,
                    vehicleStatus: _vm.status,
                    outlined: false,
                    disabled: !_vm.isMyPurchase,
                    size: 'is-small',
                },false)):_c('AppButton',_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.openOrderSummaryModal.apply(null, arguments)}}},'AppButton',{
                    label: 'View Bill of Sale',
                    outlined: false,
                    expanded: true,
                    type: 'is-grey',
                    disabled: _vm.$attrs.loading,
                    size: 'is-small',
                },false)),(!_vm.needsCheckout && _vm.arbitrationDetails && _vm.isMyPurchase)?_c('TheOpenArbitrationClaimButton',_vm._b({},'TheOpenArbitrationClaimButton',{
                    outlined: false,
                    size: 'is-small',
                    vehicleListingId: _vm.$props.vehicleListingId,
                    arbitrationExpirationDate: _vm.order?.arbitrationExpirationDate ? new Date(_vm.order.arbitrationExpirationDate) : undefined,
                    ..._vm.arbitrationDetails,
                    hasOpenedClaim: Boolean(_vm.arbitrationDetails?.openedArbitrationClaimId),
                },false)):_vm._e()],1)]},proxy:true}])},'TheListingActivityCard',{
        viewCount: _vm.highestBid?.views,
        bidCount: _vm.highestBid?.bids,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }