
import { PropType, defineComponent, watch } from 'vue';
import { useCreateListingNote } from '@/composables';
import { NoteType } from '@/types';

import AppButton from './AppButton.vue';
import InputField from './InputField.vue';

export default defineComponent({
    name: 'TheActivityFeedInput',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        noteType: {
            type: String as PropType<NoteType>,
            default: 'admin',
        },
        inputProps: {
            type: Object as PropType<Record<string, unknown>>,
            required: false,
            default: () => ({}),
        },
        useCustomSubmit: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppButton,
        InputField,
    },
    emits: ['addNote', 'update:noteText', 'blur', 'focus'],
    setup(props, context) {
        const {
            noteText, 
            noteKey,
            submitNote,
            loadingSubmitNote,
            errorMessage,
        } = useCreateListingNote({
            vehicleListingId: props.vehicleListingId,
            noteType: props.noteType,
            context,
        });

        watch(noteText, () => context.emit('update:noteText', noteText.value));

        return {
            noteText,
            submitNote,
            loadingSubmitNote,
            errorMessage,
            noteKey,
        }
    },
});
