import { getArbitrationRateBySellerStoreId, getSellRateBySellerStoreId, getTimeToTitleBySellerStoreId } from "@/api";
import { DashboardListingDTO, HighestBidRefRecord, ListingRowTableType, orderSummary, SellerStat, SRPListing, TimeDTO, VehicleStatus } from "@/types";
import { formatSellerStat, isSecondChanceStatus, isMarketplaceStatus, isListingLive, UpdateUrlParams, isVehicleSold } from "@/utils";
import { computed, onBeforeMount, Ref, ref, SetupContext, watch } from "vue";
import { useRoute } from 'vue-router/composables';
import { useCancelToken, useFetch } from './fetch';
import { useStore } from "./useStore";

import TheListingActivityCardPurchased from "@/components/TheListingActivityCardPurchased.vue";
import TheListingActivityCardNegotiating from "@/components/TheListingActivityCardNegotiating.vue";
import TheListingActivityCardAuction from "@/components/TheListingActivityCardAuction.vue";
import TheListingActivityCardMarketplace from "@/components/TheListingActivityCardMarketplace.vue";

export interface UpdateListingsCallbackParams {
    listings: any[],
    fromFilterUpdate?: boolean
}

export function useGetListingTypeFromUrlParams(validListingTypes: Ref<string[]>) {
    let route = useRoute();
    
    let urlParams = route.query;
    
    // GET LISTING TYPE
    const urlListingType: Ref<ListingType> = ref('auction'); 
    if (validListingTypes.value.includes(urlParams.listingType as string)) {
        urlListingType.value = urlParams.listingType as ListingType;
    }
    watch(() => validListingTypes.value, () => {
        if (validListingTypes.value.includes(urlParams.listingType as string)) {
            urlListingType.value = urlParams.listingType as ListingType;
        }
    });

    return {
        urlListingType,
    }
}

export type ListingType = 'auction' | 'marketplace' | 'secondChance';

export function useListingType({ context, updateUrlParams, initialListingType, validListingTypes}: {
    context: SetupContext<('update:listingType' | 'selectedListingType' | 'updateVehicleStatus' | any)[]>,
    updateUrlParams?: ({}: UpdateUrlParams) => void, // leave undefined if you don't want to update URL params
    initialListingType?: ListingType | ListingRowTableType,
    validListingTypes: Ref<string[]>,
}) {
    const selectedListingType: Ref<ListingType | ListingRowTableType> = ref(initialListingType ?? 'auction');
    const route = useRoute();
    const listingTypeKey = ref(0);

    function updateSelectedListingType(listingType: ListingType) {
        selectedListingType.value = listingType;
    }

    watch(() => selectedListingType.value, () => {
        context.emit('update:listingType', selectedListingType.value);
        context.emit('updateVehicleStatus', getVehicleStatusFromListingType.value);
        if (updateUrlParams) {
            updateUrlParams({
                route, 
                newQueryParams: { 'listingType': selectedListingType.value }, 
                maintainAllParams: true,
            });
        }
    });

    const { urlListingType } = useGetListingTypeFromUrlParams(validListingTypes);
    onBeforeMount(() => {
        selectedListingType.value = urlListingType.value ?? 'auction';
    });
    watch(() => urlListingType.value, () => selectedListingType.value = urlListingType.value ?? 'auction');

    const getVehicleStatusFromListingType = computed(() => {
        let status;
        switch (selectedListingType.value) {
            case 'auction':
                status = 'Auctioning';
                break;
            case 'marketplace':
                status = 'InMarketplace';
                break;
            case 'secondChance':
                status = 'SecondChance';
                break;
        }
        return status;
    });

    return {
        selectedListingType,
        updateSelectedListingType,
        listingTypeKey,
        getVehicleStatusFromListingType,
    }
}

export function useRemoveListingRow(listings: Ref<SRPListing[]>) {
    function removeListingRow(vehicleListingId: number) {
        window.setTimeout(() => {
            listings.value = listings.value.filter(listing => listing.id !== vehicleListingId);
        }, 500);
    }

    const removeListingRowQueue: Ref<number[]> = ref([]);
    function queueRemoveListingRow(vehicleListingId: number) {
        removeListingRowQueue.value.push(vehicleListingId);
    }

    function executeRemoveListingRowQueue() {
        removeListingRowQueue.value.forEach(vehicleListingId => {
            removeListingRow(vehicleListingId);
        });
    }

    return {
        removeListingRow,
        removeListingRowQueue,
        queueRemoveListingRow,
        executeRemoveListingRowQueue,
    }
}

export function useTransporationCost({ listingZipCode, vehicleListingId, context }: { 
    listingZipCode?: string, 
    vehicleListingId: number,
    context?: SetupContext<( 'transportationCost' | any )[]>,
}) {
    const store = useStore();
    const zip1 = store.getters.getTransportationZip;
    if (!listingZipCode || !zip1) {
        return {
            transportationCost: ref(undefined),
            isLoadingTransportationCost: ref(false),
        }
    }

    const { cancelToken, createNewCancelToken } = useCancelToken();
    createNewCancelToken();

    const {
        data,
        loading,
    } = useFetch<number>(`/distance/calculateTransportationCostUsingZipCodes`, {
            method: 'PUT',
            data: {
                zip1, 
                zip2: listingZipCode,
                vehicleListingId,
            },
            cancelToken: cancelToken.value?.token,
            skipErrorOnCancel: true,
            onSuccess: () => {
                    if (context) {
                        context.emit('transportationCost', data.value);
                    }
                }
            });

    return {
        transportationCost: data,
        isLoadingTransportationCost: loading,
    };
}

export function useFetchSellingStat({ sellerStoreId, statName }: {
    sellerStoreId: number,
    statName: SellerStat,
}) {
    const sellingStat: Ref<number | string | undefined> = ref(undefined);
    const loadingStat: Ref<boolean> = ref(false);

    const fetchStatFunction: { [key: string]: (sellerStoreId: number) => Promise<number | TimeDTO | undefined>} = {
        'Arbitration Rate': getArbitrationRateBySellerStoreId,
        'Sell Rate': getSellRateBySellerStoreId,
        'Time to Title': getTimeToTitleBySellerStoreId,
    }

    onBeforeMount(async () => {
        loadingStat.value = true;
        let fetchFunction = fetchStatFunction[statName];
        if (!fetchFunction) {
            loadingStat.value = false;
            return;
        }
        await fetchFunction(sellerStoreId)
            .then(res => {
                sellingStat.value = formatSellerStat(statName, res);
                loadingStat.value = false;
            }).catch(error => {
                loadingStat.value = false;
                console.log('error', error);
            });
    });
    return {
        sellingStat,
        loadingStat,
    }
}

export function useListingActivityCard({ highestBid, order, personId, listing, listingType, showIfNotMyListing, storeIds }: {
    highestBid: Ref<HighestBidRefRecord | undefined>,
    order: Ref<orderSummary | undefined>,
    personId: Ref<number | undefined>,
    listing: SRPListing,
    listingType: ListingRowTableType,
    showIfNotMyListing?: boolean,
    storeIds?: number[], // viewing multiple stores' listings
}) {
    const vehicleHasBuyer = computed(() => {
        if (!highestBid.value?.status || isListingLive(highestBid.value.status)) {
            return false;
        }
        if (order.value?.buyerPersonId) {
            return true;
        }
        if (highestBid.value?.buyer?.id) {
            return true;
        }
        return false;
    });

    const isVehicleBuyer = computed(() => {
        if (!vehicleHasBuyer.value) {
            return false;
        }
        if (order.value?.buyerPersonId && order.value.buyerPersonId == personId.value) {
            return true;
        }
        if (highestBid.value?.buyer?.id && parseInt(highestBid.value.buyer.id) == personId.value) {
            return true;
        }
        return false;
    });

    const listingActivityCardComponent = computed(() => {
        let component = undefined;
        let componentProps = {};

        let vehicleStatus: VehicleStatus | undefined = highestBid.value?.status ?? listing.status;
    
        if (!vehicleStatus) {
            return;
        }
    
        let hasPurchasedListing = vehicleHasBuyer.value && isVehicleBuyer.value;
    
        // PURCHASED
        if (isVehicleSold(vehicleStatus) && (hasPurchasedListing || listingType == 'purchased' || showIfNotMyListing)) {
            component = TheListingActivityCardPurchased;
            componentProps = {
                vehicleStatus,
                facilitatingAuctionStoreName: listing.facilitatingAuction?.showInUi ? listing.facilitatingAuction?.name : undefined,
                storeIds,
            }
        // NEGOTIATING
        } else if (vehicleStatus === 'Negotiating' && (hasPurchasedListing || showIfNotMyListing)) {
            component = TheListingActivityCardNegotiating;
            componentProps = {
                vin: listing.vin,
                currentNegotiation: (listing as DashboardListingDTO).currentNegotiation,
                storeIds,
            }
        // AUCTION 
        } else if ([
            'Auctioning',
            'Negotiating',
            'Checkout',
            'Sold',
            'Completed',
            'NotSold',
            'Inactive',
        ].includes(vehicleStatus)) {
            component = TheListingActivityCardAuction;
            const userCurrentBid = {
                bidAmount: listing.requestorCurrentHighestBid?.amount,
                storeName: listing.requestorCurrentHighestBid?.storeName,
                proxyBidAmount: listing.requestorCurrentHighestProxyBid?.amount,
            }
            componentProps = {
                vehicle: listing,
                reservePrice: listing.auction?.reservePrice,
                buyItNowPrice: listing.auction?.buyItNowPrice,
                userCurrentBid: listing.requestorCurrentHighestBid ? userCurrentBid : undefined,
                storeIds,
            };
        // MARKETPLACE / SECOND CHANCE
        } else if (isMarketplaceStatus(vehicleStatus) || isSecondChanceStatus(vehicleStatus)) {
            component = TheListingActivityCardMarketplace;
            componentProps = {
                marketplaceListingId: listing.marketplaceListingId,
                isSecondChance: isSecondChanceStatus(vehicleStatus as VehicleStatus),
                sellerPersonId: listing.userProfile?.id,
                sellerStoreId: listing.sellerStore?.id,
                reservePrice: listing.auction?.reservePrice,
                buyItNowPrice: listing.auction?.buyItNowPrice,
                canBuyItNow: listing.auction?.canBuyItNow,
                buyerCurrentOffer: listing.currentOffer,
                storeIds,
            }
        }
    
        return {
            component,
            props: componentProps,
        }
    });

    return {
        vehicleHasBuyer,
        isVehicleBuyer,
        listingActivityCardComponent,
    }
}