var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loadingBuyerNotes)?_c('div',{staticClass:"listing-buyer-notes"},[_c('div',{staticClass:"buyer-notes-container",attrs:{"id":"buyer-notes-display"}},[_c('TheActivityFeedDisplay',_vm._b({},'TheActivityFeedDisplay',{
                activityFeed: _vm.buyerNotes,
                noteType: 'private',
            },false))],1),_c('div',{staticClass:"activity-feed-input-and-buttons-container mb-2"},[(_vm.$props.useAddNoteButton)?_c('AppButton',_vm._b({staticClass:"carmigo-button",on:{"click":function($event){_vm.openBuyerNotesModal({ props: _vm.pick(_vm.$props, ['vehicleListingId', 'vehicleName', 'vin', 'imageUrl'])})}}},'AppButton',{
                label: 'Add a note',
                iconLeft: 'plus',
                type: 'is-primary',
                expanded: true,
            },false)):_c('TheActivityFeedInput',_vm._b({on:{"update:noteText":function($event){return _vm.$emit('update:noteText', $event);},"updatedNotes":_vm.updateBuyerNotes}},'TheActivityFeedInput',{
                vehicleListingId: _vm.$props.vehicleListingId,
                inputProps: {
                    placeholder: 'Add a note...',
                    rows: 1,
                },
                noteType: 'private',
                buttonAttrs: {
                    label: undefined,
                    iconRight: 'send',
                    class: 'no-border',
                },
                useCustomSubmit: _vm.$props.useCustomSubmit,
            },false))],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }