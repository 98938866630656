var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"listing-activity-card position-relative flex-row align-center width-100"},[_c('div',{staticClass:"activity-card"},[(!_vm.$props.hideHeader)?_c('header',{staticClass:"px-2"},[_vm._t("header"),_c('div',{staticClass:"flex-row align-center"},[_vm._t("header-right")],2)],2):_vm._e(),(_vm.$slots.body)?_c('div',{staticClass:"card-body px-4 pt-3 pb-2"},[_vm._t("body")],2):_vm._e(),(!_vm.$props.hideFooter)?_c('footer',{staticClass:"card-footer"},[(_vm.showViewsAndCounts)?_c('TheListingDetailsViewAndCounts',_vm._b({staticClass:"flex-row align-center px-1",staticStyle:{"gap":"0.5rem"}},'TheListingDetailsViewAndCounts',{
                    viewCount: _vm.$props.viewCount,
                    bidCount: _vm.$props.bidCount,
                    offerCount: _vm.$props.offerCount,
                    iconAttrs: {
                        customSize: 'mdi-12px',
                    }
                },false)):_vm._e(),_vm._t("footer"),(_vm.$slots.footerRight)?_c('div',{staticClass:"flex-row align-center pb-1 px-2"},[_vm._t("footer-right")],2):_vm._e()],2):_vm._e()]),(_vm.$slots.buttons)?_c('div',{staticClass:"listing-buttons carmigo-button ml-5"},[_vm._t("buttons")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }