var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-navbar',{key:`nav-top-${_vm.buyerRebateKey}`,staticClass:"nav sticky",attrs:{"id":"nav-top"},scopedSlots:_vm._u([{key:"brand",fn:function(){return [_c('b-navbar-item',{attrs:{"to":_vm.redirectPathByRole,"id":"company-logo","tag":"router-link"}},[_c('img',{key:_vm.navtopKey,staticClass:"logo",attrs:{"src":_vm.logo,"alt":"Company Logo"}})])]},proxy:true},{key:"start",fn:function(){return [(_vm.isUserWholesaler || _vm.isUserAdmin || _vm.isUserDsr)?_c('NavbarItem',{attrs:{"toPath":"/turn","label":"Sell"}}):_vm._e(),(!_vm.isAuthenticated)?_c('NavbarItem',{attrs:{"label":"Sell","toPath":"/home"}}):_vm._e(),(_vm.isAuthenticated && (_vm.isUserAdmin || _vm.isUserDsr || _vm.isUserBuyer || _vm.isUserStoreManager || _vm.isUserCarmigoDirectDealer()))?_c('b-navbar-dropdown',_vm._b({key:_vm.buyerRebateKey,on:{"active-change":_vm.toggleBuyDropdown},nativeOn:{"click":function($event){return _vm.goToSrpOnBuyDropdownClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Buy "),_c('b-icon',{staticClass:"ml-1",attrs:{"icon":_vm.isBuyDropdownOpen ? 'chevron-down' : 'chevron-right',"custom-size":"mdi-18px"}})]},proxy:true}],null,false,2707717175)},'b-navbar-dropdown',{
        active: _vm.isBuyDropdownOpen,
        arrowless: true,
        collapsible: true,
      },false),[_c('NavbarItem',_vm._b({},'NavbarItem',{
          toPath: '/live-auctions?listingType=auction',
        },false),[_c('span',{staticClass:"bold is-poppins"},[_vm._v("Auction")]),(_vm.numAuctionListings)?_c('span',{staticClass:"is-size-7 ml-2"},[_vm._v("("+_vm._s(_vm.numAuctionListings)+")")]):_vm._e()]),_c('NavbarItem',_vm._b({},'NavbarItem',{
          toPath: '/live-auctions?listingType=secondChance',
        },false),[_c('span',{staticClass:"bold is-poppins"},[_vm._v("Second Chance")]),(_vm.numSecondChanceListings)?_c('span',{staticClass:"is-size-7 ml-2"},[_vm._v("("+_vm._s(_vm.numSecondChanceListings)+")")]):_vm._e()]),_c('NavbarItem',_vm._b({},'NavbarItem',{
          toPath: '/live-auctions?listingType=marketplace',
        },false),[_c('span',{staticClass:"bold is-poppins"},[_vm._v("Marketplace")]),(_vm.numMarketplaceListings)?_c('span',{staticClass:"is-size-7 ml-2"},[_vm._v("("+_vm._s(_vm.numMarketplaceListings)+")")]):_vm._e()])],1):_vm._e(),(_vm.isUserBuyer || _vm.isUserAdmin)?_c('NavbarItem',_vm._b({},'NavbarItem',{
        label: 'Dashboard',
        toPath: '/dashboard/buying/0',
        showAlertIcon: Boolean(_vm.buyerActionRequiredCount),
        alertIconAttrs: {
          alertType: 'actionRequired',
        }
      },false)):_vm._e(),(_vm.isUserInspector || _vm.isUserAdmin || _vm.isUserDsr)?_c('NavbarItem',{attrs:{"label":"Inspection","toPath":"/inspector"}}):_vm._e(),(_vm.isUserAdmin || _vm.isUserDsr)?_c('NavbarItem',{attrs:{"label":"Admin","toPath":"/admin"}}):_vm._e()]},proxy:true},(_vm.isAuthenticated && (_vm.bulmaBreakpoint.mobile || _vm.bulmaBreakpoint.tablet))?{key:"burger",fn:function({ toggleActive, isOpened }){return [_c('div',{staticClass:"flex-row align-center"},[_c('AppNavbarNotifications',{attrs:{"displayNotificationFeed":_vm.displayNotificationFeed},on:{"update:displayNotificationFeed":function($event){return _vm.$emit('update:displayNotificationFeed', $event)}}}),_c('a',{staticClass:"user-menu-mobile",on:{"click":function($event){$event.preventDefault();return toggleActive.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return toggleActive.apply(null, arguments)}}},[_c('TheUserMenuIcon',{attrs:{"icon":isOpened ? 'close' : 'menu',"text":_vm.userFirstLetter}})],1)],1)]}}:null,{key:"end",fn:function(){return [(_vm.isUserBuyer)?_c('TheBuyerRebateProgressBar',_vm._b({class:{ 
        'mr-2': _vm.bulmaBreakpoint.desktop, 
        'mt-1': _vm.bulmaBreakpoint.mobile || _vm.bulmaBreakpoint.tablet, 
        'justify-center': _vm.bulmaBreakpoint.mobile || _vm.bulmaBreakpoint.tablet,
      }},'TheBuyerRebateProgressBar',{
        displayWindowStepCount: _vm.bulmaBreakpoint.mobile ? 4 : 6,
        refreshKey: _vm.buyerRebateKey,
      },false)):_vm._e(),(_vm.isAuthenticated && !(_vm.bulmaBreakpoint.mobile || _vm.bulmaBreakpoint.tablet))?_c('AppNavbarNotifications',{attrs:{"displayNotificationFeed":_vm.displayNotificationFeed},on:{"update:displayNotificationFeed":function($event){return _vm.$emit('update:displayNotificationFeed', $event)}}}):_vm._e(),(_vm.showACH)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('div',{staticClass:"buttons"},[_c('SetUpACHButton')],1)]):_vm._e(),(_vm.isAuthenticated)?_c('b-navbar-dropdown',{staticClass:"user-menu-dropdown",attrs:{"right":"","arrowless":""},on:{"click":function($event){return _vm.$emit('update:displayNotificationFeed', false)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('TheUserMenuIcon',{staticClass:"is-hidden-touch",attrs:{"text":_vm.userFirstLetter}})]},proxy:true}],null,false,3921213071)},[(_vm.isUserBuyer || _vm.isUserAdmin || _vm.isUserDsr)?_c('AppNavbarZip'):_vm._e(),(_vm.showHome)?_c('NavbarItem',{attrs:{"label":"Sell"}}):_vm._e(),(_vm.isUserSeller && !_vm.isUserBuyer)?_c('NavbarItem',{attrs:{"label":"My Cars","toPath":"/dashboard/selling/0"}}):_vm._e(),(_vm.isUserCarmigoDirectDealer())?_c('NavbarItem',_vm._b({attrs:{"label":"Carmigo Direct","toPath":"/carmigoDirect","showAlertIcon":""}},'NavbarItem',{
          alertIconAttrs: { fill: _vm.numCarmigoDirectAlerts },
        },false)):_vm._e(),(_vm.isUserStoreManager)?_c('NavbarItem',{attrs:{"label":"Stores","toPath":"/stores"}}):_vm._e(),(_vm.showReferrals)?_c('NavbarItem',{attrs:{"label":"Referrals","toPath":"/referrals"}}):_vm._e(),(_vm.isUserAdmin || _vm.isUserBuyer)?_c('NavbarItem',_vm._b({attrs:{"label":"Arbitrations","toPath":"/arbitrations","showAlertIcon":""}},'NavbarItem',{
          alertIconAttrs: { fill: _vm.numArbitrationMessages },
        },false)):_vm._e(),_c('NavbarItem',{attrs:{"label":"Logout","icon":"logout","toPath":"/logout"}})],1):_c('b-navbar-item',{staticClass:"nav-item",attrs:{"tag":"router-link","to":{ path: '/login' }}},[_vm._v(" Login ")])]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }