
import { computed, defineComponent, onBeforeMount, onMounted, PropType, ref, watch } from 'vue';
import { SRPListing } from '../types';
import { useEditableFields, useEditableVDP, useGetBrandedLane, useInspectorVDP, usePreAuctionFunctions, useRecordAudio, useSaveAudio, useSelectBrandedLane, useUserRole, useVDP } from '../composables';
import { checkForRecentVehicleRelistingByVin, deleteEngineRecordingByVehicleListingId, submitInspection } from '../api';
import { useRoute } from 'vue-router/composables';
import { formatDate, isVehicleAuctioned, isVehicleSold, openModal, openToast } from '../utils';
import router from '@/router';

import AppAudioPlayer from '@/components/AppAudioPlayer.vue';
import AppAudioRecorder from '@/components/AppAudioRecorder.vue';
import AppButton from '../components/AppButton.vue';
import AppDropdownWithSearch from '@/components/AppDropdownWithSearch.vue';
import AppListingDetailsEditable from './AppListingDetailsEditable.vue';
import AppLoading from '../components/AppLoading.vue';
import TheAssignInspectorEditableField from '../components/TheAssignInspectorEditableField.vue';
import TheListingDetailsCard from '@/components/TheListingDetailsCard.vue';
import VehicleRecentlyRelistedModal from '../components/VehicleRecentlyRelistedModal.vue';

export default defineComponent({
    name: 'TheListingDetailsInspector',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
    },
    components: {
        AppAudioPlayer,
        AppAudioRecorder,
        AppButton,
        AppDropdownWithSearch,
        AppListingDetailsEditable,
        AppLoading,
        TheAssignInspectorEditableField,
        TheListingDetailsCard,
    },
    emits: ['input', 'uploadAudio'],
    setup(props, context) {
        const route = useRoute();
        const vehicleListingId = Number(route.params.vehicleListingId);

        // don't allow DSRs to edit listings after it has been auctioned
        const { isUserDsr } = useUserRole();
        let isDsrAndListingIsAuctioned = isUserDsr.value && isVehicleAuctioned(props.listing.status);
        const isEditingMode = ref(isDsrAndListingIsAuctioned ? false : true);
        function toggleEditingMode(isEditing: boolean) {
            if (isDsrAndListingIsAuctioned) {
                return;
            }
            isEditingMode.value = isEditing;
        }

        // get listing additional details
        const {
            vehiclePhotos,
            loadingVehiclePhotos,
            vehiclePhotosDTO,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            titleDelivery: titleStatus,
            loadingTitleStatus,
            sellerReason,
            loadingSellerReason,
            additionalPricingDetails,
            loadingAdditionalPricingDetails,
        } = useInspectorVDP({ vehicleListingId });

        // open model to Mark Inactive if vehicle has recently been listed
        onMounted(() => {
            checkForRecentVehicleRelistingByVin(props.listing.vin)
                .then(recentlyListedVehicle => {
                    if (recentlyListedVehicle?.startDate) {
                        openModal({
                            component: VehicleRecentlyRelistedModal,
                            props: {
                                make: props.listing.brand,
                                model: props.listing.model,
                                year: props.listing.year.toString(),
                                vin: props.listing.vin,
                                sellerFirstName: recentlyListedVehicle.firstName,
                                sellerLastName: recentlyListedVehicle.lastName,
                                sellerEmail: recentlyListedVehicle.email,
                                sellerPhoneNumber: recentlyListedVehicle.phoneNumber,
                                auctionStartDate: new Date(recentlyListedVehicle.startDate),
                                vehicleListingId,
                                vehicleHeroImageUrl: recentlyListedVehicle.vehicleHeroImageUrl,
                            },
                        });
                    }
                });
        })

        const hasBrandedLaneChanges = computed(() => brandedLane.value?.id !== selectedBrandedLane.value?.id);
        function saveUpdatedBrandedLane() {
            if (!brandedLane.value && !selectedBrandedLane.value) {
                return;
            }
            if (!selectedBrandedLane.value && brandedLane.value) {
                unassignBrandedLaneFromListing(vehicleListingId, brandedLane.value.id, {
                    onSuccess: () => brandedLane.value = undefined,
                });
            }
            if (brandedLane.value?.id !== selectedBrandedLane.value!.id) {
                assignBrandedLaneToListing(vehicleListingId, selectedBrandedLane.value!.id, {
                    onSuccess: () => brandedLane.value = selectedBrandedLane.value,
                });
            }
        }

        // get refs for editable fields
        // syncs editable values with AppListingDetailsEditable
        const { unsavedUpdates, addToUnsavedUpdates, hasUnsavedChanges } = useEditableFields({ 
            context,
            otherUnsavedChanges: hasBrandedLaneChanges,
        });

        // updates VDP DTOs with edited values
        // saves listing edits to db
        const { 
            saveUpdates, 
            loadingSaveUpdates, 
            updatedListing, 
            listingKey, 
            updateListingFromSaveUpdatesResponse, 
            updateListingFromUnsavedUpdates,
            updateListingVehiclePhotos, 
            movePricingToBasicInfoForInspectionPayload, 
            filterOutSellerReasonIfSellerTypeIsNotIndividual,
            filterOutSellerStoreEmployeeIdIfSellerTypeIsNotDealer,
            filterOutBookoutOptionsIfNoName,
        } = useEditableVDP({
            vehicleListingId,
            originalListing: props.listing,
            unsavedUpdates,
            announcements, 
            bookoutOptions, 
            vehiclePhotos,
            vehiclePhotosDTO,
            sellerReason,
            titleStatus,
        });

        async function saveInspection() {
            const pricing = movePricingToBasicInfoForInspectionPayload();
            filterOutSellerReasonIfSellerTypeIsNotIndividual();
            filterOutSellerStoreEmployeeIdIfSellerTypeIsNotDealer();
            filterOutBookoutOptionsIfNoName();
            saveUpdatedBrandedLane();
            await saveUpdates({
                pricing,
                onSuccess: ({ response, sectionName, pricing }) => { 
                    updateListingFromSaveUpdatesResponse({ response, sectionName, pricing });
                },
                onPromiseSuccess: () => {
                    openToast('is-success', 'Changes saved!');
                    unsavedUpdates.value = {};
                },
            });
            // orderAnnouncementsByType();
        }


        const { selectSellFeeAndScheduleAuction } = usePreAuctionFunctions({ vehicleListingId });

        const loadingSubmitInspection = ref(false);
        function submit() {
            loadingSubmitInspection.value = true;
            submitInspection(vehicleListingId, {
                onSuccess: () => {
                    selectSellFeeAndScheduleAuction({
                        sellerStoreId: props.listing.sellerStore?.id,
                        vehicleStatus: props.listing.status,
                        onError: (error: string) => {
                            loadingSubmitInspection.value = false;
                            goToInspectorDashboard();
                        },
                        onSellFeeClose: (event?) => {
                            if (event?.isCancelled) {
                                loadingSubmitInspection.value = false;
                            }
                        },
                        onSellFeeSelected: () => {
                            loadingSubmitInspection.value = false;
                            openToast('is-success', 'Inspection submitted!');
                            goToInspectorDashboard();
                        },
                        onAuctionScheduled: () => {
                            loadingSubmitInspection.value = false;
                            openToast('is-success', 'Auction scheduled!');
                            goToInspectorDashboard();
                        }
                    });
                },
                onError: () => {
                    loadingSubmitInspection.value = false;
                    isEditingMode.value = true;
                },
            });
        }

        function goToInspectorDashboard() {
            router.push('/inspector');
        };

        const {
            brandedLane,
            getBrandedLane,
        } = useGetBrandedLane();

        onBeforeMount(() => {
            getBrandedLane({ vehicleListingId }, {
                onSuccess: (lane) => selectedBrandedLane.value = lane[0],
            });
        });

        const {
            brandedLaneOptions,
            loadingBrandedLaneOptions,
            selectedBrandedLane,
            getBrandedLaneOptions,
            assignBrandedLaneToListing,
            unassignBrandedLaneFromListing,
            loadingSaveBrandedLane,
        } = useSelectBrandedLane();
        onBeforeMount(getBrandedLaneOptions);
        watch(() => selectedBrandedLane.value, () => {
            console.log('SELECTED BRANDED LANE', selectedBrandedLane.value);
        });

        const {
            isRecording,
            recordingUrl,
            rawRecordingUrl,
            downloadAudio,
            updateRecordingUrl,
            updateIsRecording,
            openConfirmDeleteRecordingDialog,
            formattedRecordingTime,
        } = useRecordAudio();

        const {
            loadingUploadAudio,
            uploadAudioToStorage,
        } = useSaveAudio({
            storageFilePath: `${vehicleListingId}/inspection/audio`,
            filename: 'engine',
            context,
        });

        function saveEngineAudioFromDevice(event: Blob) {
            uploadAudioToStorage(event, {
                onSuccess: (url) => {
                    updateEngineRecordingUrl(url);
                },
            });
        }

        function downloadEngineAudio() {
            let formattedDate = formatDate(new Date(), 'MM_dd_yyyy-hhmma');
            let fileName = `${formattedDate}_engine_recording`;
            downloadAudio(fileName, rawRecordingUrl.value ?? recordingUrl.value);
        }

        function updateEngineRecordingUrl(url: string) {
            updateRecordingUrl(url);
            addToUnsavedUpdates({
                engineRecording: url,
            });
        }

        const loadingDeleteRecording = ref(false);
        function confirmDeleteRecording() {
            openConfirmDeleteRecordingDialog({
                onConfirm: () => {
                    loadingDeleteRecording.value = true;
                    deleteEngineRecordingByVehicleListingId(vehicleListingId, {
                        onSuccess: () => {
                            if (unsavedUpdates.value.engineRecording) {
                                let newValues = {};
                                Object.keys(unsavedUpdates.value).forEach(key => {
                                    if (key !== 'engineRecording') {
                                        newValues[key] = unsavedUpdates.value[key];
                                    }
                                });
                                unsavedUpdates.value = newValues;
                            }
                            updateRecordingUrl(undefined);
                            loadingDeleteRecording.value = false;
                        },
                        onError: () => loadingDeleteRecording.value = false,
                    });
                }
            });
        }

        const {
            engineRecordingUrl,
            loadingEngineRecording,
        } = useVDP({ vehicleListingId });

        watch(engineRecordingUrl, () => {
            recordingUrl.value = engineRecordingUrl.value;
        });

        return {
            loadingEngineRecording,
            loadingDeleteRecording,
            isRecording,
            recordingUrl,
            rawRecordingUrl,
            downloadEngineAudio,
            updateIsRecording,
            updateEngineRecordingUrl,
            confirmDeleteRecording,
            formattedRecordingTime,
            loadingUploadAudio,
            saveEngineAudioFromDevice,
            
            toggleEditingMode,
            isDsrAndListingIsAuctioned,
            isVehicleSold,
            updatedListing,
            listingKey,
            addToUnsavedUpdates,
            updateListingFromUnsavedUpdates,
            hasUnsavedChanges,
            isEditingMode,
            vehicleListingId,
            vehiclePhotos,
            loadingVehiclePhotos,
            vehiclePhotosDTO,
            announcements,
            loadingAnnouncements,
            brandedLaneOptions,
            loadingBrandedLaneOptions,
            selectedBrandedLane,
            loadingSaveBrandedLane,
            bookoutOptions,
            loadingBookoutOptions,
            titleStatus,
            loadingTitleStatus,
            sellerReason,
            loadingSellerReason,
            additionalPricingDetails,
            loadingAdditionalPricingDetails,
            submit,
            loadingSubmitInspection,
            saveInspection,
            loadingSaveUpdates,
            updateListingVehiclePhotos,
        }
    }
});
