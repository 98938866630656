
import { defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { print } from '@/utils';

import AppButton from '@/components/AppButton.vue';

export default defineComponent({
    name: 'AppPrintButton',
    props: {
        elementId: {
            type: String,
            required: true,
        },
        documentTitle: {
            type: String,
            required: false,
        },
        cssText: {
            type: Array as PropType<string[]>,
            required: true,
        },
        useCustomSubmit: {
            type: Boolean,
            default: false,
        },
        triggerSubmit: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppButton,
    },
    setup(props, context) {
        const printButton: Ref<any | null> = ref(null);
        const loadingPrintIframe = ref(false);
        const originalDocumentTitle = document.title;

        function sendToPrinter() {
            if (printButton.value?.$el) {
                printButton.value.$el.blur();
            }
            if (props.useCustomSubmit && !props.triggerSubmit) {
                return;
            }
            loadingPrintIframe.value = true;
            print(props.elementId, props.cssText, {
                onLoad: () => loadingPrintIframe.value = false,
                onBeforePrint: () => props.documentTitle ? document.title = props.documentTitle : undefined,
                onAfterPrint: () => props.documentTitle ? document.title = originalDocumentTitle : undefined,
            });
        }

        onMounted(() => {
            if (props.triggerSubmit) {
                sendToPrinter();
            }
        });

        return {
            printButton,
            loadingPrintIframe,
            sendToPrinter,
        }
    }
});
