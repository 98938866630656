import { assignInspectorToListing, deleteAnnouncementById, getAnnouncementCategories, getAnnouncementPrediction, getAnnouncementSubcategories, getProfileLiteByRole, saveAnnouncement, selfAssignInspection } from '@/api';
import {
    AnnouncementCategory,
    AnnouncementType,
    APIConfig, 
    ImageDTO,
    ProfileLiteDTO,
    VehicleListingAnnouncement,
    VehicleListingAnnouncementUpdate,
} from '@/types';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, isUserInspector, openCancelInspectionRequestConfirmationDialog, openErrorDialog, openRequestInspectionConfirmationDialog, openToast } from '@/utils';
import { debounce } from 'lodash';
import { computed, ComputedRef, onMounted, Ref, ref, SetupContext, watch } from 'vue';
import { useUser } from './user';
import { AnnouncementSubcategory } from '@/types/AnnouncementSubcategory';
import { AnnouncementTypeEnum } from '@/enums';
import { BNoticeComponent } from 'buefy/types/components';
import store from '@/vuex';

export interface WholesaleRecommendationMaterialized {
  inventoryId: number,
  storeId: number | null,
  year: string | null,
  make: string | null,
  model: string | null,
  trim: string | null,
  vin: string | null,
  stockNumber: string | null,
  miles: number | null,
  daysOnLot: number | null,
  mds: number | null,
  internetPrice: number | null,
  cost: number | null,
  expectedHoldingCost: number | null,
  status: string | null,
  vehicleListingId: number,
  inspectionRequestDate?: Date | null,
}

export function useRequestInspection(tableData: Ref<WholesaleRecommendationMaterialized[]>) {
    const loadingActionButtonInventoryIds = ref<number[]>([]);

    function toggleLoadingActionButton(loading: boolean, inventoryId: number) {
        const loadingIdx = loadingActionButtonInventoryIds.value.findIndex((id) => id == inventoryId);
        if (loading && loadingIdx < 0) { // loading and not already in array
            loadingActionButtonInventoryIds.value.push(inventoryId);
        } else if (!loading && loadingIdx >= 0) { // not loading and in array
            loadingActionButtonInventoryIds.value.splice(loadingIdx, 1);
        }
    }

    function requestInspection(inventoryId: number) {
        toggleLoadingActionButton(true, inventoryId);
        openRequestInspectionConfirmationDialog(
            inventoryId,
            {
                onCancel: () => toggleLoadingActionButton(false, inventoryId),
            },
            {
                onSuccess: (res) => {
                    const tableRow = tableData.value.find((row) => row.inventoryId == inventoryId);
                    if (tableRow) {
                        tableRow.inspectionRequestDate = res;
                    }
                    toggleLoadingActionButton(false, inventoryId);
                },
                onError: () => toggleLoadingActionButton(false, inventoryId),
            },
        );
    }

    function cancelInspection(inventoryId: number) {
        toggleLoadingActionButton(true, inventoryId);
        openCancelInspectionRequestConfirmationDialog(
            inventoryId,
            {
                onCancel: () => toggleLoadingActionButton(false, inventoryId),
            },
            {
                onSuccess: () => {
                    const tableRow = tableData.value.find((row) => row.inventoryId == inventoryId);
                    if (tableRow) {
                        tableRow.inspectionRequestDate = null;
                    }
                    toggleLoadingActionButton(false, inventoryId);
                },
                onError: () => toggleLoadingActionButton(false, inventoryId),
            },
        );
    }

    function requestOrCancelInspection(row: WholesaleRecommendationMaterialized) {
        if (row.inspectionRequestDate) {
            cancelInspection(row.inventoryId);
        } else {
            requestInspection(row.inventoryId);
        }
    }

    return {
        loadingActionButtonInventoryIds,
        requestOrCancelInspection,
    };
}


export function useAssignInspector({ vehicleListingId, inspectorPersonId, inspectorOptions, context }: {
    vehicleListingId: number,
    inspectorPersonId?: number,
    inspectorOptions: Ref<ProfileLiteDTO[]>,
    context: SetupContext<('select' | any)[]>
}) {
    const assignedInspector: Ref<ProfileLiteDTO | undefined> = ref(undefined);
    const assignedInspectorPersonId: Ref<number | undefined> = ref(undefined);
    const inspectorKey: Ref<number> = ref(0);
    const loadingSaveInspector: Ref<boolean> = ref(false);

    function assignInspectorFromInspectorOptions(personId: number) {
        let inspector = inspectorOptions.value.find(profileLite => profileLite.personId == personId);
        if (inspector) {
            updateAssignedInspector(inspector);
        }
    }

    const user = useUser();
    async function assignLoggedInInspector() {
        let loggedInPersonId = user.value?.profile?.id ? Number(user.value.profile.id) : undefined;
        if (isUserInspector() && loggedInPersonId) {
            loadingSaveInspector.value = true;
            selfAssignInspection(vehicleListingId, {
                onSuccess: (res) => {
                    loadingSaveInspector.value = false;
                    assignInspectorFromInspectorOptions(loggedInPersonId!);
                    openToast('is-success', res);
                },
                onError: () => loadingSaveInspector.value = false,
            });
        }
    }

    async function updateAssignedInspector(inspector?: ProfileLiteDTO) {
        assignedInspector.value = inspector;
        assignedInspectorPersonId.value = inspector?.personId;
        context.emit('select', inspector);
        inspectorKey.value++;
    }

    async function assignInspector() {
        loadingSaveInspector.value = true;
        await assignInspectorToListing(assignedInspectorPersonId.value ?? null, vehicleListingId, {
            onSuccess: () => {
                loadingSaveInspector.value = false;
                assignedInspectorPersonId.value 
                    ? openToast('is-success', 'Inspector assigned!')
                    : openToast('is-success', 'Inspector unassigned');
            },
            onError: () => loadingSaveInspector.value = false,
        });
    }

    return {
        assignedInspector,
        assignedInspectorPersonId,
        assignInspectorFromInspectorOptions,
        assignLoggedInInspector,
        assignInspector,
        updateAssignedInspector,
        inspectorKey,
        loadingSaveInspector,
    }
}

export function useGetInspectorOptions() {
    const inspectorOptions: Ref<ProfileLiteDTO[]> = ref([]);
    const loadingInspectorOptions: Ref<boolean> = ref(false);
    
    async function getInspectorOptions(config: APIConfig={}) {
        loadingInspectorOptions.value = true;
        await getProfileLiteByRole(['inspector', 'admin'], undefined, {
            onSuccess: (res) => {
                loadingInspectorOptions.value = false;
                inspectorOptions.value = res;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: () => loadingInspectorOptions.value = false,
        });
    }

    return {
        inspectorOptions,
        loadingInspectorOptions,
        getInspectorOptions,
    }
}

export function useAnnouncementAutocomplete() {
    const announcementPredictions: Ref<string[]> = ref([]);
    const loadingAnnouncementPredictions: Ref<number | undefined> = ref(undefined);

    const debouncedGetAnnouncementAutocomplete = debounce(getAnnouncementAutocomplete, 300);

    async function getAnnouncementAutocomplete(announcement: string, idx?: number) {
        if (!announcement) {
            return;
        }
        loadingAnnouncementPredictions.value = idx;
        announcementPredictions.value = await getAnnouncementPrediction(announcement, {
            onSuccess: () => loadingAnnouncementPredictions.value = undefined,
            onError: () => loadingAnnouncementPredictions.value = undefined,
        });
    }

    return {
        debouncedGetAnnouncementAutocomplete,
        announcementPredictions,
        loadingAnnouncementPredictions,
    }
}

export function useCreateAnnouncementForm({ vehicleListingId, editingAnnouncement, context }: {
    vehicleListingId?: number,
    editingAnnouncement?: VehicleListingAnnouncement,
    context?: SetupContext<('update' | 'saveAnnouncements' | 'close' | any)[]>,
}={}) {
    // selected category
    const selectedCategoryId: Ref<number | undefined> = ref(editingAnnouncement?.categories?.categoryId);
    const selectedCategory: ComputedRef<AnnouncementCategory | undefined> = computed(() => {
        if (!selectedCategoryId.value || !categoryOptions.value?.length) {
            return undefined;
        }
        return categoryOptions.value.find(category => category.id == selectedCategoryId.value);
    });
    const categoryOptions: Ref<AnnouncementCategory[]> = ref([]);
    const loadingCategoryOptions: Ref<boolean> = ref(false);
    async function getCategoryOptions() { // call this from onMounted
        if (store.state.announcementCategories?.length) {
            categoryOptions.value = store.state.announcementCategories;
            return;
        }
        loadingCategoryOptions.value = true;
        await getAnnouncementCategories({
            onSuccess: (res) => {
                store.commit('updateAnnouncementCategories', res);
                categoryOptions.value = res;
                loadingCategoryOptions.value = false;
            },
            onError: () => loadingCategoryOptions.value = false,
        });
    }
    // selected subcategory
    const selectedSubcategoryId: Ref<number | undefined> = ref(editingAnnouncement?.categories?.subcategoryId);
    const selectedSubcategory: ComputedRef<AnnouncementSubcategory | undefined> = computed(() => {
        if (!selectedSubcategoryId.value || !subcategoryOptions.value.length) {
            return undefined;
        }
        return subcategoryOptions.value.find(subcategory => subcategory.id == selectedSubcategoryId.value);
    });
    onMounted(() => {
        if (editingAnnouncement?.categories?.categoryId) {
            getSubcategoryOptions(editingAnnouncement.categories.categoryId);
        }
    });
    watch(() => selectedCategoryId.value, () => {
        selectedSubcategoryId.value = undefined;
        subcategoryOptions.value = [];
        if (selectedCategoryId.value) {
            getSubcategoryOptions(selectedCategoryId.value);
        }
    });
    const subcategoryOptions: Ref<AnnouncementSubcategory[]> = ref([]);
    const loadingSubcategoryOptions: Ref<boolean> = ref(false);
    async function getSubcategoryOptions(announcementCategoryId: number ) {
        if (store.state.announcementSubcategories[announcementCategoryId]?.length) {
            subcategoryOptions.value = store.state.announcementSubcategories[announcementCategoryId];
            return;
        }
        loadingSubcategoryOptions.value = true;
        await getAnnouncementSubcategories(announcementCategoryId ?? 0, {
            onSuccess: (res) => {
                store.commit('updateAnnouncementSubcategories', { category: announcementCategoryId, subcategories: res });
                subcategoryOptions.value = res;
                loadingSubcategoryOptions.value = false;
            },
            onError: () => loadingSubcategoryOptions.value = false,
        });
    }

    // selected announcement type
    const selectedAnnouncementTypeId: Ref<number | undefined> = ref(editingAnnouncement?.announcementType ? AnnouncementTypeEnum[editingAnnouncement.announcementType.toUpperCase() as keyof typeof AnnouncementTypeEnum] : AnnouncementTypeEnum.NEUTRAL); 
    const announcementTypeOptions: Ref<{ id: number, type: AnnouncementType }[]> = ref([
        {
            id: 2,
            type: 'positive' as AnnouncementType,
            displayValue: 'Positive',
        },
        {
            id: 1,
            type: 'negative' as AnnouncementType,
            displayValue: 'Negative',
        },
        {
            id: 3,
            type: 'neutral' as AnnouncementType,
            displayValue: 'Neutral',
        },
        {
            id: 4,
            type: 'as_is' as AnnouncementType,
            displayValue: 'As is',
        },
    ]);
    const selectedAnnouncementType: ComputedRef<{ id: number, type: AnnouncementType } | undefined> = computed(() => {
        if (!selectedAnnouncementTypeId.value || !announcementTypeOptions.value?.length) {
            return undefined;
        }
        return announcementTypeOptions.value.find(announcementType => announcementType.id == selectedAnnouncementTypeId.value);
    });

    // announcementText
    const announcementText: Ref<string | undefined> = ref(editingAnnouncement?.announcement);
    // vehicle photo
    const selectedVehiclePhoto: Ref<ImageDTO | undefined> = ref(editingAnnouncement?.announcementPhoto);

    const updatedAnnouncement: ComputedRef<VehicleListingAnnouncementUpdate> = computed(() => {
        return {
            announcementTypeId: selectedAnnouncementTypeId.value,
            announcement: announcementText.value,   
            announcementSubcategoryId: selectedSubcategoryId.value,
            vehicleListingPhotoId: selectedVehiclePhoto.value?.vehicleListingPhotoId,
            vehicleListingAnnouncementId: editingAnnouncement?.vehicleListingAnnouncementId,
        }
    });
    watch(updatedAnnouncement, () => {
        context?.emit('update', {
            announcement: updatedAnnouncement.value,
            prefillFields: {
                announcementType: selectedAnnouncementType.value?.type,
                category: selectedCategory.value,
                subcategory: selectedSubcategory.value,
            }
        });
    }, { deep: true });

    // save announcements
    const loadingSaveAnnouncement: Ref<boolean> = ref(false);
    let openedToast: BNoticeComponent | null = null;
    async function saveOrUpdateAnnouncement(announcement: VehicleListingAnnouncementUpdate, config: APIConfig={}) {
        if (!vehicleListingId) {
            return;
        }
        loadingSaveAnnouncement.value = true;
        openedToast = openToast('is-grey', 'Saving announcement...', 'indefinite');
        await saveAnnouncement(vehicleListingId, announcement, {
            ...config,
            onSuccess: (allAnnouncements) => {
                openedToast?.close();
                openedToast = openToast('is-success', 'Announcements updated!');
                applyAPIConfigOnSuccess(allAnnouncements, config);
                context?.emit('saveAnnouncements', allAnnouncements);
                loadingSaveAnnouncement.value = false;
                context?.emit('close');
            },
            onError: (error) => {
                openedToast?.close();
                applyAPIConfigOnError(error, config);
                loadingSaveAnnouncement.value = false;
            },
        });
    }

    function clearAnnouncementImageAndText() {
        announcementText.value = undefined;
        selectedVehiclePhoto.value = undefined;
    }

    return {
        selectedAnnouncementTypeId,
        selectedAnnouncementType,
        selectedCategory,
        selectedCategoryId,
        selectedSubcategory,
        selectedSubcategoryId,
        announcementText,
        selectedVehiclePhoto,
        updatedAnnouncement,

        announcementTypeOptions,
        categoryOptions,
        getCategoryOptions,
        subcategoryOptions,
        loadingCategoryOptions,
        loadingSubcategoryOptions,

        saveOrUpdateAnnouncement,
        loadingSaveAnnouncement,
        clearAnnouncementImageAndText,
    }
}