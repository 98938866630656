<template>
  <section>
    <div class="titleContainer">
      <h1 class="is-size-5">{{ user.firstName }} {{ user.lastName }}</h1>
    </div>
    <div class="is-flex is-flex-direction-row spaceTop">
      <h2 class="is-size-6">
        Email:
      </h2>
      <p class="is-size-6 ml-2">{{ user.email }}</p>
    </div>
    <div v-if="user.phoneNumber" class="is-flex is-flex-direction-row spaceTop">
      <h2 class="is-size-6">
        Phone:
      </h2>
      <p class="is-size-6 ml-2">{{ user.phoneNumber }}</p>
    </div>
    <div class="is-flex is-flex-direction-row spaceTop">
      <h2 class="is-size-6">
        Referral Code:
      </h2>
      <p class="is-size-6 ml-2">{{ user.referralCode }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ReferralUserDetails",
  props: ["user"],
  data() {
    return {};
  },
};
</script>

<style></style>
