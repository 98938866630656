import { addBrandedLaneToListing, getAdditionalPricingDetailsByVehicleListingId, getBrandedLanes, removeBrandedLaneFromListing } from '@/api';
import { 
    AdditionalPricingDTO,
    APIConfig,
    BrandedLane,
    SRPListing,
    TabButton,
} from '@/types';
import { applyAPIConfigOnSuccess, createVehiclePricingDetailsModal, updateUrlParams } from '@/utils';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';
import { useUserRole } from './user';
import { useRoute } from 'vue-router/composables';
import { debounce, pick } from 'lodash';
import { useFetchAnnouncements, useFetchBookoutOptions, useFetchVehiclePhotos } from './vdp';


export function useAdditionalPricingModal({ config }: { config: APIConfig }) {
    const loadingAdditionalPricingVehicleListingId: Ref<number | undefined> = ref(undefined);
    async function openAdditionalPricingModal(vehicleListingId: number) {
        loadingAdditionalPricingVehicleListingId.value = vehicleListingId;
        await getAdditionalPricingDetailsByVehicleListingId(vehicleListingId, {
            onSuccess: async(pricingDetails: AdditionalPricingDTO) => {
                loadingAdditionalPricingVehicleListingId.value = undefined;
                let updatedPricing = await createVehiclePricingDetailsModal(vehicleListingId, { props: { pricingDetails }});
                applyAPIConfigOnSuccess({ updatedPricing, vehicleListingId }, config);
            },
            onError: () => loadingAdditionalPricingVehicleListingId.value = undefined,
        });
    }

    return {
        openAdditionalPricingModal,
        loadingAdditionalPricingVehicleListingId,
    }
}

export function useBuyerDashboardListingsByStore() {
    const route = useRoute();

    // select my listings vs. listings by store
    const myListingsOrListingsByStore: Ref<('myListings' | 'listingsByStore')[]> = ref((route.query.listings ? [route.query.listings] : ['myListings']) as ('myListings' | 'listingsByStore')[]);
    watch(myListingsOrListingsByStore, () => {
        // if 'listings by store' is selected, wait for the selectedStoreIds to be updated before updating url params
        if (myListingsOrListingsByStore.value.includes('listingsByStore')) {
            return;
        }

        // if 'my listings' is selected, update 'listings' and clear 'stores'
        updateUrlParams({
            maintainAllParams: true,
            newQueryParams: {
                listings: myListingsOrListingsByStore.value[0],
                stores: null,
            },
            route,
        });
    });

    function updateSelectedTab({ tab, isSelected }: { tab: TabButton, isSelected: boolean }) {
        let tabValue = tab.value as 'myListings' | 'listingsByStore';
        if (isSelected && !myListingsOrListingsByStore.value.includes(tabValue)) {
            myListingsOrListingsByStore.value = [tabValue];
            isStoreDropdownOpen.value = tab.value == 'listingsByStore' && isSelected;
        }
    }

    // select store
    const isStoreDropdownOpen = ref(false);
    const selectedStoreIds: Ref<number[]> = ref([]);
    const storeIdsToStringArr: ComputedRef<string[] | undefined> = computed(() => {
        if (!selectedStoreIds.value.length) {
            return undefined;
        }
        // filter out non-store values from selectedStoreIds (customDropdownItem prop in TheSelectStoresDropdownButton)
        let storeIdsStr: string[] = selectedStoreIds.value
            .filter(id => (id as number | string) !== 'myListings')
            .map(id => id.toString()); // convert store IDs to strings for route params
        return storeIdsStr;
    });

    if (route.query.stores) {
        if (typeof route.query.stores === 'string') {
            selectedStoreIds.value = [parseInt(route.query.stores)];
        } else {
            selectedStoreIds.value = (route.query.stores as string[]).map(storeId => parseInt(storeId));
        }
    } 

    watch(() => selectedStoreIds.value, () => debouncedUpdateStoreUrlParams());

    const { isUserAdmin } = useUserRole();
    const debouncedUpdateStoreUrlParams = debounce(() => {
        updateUrlParams({
            maintainAllParams: true,
            newQueryParams: {
                stores: storeIdsToStringArr.value,
                listings: (storeIdsToStringArr.value?.length || isUserAdmin.value) ? 'listingsByStore' : 'myListings',
            },
            route,
        });
    }, 1000);

    function removeSelectedStore(id: number) {
        let idx = selectedStoreIds.value.findIndex(storeId => storeId == id);
        if (idx >= 0) {
            let updatedSelectedStores = [...selectedStoreIds.value];
            updatedSelectedStores.splice(idx, 1);
            selectedStoreIds.value = updatedSelectedStores;
        }
    }

    return {
        selectedStoreIds,
        updateSelectedTab,
        myListingsOrListingsByStore,
        isStoreDropdownOpen,
        removeSelectedStore,
    }
}

export function useGetBrandedLane() {
    const brandedLane: Ref<BrandedLane | undefined> = ref(undefined);
    const loadingBrandedLane = ref(false);
    
    async function getBrandedLane({ vehicleListingId, active, hasListings }: { vehicleListingId?: number, active?: boolean, hasListings?: boolean }={}, config: APIConfig={}) {
        getBrandedLanes({ vehicleListingId, active, hasListings }, {}, {
            onSuccess: (lanes) => {
                if (lanes?.length) {
                    brandedLane.value = lanes[0];
                }
                loadingBrandedLane.value = false;
                applyAPIConfigOnSuccess(lanes, config);
            },
            onError: () => loadingBrandedLane.value = false,
        });
    }
    
    return {
        brandedLane,
        loadingBrandedLane,
        getBrandedLane,
    }
}

export function useSelectBrandedLane() {
    const brandedLaneOptions: Ref<BrandedLane[]> = ref([]);
    const loadingBrandedLaneOptions = ref(false);
    const selectedBrandedLane: Ref<BrandedLane | undefined> = ref(undefined);

    function getBrandedLaneOptions() {
        loadingBrandedLaneOptions.value = true;
        getBrandedLanes({}, {
            filters: [
                {
                    property: 'brandedLane.isAvailable',
                    comparator: '=',
                    values: 'true',
                },
            ]
        }, {
            onSuccess: (res) => {
                brandedLaneOptions.value = res;
                loadingBrandedLaneOptions.value = false;
            },
            onError: () => loadingBrandedLaneOptions.value = false,
        });
    }

    const loadingSaveBrandedLane = ref(false);
    function assignBrandedLaneToListing(vehicleListingId: number, brandedLaneId: number, config: APIConfig={}) {
        loadingSaveBrandedLane.value = true;
        addBrandedLaneToListing(vehicleListingId, brandedLaneId, {
            onSuccess: (res) => {
                applyAPIConfigOnSuccess(res, config);
                loadingSaveBrandedLane.value = false;
            },
            onError: () => loadingSaveBrandedLane.value = false,
        });
    }

    function unassignBrandedLaneFromListing(vehicleListingId: number, brandedLaneId: number, config: APIConfig={}) {
        loadingSaveBrandedLane.value = true;
        removeBrandedLaneFromListing(vehicleListingId, brandedLaneId, {
            onSuccess: () => {
                applyAPIConfigOnSuccess(undefined, config);
                loadingSaveBrandedLane.value = false;
            },
            onError: () => loadingSaveBrandedLane.value = false,
        });
    }

    return {
        brandedLaneOptions,
        loadingBrandedLaneOptions,
        selectedBrandedLane,
        getBrandedLaneOptions,
        assignBrandedLaneToListing,
        unassignBrandedLaneFromListing,
        loadingSaveBrandedLane,
    }
}

export function useDisplayBrandedLane() {
    const route = useRoute();
    const brandedLaneFromRoute = route.query.brandedLane ? {
        name: route.query.brandedLane as string,
    } : undefined;

    const brandedLane: Ref<Partial<BrandedLane> | undefined> = ref(brandedLaneFromRoute);

    const {
        brandedLane: brandedLaneFetched,
        getBrandedLane,
    } = useGetBrandedLane();

    watch(() => brandedLaneFetched.value, () => {
        brandedLane.value = brandedLaneFetched.value;
    });

    const brandedLaneImage = computed(() => {
        if (brandedLane.value?.imageUrl) {
            return `url(${brandedLane.value.imageUrl})`;
        }
    });

    return {
        brandedLane,
        brandedLaneFromRoute,
        brandedLaneImage,
        getBrandedLane,
    }
}

export function useConditionReportPdf(listing: Partial<SRPListing>, vehicleListingId: number) {
    let fetched = ref(0);
    let options = {
        immediate: false,
        onSuccess: () => fetched.value++,
        onError: () => fetched.value++,
    }
    const { announcements, loadingAnnouncements, fetchAnnouncements } = useFetchAnnouncements(vehicleListingId, options);
    const { bookoutOptions, loadingBookoutOptions, fetchBookoutOptions } = useFetchBookoutOptions(vehicleListingId, options);
    const { vehiclePhotos, loadingVehiclePhotos, fetchVehiclePhotos } = useFetchVehiclePhotos(vehicleListingId, options);
    let vehicle = {
        ...pick(listing, ['year', 'model', 'trim', 'vin', 'mileage']),
        make: listing.brand,
    }

    const loadingConditionReport = computed(() => loadingAnnouncements.value || loadingBookoutOptions.value || loadingVehiclePhotos.value);
    const loadedConditionReport = computed(() => fetched.value == 3);
    const conditionReport = computed(() => {
        return {
            vehicle,
            announcements: announcements.value,
            features: bookoutOptions.value,
            photos: vehiclePhotos.value,
        }
    });
    return {
        fetchAnnouncements,
        fetchBookoutOptions,
        fetchVehiclePhotos,
        conditionReport,
        loadingConditionReport,
        loadedConditionReport,
    }
}