
import { defineComponent, PropType, ref } from 'vue';
import { useCreateListingNote } from '@/composables/notes';
import { NoteDTO } from '@/types';

import AppLoadingSkeleton from '@/components/AppLoadingSkeleton.vue';
import InfoHeader from '@/components/Arbitrations/InfoHeader.vue';
import Modal from '@/components/Modal.vue';
import TheListingBuyerNotes from '@/components/TheListingBuyerNotes.vue';

export default defineComponent({
    name: 'TheBuyerNotesModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicleName: {
            type: String,
            required: false,
        },
        imageUrl: {
            type: String,
            required: false,
        },
        vin: {
            type: String,
            required: false,
        },
        buyerNotesInitial: {
            type: Array as PropType<NoteDTO[]>,
            required: false,
        },
    },
    components: {
        AppLoadingSkeleton,
        InfoHeader,
        Modal,
        TheListingBuyerNotes,
    },
    emits: ['close', 'updateNotes'],
    setup(props, context) {
        const buyerNotes = ref(props.buyerNotesInitial);
        const loadingBuyerNotes = ref(false);

        const {
            noteText,
            submitNote,
            loadingSubmitNote,
        } = useCreateListingNote({ 
            vehicleListingId: props.vehicleListingId,
            noteType: 'private',
        });

        function submitAndUpdateNotes() {
            submitNote({
                onSuccess: (updatedNotes: NoteDTO[]) => {
                    buyerNotes.value = updatedNotes;
                    context.emit('updateNotes', updatedNotes);
                },
            });
        }

        return {
            buyerNotes,
            noteText,
            submitAndUpdateNotes,
            loadingSubmitNote,
            loadingBuyerNotes,
        }
    },
});
