var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isRecording)?_c('AppButton',_vm._b({staticClass:"carmigo-button",on:{"click":_vm.stopRecording}},'AppButton',{
            label: 'Stop Recording',
            type: 'is-danger',
            ..._vm.$attrs.stopButtonAttrs,
            loading: _vm.loadingUploadAudio || _vm.$attrs.stopButtonAttrs.loading,
        },false)):_c('AppButton',_vm._b({staticClass:"carmigo-button",on:{"click":_vm.startRecording}},'AppButton',{
            label: 'Start Recording',
            type: 'is-primary',
            ..._vm.$attrs.startButtonAttrs,
            loading: _vm.loadingUploadAudio || _vm.$attrs.startButtonAttrs.loading,
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }