var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-field',_vm._b({},'b-field',_vm.$attrs.fieldAttrs,false),[_c('b-dropdown',_vm._b({ref:"dropdownRef",scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_vm._t("trigger",function(){return [_c('AppButton',_vm._b({staticClass:"dropdown-button carmigo-button",on:{"click":function($event){return _vm.$emit('update:isOpen', !_vm.$props.isOpen)}}},'AppButton',{
                        iconRight: active ? 'menu-up' : 'menu-down',
                        label: _vm.placeholder,
                        ..._vm.$attrs.buttonAttrs,
                    },false),[_vm._t("buttonLabel",null,null,{ active })],2)]},null,{ active })]}}],null,true),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},'b-dropdown',{
            position: 'is-bottom-left',
            ..._vm.$attrs,
        },false),[_vm._t("dropdown-start"),(_vm.$slots.empty)?_c('div',{staticClass:"text-center"},[(!_vm.options.length)?_vm._t("empty"):_vm._e()],2):_vm._e(),_vm._l((_vm.options),function(option,idx){return _c('b-dropdown-item',{key:`dropdown-item-${idx}`,attrs:{"value":_vm.optionValueKey ? option[_vm.optionValueKey] : option,"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('click', option)}}},[_vm._t("dropdownItem",function(){return [_vm._v(" "+_vm._s(_vm.optionLabelKey ? option[_vm.optionLabelKey] : option)+" ")]},null,{ option, isSelected: _vm.isOptionSelected(option) })],2)}),_vm._t("dropdown-end",function(){return [(_vm.$attrs.multiple && _vm.$props.useSubmitButton)?_c('div',{staticClass:"flex-row align-center justify-space-between p-1"},[_c('span',{staticClass:"has-text-grey-light is-size-7",class:{ 'visibility-hidden': _vm.$attrs.hideSelectedCount }},[_vm._v(_vm._s(_vm.selectedOption.length)+" selected")]),_c('AppButton',_vm._b({staticClass:"carmigo-button",on:{"click":function($event){return _vm.$emit('submit', _vm.selectedOption)}}},'AppButton',{
                        iconRight: 'arrow-right',
                        type: 'is-primary',
                        disabled: !_vm.hasChanges,
                        size: 'is-small',
                    },false))],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }