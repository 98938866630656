
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { CurrentNegotiationDTO } from '@/types';
import { formatDate, formatDollarAmount, getListingTypeColor, getListingTypeLabel } from '@/utils';
import { useCurrentNegotiationOffer, useLoggedInUser, useSingleAuctionListener, useUpdateAuctionListingCardOnRdbChange } from '@/composables';
import { NegotiationTypeEnum } from '@/enums';

import AppButton from './AppButton.vue';
import TheListingActivityCard from './TheListingActivityCard.vue';
import TheNegotiateButtonAuction from './TheNegotiateButtonAuction.vue';

export default defineComponent({
    name: 'TheListingActivityCardNegotiating',
    props: {
        vehicleListingId: {
            type: Number as PropType<number>,
            required: true,
        },
        vin: {
            type: String,
            required: true,
        },
        currentNegotiation: {
            type: Object as PropType<CurrentNegotiationDTO>,
            required: false,
        },
        storeIds: {
            type: Array as PropType<number[]>,
            required: false,
        },
    },
    components: {
        AppButton,
        TheListingActivityCard,
        TheNegotiateButtonAuction,
    },
    setup(props, { attrs }) {
        const viewingMultipleStores = computed(() => Boolean(props.storeIds?.length));
    
        const { highestBid, updateAuction } = useUpdateAuctionListingCardOnRdbChange();
        const { loadingHighestBidListener } = useSingleAuctionListener(props.vehicleListingId, (snapshot) => {
            updateAuction(snapshot);
            if (snapshot.isInitialSnapshot) {
                return;
            }
            if (snapshot.newSnapshotValue.negotiations?.length !== snapshot.oldSnapshotValue?.negotiations?.length) {
                getUpdatedNegotiationOffer(props.vehicleListingId);
            }
        });

        const {
            negotiationOffer,
            loadingNegotiationOffer,
            getUpdatedNegotiationOffer,
        } = useCurrentNegotiationOffer({
            currentNegotiation: props.currentNegotiation,
        });
        const { loggedInUserPersonId } = useLoggedInUser();
        const isMyNegotiation = computed(() => negotiationOffer.value?.buyer?.personId == loggedInUserPersonId.value)

        const sellerSentOffer = computed(() => {
            if (!negotiationOffer.value) {
                return false;
            }
            return NegotiationTypeEnum[negotiationOffer.value?.mostRecentNegotiationTypeId] === 'seller';
        });

        const getAuctionEndDate = computed(() => {
            if (highestBid.value?.auctionEnd) {
                return formatDate(new Date(highestBid.value.auctionEnd));
            }
            return '--';
        });

        const getClasses = computed(() => {
            return {
                'show-listing-type': attrs.showListingTypeFlag,
                'multiple-stores': viewingMultipleStores.value,
            }
        });

        return {
            viewingMultipleStores,
            isMyNegotiation,
            getClasses,
            getListingTypeColor,
            getListingTypeLabel,
            formatDollarAmount,

            highestBid,
            loadingHighestBidListener,
            getAuctionEndDate,

            sellerSentOffer,
            negotiationOffer,
            loadingNegotiationOffer,
        }
    }
});
