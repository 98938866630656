var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.inProgressPhotos.length > 0)?_c('div',{staticClass:"mb-3 upload-progress-container"},_vm._l((_vm.inProgressPhotos),function(photo,index){return _c('b-message',{key:index,attrs:{"type":"is-info","size":"is-small"}},[_c('div',{staticClass:"is-flex is-flex-direction-row is-align-items-center"},[_c('div',{staticClass:"loading-ring mr-4"},[_c('div'),_c('div'),_c('div'),_c('div')]),_c('div',[_vm._v("Uploading "+_vm._s(photo)+"...")])])])}),1):_vm._e(),_c('div',{staticClass:"header-container flex-row mobile-column justify-space-between mb-3",class:{ 'is-sticky': _vm.isStickyHeader }},[_c('div',{staticClass:"mr-3 header flex-column"},[_c('div',{staticClass:"flex-row justify-space-between"},[_c('div',{staticClass:"flex-row align-center"},[_c('h2',{staticClass:"header-text is-size-3 is-size-4-mobile has-text-primary text-center"},[_vm._v(_vm._s(_vm.headerText))]),_c('span',{staticClass:"is-size-4 is-size-5-mobile ml-2 has-text-dark"},[_vm._v("("+_vm._s(_vm.photosUpdated.length)+")")])]),_c('AddAttachmentsButton',_vm._b({staticClass:"mobile-show",on:{"uploading":_vm.handleUploading,"uploaded":_vm.clearUploadedFiles,"upload":_vm.handleUploadedFiles,"activeChange":function($event){return _vm.$emit('activeChange', $event)}}},'AddAttachmentsButton',{
                    filePath: _vm.filePath,
                    isDropdown: _vm.isMobile,
                    saveToCameraRoll: _vm.isMobile,
                },false))],1),_vm._m(0)]),_c('div',{staticClass:"pt-1 flex-row mobile-column align-center justify-space-between"},[(_vm.photosUpdated.length)?_c('div',{class:{ 'mobile-hide': !_vm.selectedPhotos.length }},[_c('div',{staticClass:"mb-0 mr-2 field is-grouped"},[_c('b-button',{attrs:{"label":"Delete Selected","icon":"delete-circle","disabled":!_vm.selectedPhotos.length,"outlined":""},on:{"click":function($event){return _vm.markSelectedPhotos('delete')}}}),_c('BuefyDropdown',{staticClass:"mb-0",attrs:{"options":_vm.moveToSectionOptions,"initialValue":_vm.selectedMoveToSection,"disabled":!_vm.selectedPhotos.length},on:{"update:initialValue":function($event){_vm.selectedMoveToSection=$event},"update:initial-value":function($event){_vm.selectedMoveToSection=$event},"select":function($event){return _vm.markSelectedPhotos('moveTo', _vm.selectedMoveToSection)},"activeChange":function($event){return _vm.$emit('activeChange', $event)}},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('b-button',{attrs:{"label":_vm.getMoveToLabel,"icon-right":active ? 'menu-up' : 'menu-down',"outlined":""}})]}}],null,false,258967235)})],1)]):_vm._e(),_c('AddAttachmentsButton',_vm._b({staticClass:"mobile-hide",on:{"uploading":_vm.handleUploading,"uploaded":_vm.clearUploadedFiles,"upload":_vm.handleUploadedFiles,"activeChange":function($event){return _vm.$emit('activeChange', $event)}}},'AddAttachmentsButton',{
                    isDropdown: _vm.isMobile,
                    saveToCameraRoll: _vm.isMobile,
                    filePath: _vm.filePath,
                    displayAddedFiles: false,
                },false))],1)]),_c('div',{key:_vm.photosKey,staticClass:"gallery-container",class:`column-${_vm.numColumns}`},[_c('draggable',{staticClass:"gallery-element",attrs:{"move":_vm.checkIfMoveIsAllowed},on:{"start":function($event){_vm.isDragging=true},"end":function($event){_vm.isDragging=false}},model:{value:(_vm.photosUpdated),callback:function ($$v) {_vm.photosUpdated=$$v},expression:"photosUpdated"}},[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.photosUpdated),function(element,elementIdx){return _c('figure',{key:`element-${element.order}`,class:{ 
                        'selected' : _vm.isSelected(element.id),
                        'added' : element?.image?.added,
                        'to-delete': element.delete,
                        'multiselect': _vm.isSelected(element.id),
                        'to-move': element.moveTo
                    },on:{"click":function($event){$event.stopPropagation();return _vm.selectOrDeselectImage(element)}}},[_c('div',{staticClass:"icon-container top"},[(element.delete)?_c('img',{attrs:{"src":require('../../assets/icons/delete-circle-custom.png')}}):(element.moveTo)?_c('div',{staticClass:"flex-row align-center has-background-white px-2"},[_c('b-icon',{staticClass:"icon-with-background mr-1",attrs:{"icon":"step-forward","type":"is-success"}}),_c('p',{},[_vm._v(_vm._s(element.moveTo))])],1):(_vm.isSelected(element.id))?_c('b-icon',{staticClass:"icon-with-background",attrs:{"icon":"check-circle","type":"is-success"}}):_vm._e()],1),_c('div',{staticClass:"icon-container bottom flex-row align-center"},[_c('p',{staticClass:"is-size-5 px-2 bold has-background-white text-center"},[_vm._v(" "+_vm._s(elementIdx + 1)+" ")]),(_vm.hasBeenMoved(element.order, elementIdx))?_c('p',{staticClass:"is-size-5 px-2 bold text-center has-background-red has-text-white"},[_vm._v(" "+_vm._s(element.order)+" ")]):_vm._e()]),_c('img',{staticClass:"gallery-img",class:{ 'opacity-50': element.delete },attrs:{"src":element?.image?.url}})])}),0)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instructions flex-row"},[_c('p',{staticClass:"border-right mr-3"},[_vm._v("Click to select")]),_c('p',[_vm._v("Click & drag to reorder")])])
}]

export { render, staticRenderFns }