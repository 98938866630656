
import { defineComponent, PropType } from 'vue';
import { readAndCompressImage } from 'browser-image-resizer';
import breakpointMixin from '@/mixins/breakpoint';
import { DialogProgrammatic } from 'buefy';
import AddedAttachment from './Arbitrations/AddedAttachment.vue';
import AppCamera from './AppCamera.vue';
import {
    deleteAttachment,
    formatAndUploadFile,
    formatDate,
    openAlertDialog,
    openModal,
    openToast,
    uploadAttachmentsToStorage,
    uploadFile,
} from '../utils';
import { useBreakpoint } from '@/composables';

export default defineComponent({
    inheritAttrs: false,
    name: 'DragAndDropAttachment',
    mixins: [breakpointMixin],
    props: {
        filePath: {
            type: String,
            required: false,
        },
        immediate: {
            type: Boolean,
            default: true,
        },
        isDropdown: {
            type: Boolean,
            default: false,
        },
        uploadTypes: {
            type: Array as PropType<('library' | 'camera' | 'listing')[]>,
            default: () => { return ['library', 'camera'] },
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        saveToCameraRoll: {
            type: Boolean,
            default: false,
        },
        saveAsName: {
            type: String,
            default: 'carmigo',
        },
    },
    components: {
        AddedAttachment,
    },
    setup() {
        const { bulmaBreakpoint } = useBreakpoint();
        return {
            bulmaBreakpoint,
        }
    },
    data() {
        return {
            newAttachments: [] as File[],
            attachmentUploadQueue: [],
            attachmentUploadQueueIdx: -1,
            failedUploadsIdx: [],
            numSuccessfulUploads: 0,
            openToast: null,
            uploadedFiles: [],
        };
    },
    beforeDestroy() {
        this.closeOpenToast();
    },
    computed: {
        isUploadingAttachments() {
            return this.attachmentUploadQueueIdx >= 0;
        },
        numFailedUploads() {
            return this.failedUploadsIdx.length;
        },
        hasFailedUploads() {
            return this.failedUploadsIdx.length > 0;
        },
        numPendingUploads() {
            if (!this.isUploadingAttachments) {
                return 0;
            }
            return this.attachmentUploadQueue.length - this.attachmentUploadQueueIdx;
        },
    },
    methods: {
        deleteAttachment,
        uploadAttachmentsToStorage,
        formatAndUploadFile,
        uploadFile,
        async savePhotosToCameraRoll(blobs: Blob[]) {
            if (!blobs?.length) {
                return;
            }
            try {
                const files = blobs.map((blob, idx) => new File([blob], `${this.saveAsName}-${formatDate(new Date(), 'MM_dd_yyyy-hhmma')}.jpg`, { type: "image/jpeg" }));
                if (navigator.share) {
                    navigator.share({
                        files,
                    });
                } else {
                    alert("Saving to camera roll is not supported on this browser.");
                }
            } catch(error) {
                alert('Failed to save to camera roll');
            }
        },

        openSelectListingPhotosModal() {
            this.$emit('selectListingPhotos');
        },

        closeOpenToast() {
            if (this.openToast) {
                this.openToast.close();
                this.openToast = null;
            }
        },

        onTakePhotoSelect() {
            openModal({
                component: AppCamera,
                fullScreen: true,
                props: {
                    multiple: this.multiple,
                    enforceOrientation: 'landscape',
                },
                events: {
                    snapshot: this.uploadSnapshot,
                    close: this.savePhotosToCameraRoll,
                },
            });
        },

        async uploadAttachments(filesToUpload: File[]) {
            let renamedAndResizedFiles = await Promise.all(filesToUpload.map(async (file, idx) => {
                let name = this.generateFilename();
                let resizedFile = await this.resizeAttachment(file, name);
                return {
                    file: resizedFile, 
                    uploadName: file.name
                }
            }));
            if (!this.immediate) {
                this.$emit('select', renamedAndResizedFiles);
                return;
            }
            // check if already added to attachmentUploadQueue
            renamedAndResizedFiles.forEach(file => {
                let alreadyUploading = this.attachmentUploadQueue.some(existingFile => existingFile.uploadName == file.uploadName);
                if (!alreadyUploading) {
                    this.addToUploadQueue(file);
                }
            });
            this.executeUploadQueue();
        },

        async uploadSnapshot(file: Blob) {
            const lastModified = new Date().getTime();
            const fileToUpload = new File([file], `${lastModified}.png`, {
                lastModified,
                type: file.type,
            });

            if (!this.immediate) {
                this.$emit('snapshot', fileToUpload);
                return;
            }

            this.addToUploadQueue({ file: fileToUpload });
            this.executeUploadQueue();
        },

        addToUploadQueue(fileToUpload) {
            this.attachmentUploadQueue.push(fileToUpload);
            this.$emit('uploading', [fileToUpload.file.name]);
            this.showUploadingToast();
        },

        executeUploadQueue() {
            if (this.attachmentUploadQueueIdx == -1) {
                this.attachmentUploadQueueIdx = 0;
                this.uploadFilesAndRetryOnFailure();
            }
        },
        
        async uploadFilesAndRetryOnFailure({ uploadAttemptNum }: { uploadAttemptNum?: number }={}) {
            if (this.attachmentUploadQueueIdx >= this.attachmentUploadQueue.length) {
                this.$emit('upload', this.uploadedFiles);
                this.showUploadSummaryToastOrAlert();
                this.attachmentUploadQueueIdx = -1;
                this.attachmentUploadQueue = [];
                this.newAttachments = [];
                this.uploadedFiles = [];
                return;
            }
            this.showUploadingToast();
            const fileToUpload = this.attachmentUploadQueue[this.attachmentUploadQueueIdx]?.file;
            await this.uploadAttachmentsToStorage(this.filePath, [fileToUpload])
                .then(res => {
                    this.uploadedFiles = this.uploadedFiles.concat(res);
                    this.$emit('uploaded', fileToUpload.name);
                    this.attachmentUploadQueueIdx++;
                    this.numSuccessfulUploads++;
                    this.uploadFilesAndRetryOnFailure();
                }).catch(error => {
                    // re-attempts twice (total of 3 attempts)
                    // if fails on third attempt, moves on to the next attachment
                    if (uploadAttemptNum >= 3) {
                        this.failedUploadsIdx.push(this.attachmentUploadQueueIdx + 1);
                        this.attachmentUploadQueueIdx++;
                        this.uploadFilesAndRetryOnFailure();
                    } else {
                        this.uploadFilesAndRetryOnFailure({ uploadAttemptNum: uploadAttemptNum ? uploadAttemptNum + 1 : 2 });
                    }
                });
        },

        showUploadingToast() {
            let failedText = ` | ${this.numFailedUploads} failed`;
            openToast(
                'is-info',
                `Uploading ${this.attachmentUploadQueueIdx + 1} of ${this.attachmentUploadQueue.length} attachments${this.hasFailedUploads ? failedText : '...'}`,
                'indefinite'
            )
        },

        showUploadSummaryToastOrAlert() {
            this.closeOpenToast();
            if (this.hasFailedUploads) {
                this.showFailedUploadsAlert();
            } else {
                openToast('is-success', `All ${this.numSuccessfulUploads} attachments uploaded successfully`);
            }
            this.resetUploadCount();
        },

        showFailedUploadsAlert() {
            let commaSeparatedIndexes = this.failedUploadsIdx.join(', ').replace(/, ([^,]*)$/, ' and $1');
            openAlertDialog({
                title: 'Some attachments failed to upload',
                message: `
                    <p>Uploaded ${this.numSuccessfulUploads}/${this.attachmentUploadQueue.length} attachments successfully.</p>
                    <p>Failed to upload attachments ${commaSeparatedIndexes} (${this.numFailedUploads} total).</p>
                `,
            });
        },

        resetUploadCount() {
            this.numSuccessfulUploads = 0;
            this.failedUploadsIdx = [];
        },

        generateFilename() {
            const randomString = Math.random().toString(36).slice(2);
            // i.e. 24380/inspection/exterior
            const filePathArray = this.filePath.split('/');
            // i.e. exterior
            const type = filePathArray[filePathArray.length - 1];
            return `${type}_${randomString}.jpeg`;
        },

        async resizeAttachment(file: File, name: string) {
            return await readAndCompressImage(file, {
                quality: 0.70,
                maxWidth: 1920,
                maxHeight: 1080,
                debug: process.env.NODE_ENV !== 'production',
                mimeType: file.type,
            }).then(result => {
                const lastModified = new Date().getTime();
                return new File([result], name, {
                    lastModified,
                    type: result.type,
                });
            });
        },
    },
});
