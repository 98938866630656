
import { defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useSaveAudio } from '@/composables';

import AppButton from '@/components/AppButton.vue';

export default defineComponent({
    name: 'AppAudioRecorder',
    props: {
        storageFilePath: {
            type: String,
            required: true,
        },
        filename: {
            type: String,
            default: 'carmigo_audio',
        },
    },
    components: {
        AppButton,
    },
    emits: ['update:rawRecordingUrl', 'update:recording', 'uploadAudio'],
    setup(props, context) {
        const isRecording = ref(false);
        watch(() => isRecording.value, () => context.emit('update:recording', isRecording.value));

        const mediaRecorder = ref<MediaRecorder | null>(null);
        const audioChunks = ref<Blob[]>([]);
        const audioURL = ref<string | null>(null);
        const rawAudioURL = ref<string | null>(null);

        onBeforeUnmount(async () => {
            context.emit('update:recording', false);
            await stopRecording();
        });

        async function startRecording() {
            try {
            // Start- tuning specific to engine audio
                const stream = await navigator.mediaDevices.getUserMedia({ 
                    audio:  {
                        noiseSuppression: false,  // Allow engine sound details
                        echoCancellation: false,  // Preserve all ambient noise
                        autoGainControl: false,   // Avoid volume fluctuations
                        sampleRate: 48000,        // Higher quality for engine sounds
                        channelCount: 1,          // One mic instead of stereo
                    },
                });

                const audioContext = new AudioContext();
                const source = audioContext.createMediaStreamSource(stream);
                const destination = audioContext.createMediaStreamDestination();

                // create low-pass filter to capture engine audio frequency
                const lowPassFilter = audioContext.createBiquadFilter();
                lowPassFilter.type = 'lowpass';
                lowPassFilter.frequency.value = 8000;
                source.connect(lowPassFilter);
                lowPassFilter.connect(destination);

                mediaRecorder.value = new MediaRecorder(destination.stream, {
                    audioBitsPerSecond: 192000,
                });
    
            // End- tuning specific to engine audio
                mediaRecorder.value.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        audioChunks.value.push(event.data);
                        const audioBlob = new Blob(audioChunks.value, { type: 'audio/webm' });
                        const url = URL.createObjectURL(audioBlob);
                        rawAudioURL.value = url;
                        context.emit('update:rawRecordingUrl', rawAudioURL.value);
                    }
                };
    
                mediaRecorder.value.onstop = () => {
                    const tracks = stream.getTracks();
                    tracks.forEach(track => track.stop());
                    const audioBlob = new Blob(audioChunks.value, { type: 'audio/webm' });
                    audioURL.value = URL.createObjectURL(audioBlob);
                    if (props.storageFilePath) {
                        uploadAudioToStorage(audioBlob);
                    }
                };
    
                audioChunks.value = [];
                mediaRecorder.value.start(10); // update audioChuks every 10ms
                isRecording.value = true;
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        }

        async function stopRecording() {
            if (mediaRecorder.value && mediaRecorder.value.state !== 'inactive') {
                mediaRecorder.value.stop();
                isRecording.value = false;
            }
        }

        const {
            loadingUploadAudio,
            uploadAudioToStorage,
        } = useSaveAudio({
            storageFilePath: props.storageFilePath,
            filename: props.filename,
            fileType: 'mp3',
            context,
        });

        return {
            isRecording,
            startRecording,
            stopRecording,
            audioURL,
            loadingUploadAudio,
        };
    }
});
