import { APIConfig, BrandedLane, ModifierOptions } from '@/types';
import { DELETE, POST, PUT } from '.';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openErrorDialog } from '@/utils';

export async function getBrandedLanes(payload: { vehicleListingId?: number, active?: boolean, hasListings?: boolean }={}, modifiers: ModifierOptions={}, config: APIConfig={}) {
    return await PUT(`/promotion/getBrandedLanes`, { modifiers, ...payload }, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function createBrandedLane(brandedLane: Omit<BrandedLane, 'id'>, config: APIConfig={}) {
    return await POST(`/promotion/brandedLane/create`, brandedLane)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function updateBrandedLane(brandedLaneId: number, updatedBrandedLane: Partial<BrandedLane>, config: APIConfig={}) {
    return await PUT(`/promotion/brandedLane/${brandedLaneId}/update`, updatedBrandedLane)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function addBrandedLaneToListing(vehicleListingId: number, brandedLaneId: number, config: APIConfig={}) {
    return await POST(`/promotion/brandedLane/${vehicleListingId}/add`, { brandedLaneId })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to assign branded lane',
                message: `We encountered an error while assigning vehicle ${vehicleListingId} to branded lane ${brandedLaneId}`, 
                displayErrorInDialog: true,
                error,
            });
        });
}

export async function removeBrandedLaneFromListing(vehicleListingId: number, brandedLaneId: number, config: APIConfig={}) {
    return await DELETE(`/promotion/brandedLane/${vehicleListingId}/remove`, undefined, { brandedLaneId })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to unassign branded lane',
                message: `We encountered an error while unassigning vehicle ${vehicleListingId} from branded lane`, 
                displayErrorInDialog: true,
                error,
            });
        });
}