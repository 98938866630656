import { computed, onBeforeMount, reactive, Ref, ref, SetupContext } from 'vue';
import { ancillaryOption, CheckoutForm, CheckoutFormData, CheckoutFormStep } from '@/types';
import { capitalize } from 'lodash';
import { GET, POST } from '@/api';
import { getCheckoutDataAndStepsFromOrderOrderFeatures, setupOrderOrderFeaturesForCheckout } from '@/utils/checkoutUtils';
import { openErrorDialog } from '@/utils';

export function useCheckoutForm({ vehicleName = 'Car price', vehiclePrice, buyerFee, transportationCost, arbitrationOptions, floatFee, photosPrice, requiredFields }: {
    vehicleName?: string,
    vehiclePrice?: number,
    buyerFee?: number,
    transportationCost?: number,
    arbitrationOptions?: Array<ancillaryOption & { description?: string }>,
    floatFee?: number,
    photosPrice?: number,
    requiredFields?: Array<keyof CheckoutForm>,
}={}) {
    const checkoutFormResponses: CheckoutForm = reactive({
        useCarmigoTransportation: undefined,
        selectedArbitrationPlan: undefined,
        buyerFee: buyerFee,
        useCarmigoFloat: undefined,
        purchaseCarmigoPhotos: undefined,
    });

    const getTransportationCost = computed(() => {
        if (checkoutFormResponses.useCarmigoTransportation) {
            return { 'Carmigo Transportation': transportationCost };
        }
        return {};
    });

    function getSelectedArbitrationOption() {
        return arbitrationOptions?.find((option: ancillaryOption) => option.name == checkoutFormResponses.selectedArbitrationPlan);
    }

    const getArbitrationCost = computed(() => {
        if (checkoutFormResponses.selectedArbitrationPlan && arbitrationOptions) {
            let selectedArbitrationOption = getSelectedArbitrationOption();
            return selectedArbitrationOption 
                ? { [`${capitalize(selectedArbitrationOption.name)} Buyer Assurance Plan`]: selectedArbitrationOption.price } 
                : {};
        }
        return {};
    });

    const getCarmigoFloatFee = computed(() => {
        return checkoutFormResponses.useCarmigoFloat ? { 'Carmigo Float Fee': floatFee } : {};
    });

    const getPhotosCost = computed(() => {
        return checkoutFormResponses.purchaseCarmigoPhotos ? { 'Photos': photosPrice }: {};
    });

    const getBuyerFee = computed(() => {
        return buyerFee 
            ? { 'Buyer Fee': buyerFee }
            : {};
    });

    const orderSummary = computed(() => {
        let order = {
            [`${vehicleName}`]: vehiclePrice,
            ...getTransportationCost.value,
            ...getArbitrationCost.value,
            ...getPhotosCost.value,
            ...getCarmigoFloatFee.value,
            ...getBuyerFee.value,
        };
        return order;
    });

    const disableSubmit = computed(() => {
        return Object.keys(checkoutFormResponses).some((field: string) => {
            let value = checkoutFormResponses[field as keyof CheckoutForm];
            return requiredFields 
                ? requiredFields.includes(field as keyof CheckoutForm) && value === undefined 
                : value === undefined;
        });
    });

    return {
        getSelectedArbitrationOption,
        checkoutFormResponses,
        orderSummary,
        disableSubmit,
    }
}

export function useGetAndSaveCheckoutData({ vehicleListingId, marketplaceListingId, context, isFacilitatingAuction }: { 
    vehicleListingId: number, 
    marketplaceListingId?: number, 
    context: SetupContext<('submitted' | 'close')[]>, 
    isFacilitatingAuction?: boolean, 
}) {
    let checkoutData: Ref<CheckoutFormData> = ref({});
    let orderOrderFeatures = ref([]);
    let isLoadingCheckoutData = ref(false);
    const isLoadingSubmitCheckout = ref(false);
    const checkoutSteps: Ref<CheckoutFormStep[]> = ref([]);

    onBeforeMount(async() => {
        isLoadingCheckoutData.value = true;
        await GET(`/orders/getOrGenerateOrderByVehicleListingId/${vehicleListingId}`)
            .then(res => {
                orderOrderFeatures.value = res.data.features;
                const { checkoutSteps: availableSteps, checkoutData: data } = getCheckoutDataAndStepsFromOrderOrderFeatures(orderOrderFeatures.value, isFacilitatingAuction, res.data.canFloat)
                checkoutSteps.value = availableSteps;
                checkoutData.value = {
                    ...data,
                    vehiclePrice: res.data.price,
                };
                isLoadingCheckoutData.value = false;
            }).catch((error) => {
                isLoadingCheckoutData.value = false;
                openErrorDialog({
                    title: 'Failed to fetch order details',
                    message: `We encountered an error while fetching the order for vehicle ${vehicleListingId}`,
                    error,
                });
            });
    });

    async function formatAndSubmitCheckout(checkoutFormResponses: CheckoutForm) {
        isLoadingSubmitCheckout.value = true;
        await POST(`/orders/${vehicleListingId}/checkout`, {
            marketplaceListingId: marketplaceListingId,
            orderOrderFeatures: setupOrderOrderFeaturesForCheckout(checkoutFormResponses, orderOrderFeatures.value),
        }).then(res => {
            context.emit('submitted');
            context.emit('close');
            isLoadingSubmitCheckout.value = false;
        }).catch(error => {
            isLoadingSubmitCheckout.value = false;
            let marketplaceListing = marketplaceListingId ? `and marketplace listing ${marketplaceListingId}` : '';
            openErrorDialog({
                title: 'Failed to complete checkout',
                message: `We encountered an error while checking out for vehicle ${vehicleListingId}${marketplaceListing}.`,
                error,
            });
        });
    }

    return {
        checkoutSteps,
        checkoutData,
        isLoadingCheckoutData,
        formatAndSubmitCheckout,
        isLoadingSubmitCheckout,
    }
}
